import { gql } from '@apollo/client';

export const GET_AUTOCOMPLETE_ADDRESS = gql`
    query autoComplete($bitcoinAddress: String!) {
        autoComplete(bitcoin_address: $bitcoinAddress) {
            address
            cluster_id
        }
    }
`;

export const GET_CLUSTER_ID = gql`
    query get_cluster_id($address: String!) {
        get_cluster_id(address: $address) {
            address
            cluster_id
        }
    }
`;

export const GET_BULK_CLUSTER_IDS = gql`
    query get_bulk_cluster_ids($addresses: [String!]) {
        get_bulk_cluster_ids(addresses: $addresses) {
            address
            cluster_id
        }
    }
`;

export const GET_ADDRESS_INFO = gql`
    query get_address_info($address: String!) {
        get_address_info(address: $address) {
            address
            address_type
            cluster_id
            total_btc_sent
            total_btc_received
            total_btc
            total_usd_sent
            total_usd_received
            total_usd
            tx_in
            tx_out
            first_seen
            last_seen
            tx_total
        }
    }
`;

export const GET_INBOUND_TX_IDS = gql`
    query get_inbound_tx_ids(
        $bitcoinAddress: String!
        $skip: Int
        $column: String
        $direction: String
        $type: String
        $first_seen: Int
        $last_seen: Int
        $page_min_val: Int
        $page_max_val: Int
        $page_last_tx_id: String
    ) {
        get_inbound_tx_ids(
            bitcoin_address: $bitcoinAddress
            skip: $skip
            column: $column
            direction: $direction
            type: $type
            first_seen: $first_seen
            last_seen: $last_seen
            page_min_val: $page_min_val
            page_max_val: $page_max_val
            page_last_tx_id: $page_last_tx_id
        ) {
            tx_id
            total_btc_output
            total_usd_output
            timestamp
            timestamp_epoch
        }
    }
`;

export const GET_OUTBOUND_TX_IDS = gql`
    query get_outbound_tx_ids(
        $bitcoinAddress: String!
        $skip: Int
        $column: String
        $direction: String
        $type: String
        $first_seen: Int
        $last_seen: Int
        $page_min_val: Int
        $page_max_val: Int
        $page_last_tx_id: String
    ) {
        get_outbound_tx_ids(
            bitcoin_address: $bitcoinAddress
            skip: $skip
            column: $column
            direction: $direction
            type: $type
            first_seen: $first_seen
            last_seen: $last_seen
            page_min_val: $page_min_val
            page_max_val: $page_max_val
            page_last_tx_id: $page_last_tx_id
        ) {
            tx_id
            total_btc_output
            total_usd_output
            timestamp
            timestamp_epoch
        }
    }
`;

export const GET_INBOUND_AND_OUTBOUND_TX_IDS = gql`
    query get_inbound_and_outbound_tx_ids(
        $bitcoinAddress: String!
        $skip: Int
        $column: String
        $direction: String
        $type: String
        $first_seen: Int
        $last_seen: Int
        $page_min_val: Int
        $page_max_val: Int
        $page_last_tx_id: String
    ) {
        get_inbound_and_outbound_tx_ids(
            bitcoin_address: $bitcoinAddress
            skip: $skip
            column: $column
            direction: $direction
            type: $type
            first_seen: $first_seen
            last_seen: $last_seen
            page_min_val: $page_min_val
            page_max_val: $page_max_val
            page_last_tx_id: $page_last_tx_id
        ) {
            isOutgoing
            tx_id
            tx_fee_btc
            total_btc_output
            total_usd_output
            timestamp
            timestamp_epoch
        }
    }
`;

export const GET_TX_ID_DETAILS = gql`
    query get_tx_id_details($tx_id: String!) {
        get_tx_id_details(tx_id: $tx_id) {
            tx_id
            tx_fee_btc
            total_btc_input
            total_btc_output
            tx_fee_usd_value
            total_usd_output
            total_usd_input
            block_height
            from_addresses {
                address
                btc_input_value
                usd_input_value
            }
            to_addresses {
                address
                btc_output_value
                usd_output_value
            }
            timestamp
        }
    }
`;

export const GET_TXS_OVER_TIME = gql`
    query get_txs_over_time($address: String!) {
        get_txs_over_time(address: $address) {
            total_num_txs
            too_much_data
            input_txs_over_time {
                timestamp
                total_btc_input
                total_usd_input
            }
            output_txs_over_time {
                timestamp
                total_btc_output
                total_usd_output
            }
        }
    }
`;
export const GET_TXS_OVER_TIME_DAILY = gql`
    query get_txs_over_time_daily($address: String!) {
        get_txs_over_time_daily(address: $address) {
            total_num_txs
            too_much_data
            input_txs_over_time {
                timestamp
                total_btc_input
                total_usd_input
            }
            output_txs_over_time {
                timestamp
                total_btc_output
                total_usd_output
            }
        }
    }
`;

export const GET_CLUSTER_ADDRESSES = gql`
    query get_cluster_addresses($cluster_id: String!, $skip: Int) {
        get_cluster_addresses(cluster_id: $cluster_id, skip: $skip) {
            address
        }
    }
`;

export const HAS_AGENCY_INSIGHTS = gql`
    query has_agency_insights($address: String!, $userDatasetAccess: [String!]) {
        has_agency_insights(address: $address, userDatasetAccess: $userDatasetAccess) {
            agency_sources
        }
    }
`;

export const GET_AGENCY_INSIGHTS_RESULTS = gql`
    query get_agency_insights_results($address: String!, $userDatasetAccess: [String!]) {
        get_agency_insights_results(address: $address, userDatasetAccess: $userDatasetAccess) {
            withdraw_addresses {
                address
                dataset
                user_id
                asset
            }
            deposit_addresses {
                address
                dataset
                user_id
                asset
            }
        }
    }
`;

export const GET_AGENCY_INSIGHTS_ACCOUNT_DETAILS = gql`
    query get_agency_insights_account_details($userId: String!) {
        get_agency_insights_account_details(userId: $userId) {
            dataset
            user_id
            user_info {
                alias
                user_id
                username
                first_name
                middle_name
                last_name
                email
                phone_number
                ssn
                birthday
                address
                city
                province
                country
                possible_location
            }
            withdraw_addresses
            deposit_addresses
        }
    }
`;

export const GET_AGENCY_INSIGHTS_DATASET_NAMES = gql`
    query get_agency_insights_dataset_names {
        get_agency_insights_dataset_names {
            dataset_names
        }
    }
`;

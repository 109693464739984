import React, { createContext, useEffect, useState } from 'react';

export const StrikesContext = createContext();

function Strikes({ children }) {
    const [selectedGroup, setselectedGroup] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [reducedAddresses, setReducedAddresses] = useState([]);
    const [results, setResults] = useState([]);
    const [asset, setAsset] = useState(null);
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [search, setSearch] = useState(null);
    const [saved, setSaved] = useState(null);
    const [drp_secondary_sm1, setDrp_secondary_sm1] = useState(false);
    const [message, setMessage] = useState(null);
    const [progressMessage, setProgressMessage] = useState('');
    const [title, setTitle] = useState('Saved Searches');
    const [threshhold, setthreshhold] = useState(0);
    const [disDefault, setdisDefault] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState(null);
    const [customActiveTab1, setcustomActiveTab1] = useState('summary');
    const [CSV, setCSV] = useState([]);
    const [completed, setCompleted] = useState(0);
    const [progress, setProgress] = useState(false);
    const [cancelRequest, setCancelRequest] = useState(false);
    const [allAssets, setAllAssets] = useState(null);
    const [activeAddress, setActiveAddress] = useState(null);
    const [provider, setProvider] = useState(null);
    const [hasLocation, setHasLocation] = useState([]);
    const [chainalysisCoordinates, setChainalysisCoordinates] = useState([]);
    const [providerData, setProviderData] = useState(null);
    const [drp_link, setdrp_link] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [observations, setObservations] = useState(false);
    const [cleaned_results, set_cleaned_results] = useState([]);

    useEffect(() => {
        const savedCleanedResults = localStorage.getItem('strikesSavedStorage');
        const savedResults = localStorage.getItem('strikesSavedResults');
        if (savedCleanedResults) {
            set_cleaned_results(JSON.parse(savedCleanedResults));
        }

        if (savedResults) {
            setResults(JSON.parse(savedResults));
        }
    }, []);

    return (
        <StrikesContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                selectedGroup,
                setselectedGroup,
                addresses,
                setAddresses,
                reducedAddresses,
                setReducedAddresses,
                results,
                setResults,
                asset,
                setAsset,
                modal,
                setModal,
                deleteModal,
                setDeleteModal,
                searchModal,
                setSearchModal,
                search,
                setSearch,
                saved,
                setSaved,
                drp_secondary_sm1,
                setDrp_secondary_sm1,
                message,
                setMessage,
                progressMessage,
                setProgressMessage,
                title,
                setTitle,
                threshhold,
                setthreshhold,
                disDefault,
                setdisDefault,
                loading,
                setLoading,
                customActiveTab,
                setcustomActiveTab,
                customActiveTab1,
                setcustomActiveTab1,
                CSV,
                setCSV,
                completed,
                setCompleted,
                progress,
                setProgress,
                cancelRequest,
                setCancelRequest,
                allAssets,
                setAllAssets,
                activeAddress,
                setActiveAddress,
                provider,
                setProvider,
                hasLocation,
                setHasLocation,
                chainalysisCoordinates,
                setChainalysisCoordinates,
                providerData,
                setProviderData,
                drp_link,
                setdrp_link,
                drawerIsOpen,
                setDrawerIsOpen,
                observations,
                setObservations,
                cleaned_results,
                set_cleaned_results,
            }}
        >
            {children}
        </StrikesContext.Provider>
    );
}

export default Strikes;

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

function UnPrivateRoute({ children }) {
    const { isAuthenticated, userStatus } = useContext(AuthContext);

    if (isAuthenticated && userStatus !== 500) return <Navigate to="/" replace />;

    if (userStatus === 500) return <Navigate to="/maintenance" replace />;

    return children;
}

export default UnPrivateRoute;

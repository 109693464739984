import React, { useState, useContext, lazy, Suspense } from 'react';
import { themeStyles } from '../../../Themes/styles';
import { GraphContext } from '../../../Context/GraphContext';
import { AttributionContext } from '../../../Context/AttributionContext';
import { CaseContext } from '../../../Context/CaseContext';
import { SearchContext } from '../../../Context/SearchContext';
import { AuthContext } from '../../../Context/AuthContext';

// Suspense fallback
import Fallback from '../../Suspense/Fallback';

// Button Components
import FullscreenButton from './HeaderButtons/Fullscreen/FullscreenButton';
import SaveCanvasImageButton from './HeaderButtons/SaveCanvasImage/SaveCanvasImageButton';
import RecenterButton from './HeaderButtons/Recenter/RecenterButton';

// TODO: remove these next six when case management and
// graph sharing enhancements are done:
import NewSearchButton from './HeaderButtons/NewSearch/NewSearchButton';
import OpenCaseButton from './HeaderButtons/OpenCase/OpenCaseButton';
import SaveGraphButton from './HeaderButtons/SaveGraph/SaveGraphButton';
import ShareGraphButton from './HeaderButtons/ShareGraph/ShareGraphButton';
import UnshareGraphButton from './HeaderButtons/UnshareGraph/UnshareGraphButton';
import DeleteGraphButton from './HeaderButtons/DeleteGraph/DeleteGraphButton';

// Buttons for Case Details page
import BackToCasesButton from './HeaderButtons/CaseDetails/BackToCasesButton';
import BackToCaseDetailsButton from './HeaderButtons/CaseDetails/BackToCaseDetailsButton';

// Components
const SupportModal = lazy(() => import('./SupportModal/index'));
const Logo = lazy(() => import('./Logo/Logo'));
const RightSidebar = lazy(() => import('../RightSidebar/RightSidebar'));
const SearchBar = lazy(() => import('../../Vis/SearchBar/searchBar'));
const ProfileMenu = lazy(() => import('../../Logout/ProfileMenu'));

function Header({ active, setActive }) {
    const [fullscreen, setFullscreen] = useState(false);
    const [showApi, setShowApi] = useState(true);

    const { users, setUsers, user } = useContext(AuthContext);
    const { status, setStatus, apiKey, fusionApiFlag, setLocations, setAttributionCache, attributionCache } =
        useContext(AttributionContext);
    const {
        open,
        setOpen,
        saved,
        setSaved,
        setSelectedNodeId,
        network,
        setSideDrawerIsOpen,
        setSelectedGroup,
        setDirection,
        setType,
        setColumn,
        setDateRange,
        setDateFilter,
        setSkip,
        setInboundAndOutboundTxIds,
        setLoading,
        setActiveTab,
        autoSave,
        isSaved,
        setIsSaved,
        setIsSaving,
        saveGraphButton,
        setAutoSave,
    } = useContext(GraphContext);

    const { set_address } = useContext(SearchContext);

    const {
        allCases,
        setAllCases,
        reducedCases,
        setReducedCases,
        setCompleted,
        setProgress,
        addModal,
        setAddModal,
        message,
        setMessage,
        threshhold,
        setthreshhold,
        disDefault,
        setdisDefault,
        drp_link,
        setdrp_link,
        setDrawerIsOpen,
        setLoadingAddresses,
        setActiveAddress,
        title,
        setTitle,
        expand,
        setExpand,
        existingCase,
        setCaseInput,
        setExistingCase,
        setNewCase,
        caseInput,
        newCase,
        setUpdateCases,
        caseSearch,
        setCaseSearch,
        graphSearch,
        setGraphSearch,
        supportModal,
        setSupportModal,
        deleteModal,
        setDeleteModal,
        loadingCases,
        setLoadingCases,
    } = useContext(CaseContext);

    const styles = themeStyles();

    // Custom theme styles
    const { headerColor, backgroundColor, text, iconColor, searchBar, highlightTextColor, highlightColor, card } =
        styles;

    const openSupportModal = () => {
        setSupportModal(!supportModal);
    };

    const closeSupportModal = () => {
        setSupportModal(false);
    };

    const toggleTopDrawer = () => {
        setOpen(!open);
        if (open === false && apiKey && fusionApiFlag && status === 'Success') {
            setShowApi(false);
        }
    };

    // TODO: remove all of these when case management and
    // graph sharing enhancements are done:
    const showOldCases = false;
    const showBackToCasesInHeader = false;

    return (
        <Suspense fallback={<Fallback />}>
            <header id="page-topbar" style={{ background: headerColor }}>
                <div className="navbar-header">
                    <div className="d-flex">
                        <Logo setActive={setActive} />

                        <SearchBar />

                        {showBackToCasesInHeader && active.artemisCase && <BackToCasesButton />}

                        {active.artemisGraph && <BackToCaseDetailsButton />}
                    </div>

                    <div className="d-flex">
                        <FullscreenButton fullscreen={fullscreen} setFullscreen={setFullscreen} iconColor={iconColor} />
                        {active.artemisGraph && (
                            <>
                                <SaveCanvasImageButton network={network} iconColor={iconColor} />

                                {saved && saved.nodes.length >= 1 && (
                                    <RecenterButton
                                        network={network}
                                        setSelectedNodeId={setSelectedNodeId}
                                        iconColor={iconColor}
                                    />
                                )}

                                {showOldCases && !isSaved && (
                                    <NewSearchButton
                                        addModal={addModal}
                                        setAddModal={setAddModal}
                                        caseInput={caseInput}
                                        existingCase={existingCase}
                                        setExistingCase={setExistingCase}
                                        setNewCase={setNewCase}
                                        setthreshhold={setthreshhold}
                                        setIsSaved={setIsSaved}
                                        setSaved={setSaved}
                                        setdisDefault={setdisDefault}
                                        saved={saved}
                                        iconColor={iconColor}
                                        disDefault={disDefault}
                                        threshhold={threshhold}
                                        allCases={allCases}
                                        message={message}
                                        newCase={newCase}
                                        setCaseInput={setCaseInput}
                                        setMessage={setMessage}
                                        setUpdateCases={setUpdateCases}
                                        setCompleted={setCompleted}
                                        setProgress={setProgress}
                                        caseSearch={caseSearch}
                                        setCaseSearch={setCaseSearch}
                                        graphSearch={graphSearch}
                                        setGraphSearch={setGraphSearch}
                                        autoSave={autoSave}
                                        setAllCases={setAllCases}
                                        setReducedCases={setReducedCases}
                                        setUsers={setUsers}
                                    />
                                )}

                                {showOldCases && (loadingCases || (allCases && allCases.length >= 1)) && (
                                    <OpenCaseButton
                                        setTitle={setTitle}
                                        setExpand={setExpand}
                                        expand={expand}
                                        setdrp_link={setdrp_link}
                                        iconColor={iconColor}
                                        allCases={allCases}
                                        reducedCases={reducedCases}
                                        setDrawerIsOpen={setDrawerIsOpen}
                                        setLoadingAddresses={setLoadingAddresses}
                                        setActiveAddress={setActiveAddress}
                                        setSaved={setSaved}
                                        setIsSaved={setIsSaved}
                                        setMessage={setMessage}
                                        network={network}
                                        title={title}
                                        drp_link={drp_link}
                                        setActive={setActive}
                                        setAllCases={setAllCases}
                                        setReducedCases={setReducedCases}
                                        loadingCases={loadingCases}
                                        setLoadingCases={setLoadingCases}
                                        setUsers={setUsers}
                                        setAttributionCache={setAttributionCache}
                                        attributionCache={attributionCache}
                                        users={users}
                                        user={user}
                                        setAutoSave={setAutoSave}
                                    />
                                )}
                                {showOldCases &&
                                    isSaved &&
                                    saved.case.length >= 1 &&
                                    saved.graph.length >= 1 &&
                                    saved?.sharedWith?.some((shared) => shared.email === user.email) && (
                                        <UnshareGraphButton
                                            saved={saved}
                                            message={message}
                                            setMessage={setMessage}
                                            setAllCases={setAllCases}
                                            setReducedCases={setReducedCases}
                                            set_address={set_address}
                                            setSelectedNodeId={setSelectedNodeId}
                                            setSideDrawerIsOpen={setSideDrawerIsOpen}
                                            setSelectedGroup={setSelectedGroup}
                                            setSaved={setSaved}
                                            setDirection={setDirection}
                                            setType={setType}
                                            setColumn={setColumn}
                                            setDateRange={setDateRange}
                                            setDateFilter={setDateFilter}
                                            setSkip={setSkip}
                                            setInboundAndOutboundTxIds={setInboundAndOutboundTxIds}
                                            setLoading={setLoading}
                                            setActiveTab={setActiveTab}
                                            setLocations={setLocations}
                                            setIsSaved={setIsSaved}
                                            network={network}
                                        />
                                    )}

                                {showOldCases && saveGraphButton && (
                                    <>
                                        {!autoSave && (
                                            <SaveGraphButton
                                                iconColor={iconColor}
                                                saved={saved}
                                                setIsSaving={setIsSaving}
                                                setReducedCases={setReducedCases}
                                                setAllCases={setAllCases}
                                            />
                                        )}

                                        <ShareGraphButton
                                            iconColor={iconColor}
                                            saved={saved}
                                            message={message}
                                            setMessage={setMessage}
                                            setAllCases={setAllCases}
                                            setReducedCases={setReducedCases}
                                        />

                                        <DeleteGraphButton
                                            iconColor={iconColor}
                                            deleteModal={deleteModal}
                                            setDeleteModal={setDeleteModal}
                                            saved={saved}
                                            message={message}
                                            setMessage={setMessage}
                                            setIsSaved={setIsSaved}
                                            setAllCases={setAllCases}
                                            setReducedCases={setReducedCases}
                                            set_address={set_address}
                                            setSelectedNodeId={setSelectedNodeId}
                                            setSideDrawerIsOpen={setSideDrawerIsOpen}
                                            setSelectedGroup={setSelectedGroup}
                                            setSaved={setSaved}
                                            setDirection={setDirection}
                                            setType={setType}
                                            setColumn={setColumn}
                                            setDateRange={setDateRange}
                                            setDateFilter={setDateFilter}
                                            setSkip={setSkip}
                                            setInboundAndOutboundTxIds={setInboundAndOutboundTxIds}
                                            setLoading={setLoading}
                                            setActiveTab={setActiveTab}
                                            setUpdateCases={setUpdateCases}
                                            network={network}
                                            setLocations={setLocations}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        <ProfileMenu
                            text={text}
                            iconColor={iconColor}
                            headerColor={headerColor}
                            searchBar={searchBar}
                            highlightTextColor={highlightTextColor}
                            highlightColor={highlightColor}
                            card={card}
                            toggleTopDrawer={toggleTopDrawer}
                            openSupportModal={openSupportModal}
                            active={active}
                        />
                    </div>
                </div>
            </header>

            <RightSidebar
                open={open}
                toggleTopDrawer={toggleTopDrawer}
                backgroundColor={backgroundColor}
                text={text}
                iconColor={iconColor}
                showApi={showApi}
                setShowApi={setShowApi}
                status={status}
                setStatus={setStatus}
                active={active}
            />

            <SupportModal
                supportModal={supportModal}
                closeSupportModal={closeSupportModal}
                setSupportModal={setSupportModal}
            />
        </Suspense>
    );
}

export default Header;

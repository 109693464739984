import React, { createContext, useState } from 'react';

export const ArcaneContext = createContext();

function Arcane({ children }) {
    const [fusion, setFusion] = useState([]);
    const [count, setCount] = useState(0);
    const [docCount, setDocCount] = useState(null);
    const [page_number, set_page_number] = useState(1);
    const [fusionPaginated, setFusionPaginated] = useState([]);
    const [title, setTitle] = useState('Category');
    const [compareSearch1Title, setCompareSearch1Title] = useState('Category');
    const [compareSearch2Title, setCompareSearch2Title] = useState('Category');
    const [search, setSearch] = useState('');
    const [compareSearch1, setCompareSearch1] = useState('');
    const [compareSearch2, setCompareSearch2] = useState('');
    const [nextPage, setNextPage] = useState(null);
    const [reset, setReset] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [compareStartTime, setCompareStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [compareEndTime, setCompareEndTime] = useState('');
    const [filteredLocation, setFilteredLocation] = useState(false);
    const [reSearch, setReSearch] = useState(false);
    const [filteredAddresses, setFilteredAddresses] = useState(null);
    const [point, setPoint] = useState(false);
    const [customchk, setcustomchk] = useState(false);
    const [query, setQuery] = useState('');
    const [condition, setCondition] = useState('');
    const [originalCondition, setOriginalCondition] = useState('');
    const [compareSearch1Condition, setCompareSearch1Condition] = useState('');
    const [compareSearch2Condition, setCompareSearch2Condition] = useState('');
    const [fusionHoldingArray, setFusionHoldingarray] = useState([]);
    const [customActiveTab, setcustomActiveTab] = useState('1');
    const [activeAddress, setActiveAddress] = useState(null);
    const [addressSummary, setAddressSummary] = useState(null);
    const [country_name, set_country_name] = useState(null);
    const [searchingAgain, setSearchingAgain] = useState(false);
    const [ip, setIP] = useState(null);
    const [city, setCity] = useState(null);
    const [agent, setAgent] = useState(null);
    const [region, setRegion] = useState(null);
    const [country, setCountry] = useState(null);
    const [isp, setISP] = useState(null);
    const [modal, setModal] = useState(false);
    const [summaryModal, setSummaryModal] = useState(false);
    const [bulkAddressModal, setBulkAddressModal] = useState(false);
    const [bulkIpModal, setBulkIpModal] = useState(false);
    const [compareSearchModal, setCompareSearchModal] = useState(false);
    const [processed, setProcessed] = useState(null);
    const [fusion_message, set_fusion_message] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [location, setLocation] = useState(false);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [zoom, setZoom] = useState(3);
    const [mapHoldingObject, setMapHoldingObject] = useState({ zoom: 3, lat: 30.832654, lng: 5.13437 });
    const [center, setCenter] = useState({ lat: 30.832654, lng: 5.13437 });
    const [address, setAddress] = useState(null);
    const [filteredAddress, setFilteredAddress] = useState(null);
    const [popover, setpopover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paginatedLoading, setPaginatedLoading] = useState(false);
    const [loadingStrikes, setLoadingStrikes] = useState(false);
    const [strikesSearch, setStrikesSearch] = useState(null);
    const [clearCluster, setClearCluster] = useState(false);
    const [sort2, setSort2] = useState(true);
    const [sortAddresses, setSortAddresses] = useState(true);
    const [message, setMessage] = useState('');
    const [switch1, setswitch1] = useState(false);
    const [bulkAddresses, setBulkAddresses] = useState(null);
    const [bulkIp, setBulkIp] = useState(null);
    const [bulkSearch, setBulkSearch] = useState(false);
    const [compareCountry, setCompareCountry] = useState(null);
    const [reducedAddresses, setReducedAddresses] = useState([]);
    const [reducedIP, setReducedIP] = useState([]);
    const [provider_a_copy_array, set_provider_a_copy_array] = useState([]);
    const [provider_b_copy_array, set_provider_b_copy_array] = useState([]);
    const [CSV, setCSV] = useState([]);
    const [loadingTotals, setLoadingTotals] = useState(false);
    const [loadingVpn, setLoadingVpn] = useState(false);
    const [drp_link, setdrp_link] = useState(false);
    const [drp_secondary_sm1, setDrp_secondary_sm1] = useState(false);
    const [drp_secondary_sm1_1, setDrp_secondary_sm1_1] = useState(false);
    const [drp_secondary_sm1_2, setDrp_secondary_sm1_2] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [attribution_color_scheme, set_attribution_color_scheme] = useState([]);
    const [toggle, setToggle] = useState({
        arcane: false,
        fusion: true,
    });
    const [mapViewport, setMapViewport] = useState({
        center: { lat: 0, long: 0 },
        latitudeRange: { min: 0, max: 0 },
        longitudeRange: { min: 0, max: 0 },
        zoom: 2,
    });

    return (
        <ArcaneContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                fusion,
                setFusion,
                count,
                setCount,
                docCount,
                setDocCount,
                page_number,
                set_page_number,
                fusionPaginated,
                setFusionPaginated,
                title,
                setTitle,
                compareSearch1Title,
                setCompareSearch1Title,
                compareSearch2Title,
                setCompareSearch2Title,
                search,
                setSearch,
                compareSearch1,
                setCompareSearch1,
                compareSearch2,
                setCompareSearch2,
                nextPage,
                setNextPage,
                reset,
                setReset,
                startTime,
                setStartTime,
                compareStartTime,
                setCompareStartTime,
                endTime,
                setEndTime,
                compareEndTime,
                setCompareEndTime,
                filteredLocation,
                setFilteredLocation,
                reSearch,
                setReSearch,
                filteredAddresses,
                setFilteredAddresses,
                point,
                setPoint,
                customchk,
                setcustomchk,
                query,
                setQuery,
                condition,
                setCondition,
                compareSearch1Condition,
                setCompareSearch1Condition,
                compareSearch2Condition,
                setCompareSearch2Condition,
                fusionHoldingArray,
                setFusionHoldingarray,
                country_name,
                set_country_name,
                customActiveTab,
                setcustomActiveTab,
                activeAddress,
                setActiveAddress,
                addressSummary,
                setAddressSummary,
                searchingAgain,
                setSearchingAgain,
                ip,
                setIP,
                city,
                setCity,
                agent,
                setAgent,
                region,
                setRegion,
                country,
                setCountry,
                isp,
                setISP,
                modal,
                setModal,
                summaryModal,
                setSummaryModal,
                bulkAddressModal,
                setBulkAddressModal,
                bulkIpModal,
                setBulkIpModal,
                compareSearchModal,
                setCompareSearchModal,
                compareCountry,
                setCompareCountry,
                processed,
                setProcessed,
                fusion_message,
                set_fusion_message,
                searchMessage,
                setSearchMessage,
                location,
                setLocation,
                lat,
                setLat,
                lng,
                setLng,
                zoom,
                setZoom,
                address,
                setAddress,
                filteredAddress,
                setFilteredAddress,
                popover,
                setpopover,
                loading,
                setLoading,
                paginatedLoading,
                setPaginatedLoading,
                loadingStrikes,
                setLoadingStrikes,
                strikesSearch,
                setStrikesSearch,
                clearCluster,
                setClearCluster,
                sort2,
                setSort2,
                sortAddresses,
                setSortAddresses,
                message,
                setMessage,
                switch1,
                setswitch1,
                bulkAddresses,
                setBulkAddresses,
                bulkIp,
                setBulkIp,
                bulkSearch,
                setBulkSearch,
                reducedAddresses,
                setReducedAddresses,
                reducedIP,
                setReducedIP,
                provider_a_copy_array,
                set_provider_a_copy_array,
                provider_b_copy_array,
                set_provider_b_copy_array,
                CSV,
                setCSV,
                loadingTotals,
                setLoadingTotals,
                loadingVpn,
                setLoadingVpn,
                drp_link,
                setdrp_link,
                drawerIsOpen,
                setDrawerIsOpen,
                attribution_color_scheme,
                set_attribution_color_scheme,
                toggle,
                setToggle,
                drp_secondary_sm1,
                setDrp_secondary_sm1,
                drp_secondary_sm1_1,
                setDrp_secondary_sm1_1,
                drp_secondary_sm1_2,
                setDrp_secondary_sm1_2,
                center,
                setCenter,
                mapHoldingObject,
                setMapHoldingObject,
                mapViewport,
                setMapViewport,
                originalCondition,
                setOriginalCondition,
            }}
        >
            {children}
        </ArcaneContext.Provider>
    );
}

export default Arcane;

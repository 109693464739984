import { createSlice } from '@reduxjs/toolkit';

/**
 * @namespace ArtemisCasesState
 */

const CASES_SLICE_NAME = 'cases';

const initialState = {
    casesList: [],
    casesById: {},
    selectedCaseId: undefined,
    selectedGraphIds: [],
};

const casesSlice = createSlice({
    name: CASES_SLICE_NAME,
    initialState,
    reducers: {
        addCase(state, action) {
            const { payload = {} } = action;
            const { case: newCase } = payload;
            const { _id: caseId } = newCase;

            if (!newCase.graphs) {
                newCase.graphs = [];
            }

            // Creating a new array object causes the
            // getAllCases selector function to return a new
            // array reference which in turn causes any
            // react component using that selector to
            // be re-rendered
            state.casesList = state.casesList.slice();
            state.casesList.push(newCase);
            state.casesById[caseId] = newCase;
        },
        addGraph(state, action) {
            const { payload = {} } = action;
            const { caseId, graph } = payload;

            const theCase = state.casesList.filter(({ _id }) => _id === caseId)[0];
            if (theCase) {
                // Note: that this create a new array object to intentionally
                // force any component using a selector to get a list of cases
                // to update:
                state.casesList = state.casesList.filter(({ _id }) => _id !== caseId);

                // Also creating a new array to force selectors to change:
                const updatedCase = {
                    ...theCase,
                    graphs: theCase.graphs.slice(),
                };
                updatedCase.graphs.push(graph);

                // And just add back the same case object but in a different casesList array object:
                state.casesList.push(updatedCase);

                state.casesById[caseId] = updatedCase;
            } else {
                console.error('casesSlice.addGraph failed to find existing case to update');
            }
        },
        moveGraph(state, action) {
            const { payload = {} } = action;
            const { fromCaseId, toCaseId, graphId } = payload;

            const fromCase = state.casesById[fromCaseId];
            if (!fromCase) {
                console.error('casesSlice.moveGraph failed to find case to move from!');
                return;
            }

            const theGraph = fromCase.graphs.filter(({ _id }) => _id === graphId)[0];
            if (!theGraph) {
                console.error('casesSlice.moveGraph failed to find the graph to move!');
                return;
            }

            const toCase = state.casesById[toCaseId];
            if (!toCase) {
                console.error('casesSlice.moveGraph failed to find case to move to!');
                return;
            }

            state.casesList = state.casesList.filter(({ _id }) => _id !== fromCaseId && _id !== toCaseId);

            const updatedFromCase = {
                ...fromCase,
                graphs: fromCase.graphs.filter(({ _id }) => _id !== graphId),
            };
            state.casesList.push(updatedFromCase);
            state.casesById[fromCaseId] = updatedFromCase;

            const theMovedGraph = {
                ...theGraph,
                caseId: toCaseId,
            };
            const updatedToCase = {
                ...toCase,
                graphs: toCase.graphs.slice(),
            };
            updatedToCase.graphs.push(theMovedGraph);

            state.casesList.push(updatedToCase);
            state.casesById[toCaseId] = updatedToCase;

            if (state.selectedGraphIds.includes(graphId)) {
                state.selectedGraphIds = state.selectedGraphIds.filter((id) => id !== graphId);
            }
        },
        updateCase(state, action) {
            const { payload = {} } = action;
            const { case: aCase } = payload;
            const { _id: caseId } = aCase;

            const prev = state.casesList.filter(({ _id }) => _id === caseId)[0];

            if (prev) {
                // Note: that this create a new array object to intentionally
                // force any component using a selector to get a list of cases
                // to update:
                state.casesList = state.casesList.filter(({ _id }) => _id !== caseId);
                // Merge the updated case with the previous one:
                const updatedCase = { ...prev, ...aCase };
                state.casesList.push(updatedCase);
                state.casesById[caseId] = updatedCase;
            } else {
                console.error('casesSlice.updateCase failed to find existing case to update');
            }
        },
        updateGraph(state, action) {
            const { payload = {} } = action;
            const { caseId, graph } = payload;
            const { _id: graphId } = graph;

            // Get the case containing the graph:
            const theCase = state.casesList.filter(({ _id }) => _id === caseId)[0];
            if (theCase) {
                // Note: that this creates a new array object to intentionally
                // force any component using a selector to get a list of cases
                // to update:
                state.casesList = state.casesList.filter(({ _id }) => _id !== caseId);

                // Get the previous graph
                const prevGraph = theCase.graphs.filter(({ _id }) => _id === graphId)[0];
                if (prevGraph) {
                    // Note: this creates a new array object to intentionally
                    // force any component using a selector to get a case's graphs list
                    // to update:
                    const updatedCase = {
                        ...theCase,
                        graphs: theCase.graphs.filter(({ _id }) => _id !== graphId),
                    };
                    // Merge the updated graph with the previous one:
                    const updatedGraph = { ...prevGraph, ...graph };
                    updatedCase.graphs.push(updatedGraph);

                    // And just add back the same case object but in a different casesList array object:
                    state.casesList.push(updatedCase);
                    state.casesById[caseId] = updatedCase;
                } else {
                    console.error('casesSlice.updateGraph failed to find existing graph to update');
                }
            } else {
                console.error('casesSlice.updateGraph failed to find existing case containing the graph to update');
            }
        },
        removeCase(state, action) {
            const { payload = {} } = action;
            const { caseId } = payload;

            const theCase = state.casesById[caseId];

            state.casesList = state.casesList.filter(({ _id }) => _id !== caseId);
            delete state.casesById[caseId];

            if (theCase && caseId === state.selectedCaseId) {
                state.selectedCaseId = undefined;

                const { graphs = [] } = theCase;
                const { selectedGraphIds } = state;
                let newSelectedGraphIds = selectedGraphIds.slice();
                graphs.forEach(({ _id: graphId }) => {
                    if (newSelectedGraphIds.includes(graphId)) {
                        newSelectedGraphIds = newSelectedGraphIds.filter((id) => id !== graphId);
                    }
                });
                state.selectedGraphIds = newSelectedGraphIds;
            }
        },
        removeGraph(state, action) {
            const { payload = {} } = action;
            const { caseId, graphId } = payload;

            // Get the case containing the graph:
            const theCase = state.casesList.filter(({ _id }) => _id === caseId)[0];
            if (theCase) {
                // Note: that this creates a new array object to intentionally
                // force any component using a selector to get a list of cases
                // to update:
                state.casesList = state.casesList.filter(({ _id }) => _id !== caseId);

                // Remove the graph from the case's list:
                const updatedCase = {
                    ...theCase,
                    graphs: theCase.graphs.filter(({ _id }) => _id !== graphId),
                };

                // And just add back the same case object but in a different casesList array object:
                state.casesList.push(updatedCase);
                state.casesById[caseId] = updatedCase;
            } else {
                console.error('casesSlice.updateGraph failed to find existing case containing the graph to update');
            }

            if (state.selectedGraphIds.includes(graphId)) {
                state.selectedGraphIds = state.selectedGraphIds.filter((id) => id !== graphId);
            }
        },
        setCases(state, action) {
            const { payload = {} } = action;
            const { cases } = payload;
            // Creating a new array object causes the
            // getAllCases selector function to return a new
            // array reference which in turn causes any
            // react component using that selector to
            // be re-rendered
            state.casesList = [...cases];
            cases.forEach((c) => {
                const { _id: caseId } = c;
                if (!c.graphs) {
                    c.graphs = [];
                }
                state.casesById[caseId] = c;
            });

            state.selectedCaseId = undefined;
            state.selectedGraphIds = [];
        },
        setSelectedCaseId(state, action) {
            const { payload = {} } = action;
            const { id, isSelected } = payload;

            if (id && isSelected) {
                state.selectedCaseId = id;
            } else {
                state.selectedCaseId = undefined;
            }
        },
        toggleSelectedGraphId(state, action) {
            const { payload = {} } = action;
            const { id } = payload;
            if (!id) {
                return;
            }
            const wasSelected = state.selectedGraphIds.includes(id);
            if (wasSelected) {
                state.selectedGraphIds = state.selectedGraphIds.filter((anId) => anId !== id);
            } else {
                state.selectedGraphIds = state.selectedGraphIds.slice();
                state.selectedGraphIds.push(id);
            }
        },
        setSelectedGraphIds(state, action) {
            const { payload = {} } = action;
            const { ids = [] } = payload;
            state.selectedGraphIds = ids.slice();
        },
        setCaseSelectedByName(state, action) {
            const { payload = {} } = action;
            const { caseName } = payload;

            try {
                const theCase = state.casesList.filter(({ name }) => name === caseName)[0];
                state.selectedCaseId = theCase._id;
            } catch (error) {
                console.error(`setCaseSelectedByName: error attempting to select case name: ${caseName}: ${error}`);
            }
        },
        setGraphSelectedByName(state, action) {
            const { payload = {} } = action;
            const { caseName, graphName } = payload;

            try {
                const theCase = state.casesList.filter(({ name }) => name === caseName)[0];
                const theGraph = theCase.graphs.filter(({ name }) => name === graphName)[0];
                state.selectedGraphIds = [theGraph._id];
            } catch (error) {
                console.error(
                    `setGraphSelectedByName: error attempting to select graph name: ${graphName} in case name: ${caseName}: ${error}`,
                );
            }
        },
    },
});

export { initialState, CASES_SLICE_NAME };
export const {
    addCase,
    addGraph,
    moveGraph,
    updateCase,
    updateGraph,
    removeCase,
    removeGraph,
    setCases,
    setSelectedCaseId,
    toggleSelectedGraphId,
    setSelectedGraphIds,
    setCaseSelectedByName,
    setGraphSelectedByName,
} = casesSlice.actions;
export default casesSlice.reducer;

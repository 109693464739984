const providers = ['ca'];

export const fetchObservationData = async (
    provider,
    mapViewport,
    setCenter,
    setClearCluster,
    zoom,
    setZoom,
    setLocations,
    setSortStates,
    setPoint,
    filteredLocation,
    setFilteredLocation,
    mapHoldingObject,
    activeProviderTab,
    observationCache,
    selectedNode,
    filteredSearchResults,
    setObservationCache,
    apiKey,
    setLoadingObservations,
    setLocationsHoldingArray,
    saved,
    network,
    setSaved,
    setShowReset,
    getObservations,
    resetObservations,
    setRateCache,
    rateCache,
) => {
    resetObservations(
        mapViewport,
        setCenter,
        setClearCluster,
        zoom,
        setZoom,
        setSortStates,
        setPoint,
        filteredLocation,
        setFilteredLocation,
        mapHoldingObject,
    );
    const isFiltered = !filteredLocation;
    const activeProvider = 'ca';
    const checkObservationCache = observationCache.find(
        (obs) => obs.address === selectedNode.address && obs.provider === provider,
    );

    const checkProviderObservationCache = observationCache.find(
        (obs) => obs.address === selectedNode.address && obs.provider === activeProvider && obs.locations.length >= 1,
    );

    if (checkProviderObservationCache) {
        setLocations(checkProviderObservationCache.locations);
        setLocationsHoldingArray(checkProviderObservationCache.locations);
        // add logic here to add locations to observationCache if address is not already there
    } else if (selectedNode.address && !filteredSearchResults && !filteredLocation && !checkObservationCache) {
        await getObservations(
            observationCache,
            setObservationCache,
            selectedNode.address,
            setLocations,
            provider,
            apiKey,
            setLoadingObservations,
            filteredLocation,
            isFiltered,
            setLocationsHoldingArray,
            saved,
            network,
            setSaved,
            setShowReset,
            activeProviderTab,
            setRateCache,
            rateCache,
        );
    } else {
        const other_provider = providers.find((p) => p !== activeProvider);

        const other_provider_cache = observationCache.find(
            (obs) =>
                obs.address === selectedNode.address && obs.provider === other_provider && obs.locations.length > 0,
        );

        if (other_provider_cache) {
            setLocations(other_provider_cache.locations);
            setLocationsHoldingArray(other_provider_cache.locations);
        }

        if (!other_provider_cache) {
            setLocations(null);
            setLocationsHoldingArray([]);
        }

        setTimeout(() => {
            setShowReset(true);
        }, 2000);
    }
};

// Set location data on tab click
export const setObservationData = (
    provider,
    provider_long,
    setSearch,
    setActiveProviderTab,
    setClearCluster,
    observationCache,
    selectedNodeId,
    setLocations,
    setLocationsHoldingArray,
    setCenter,
    setFilteredLocation,
    zoom,
    setZoom,
    filteredLocation,
    setShowReset,
) => {
    setSearch('');
    setActiveProviderTab(provider_long);
    setClearCluster(true);
    const locations = observationCache.find((o) => o.provider === provider && selectedNodeId === o.address);
    setLocations(locations?.locations);
    setLocationsHoldingArray(locations?.locations);
    setCenter({ lat: 30.832654, lng: 5.13437 });
    setFilteredLocation(false);

    if (zoom !== 2) {
        setZoom(2);
    }

    if (!filteredLocation) {
        setTimeout(() => {
            setShowReset(true);
        }, 2000);
    }
};

// Set location data on tab click
export const setApiLimitObservationData = (
    provider_long,
    setSearch,
    setActiveProviderTab,
    setLocations,
    setLocationsHoldingArray,
    setFilteredLocation,
) => {
    setSearch('');
    setActiveProviderTab(provider_long);
    setLocations([]);
    setLocationsHoldingArray([]);
    setFilteredLocation(false);
};

export const resetObservations = (
    mapViewport,
    setCenter,
    setClearCluster,
    zoom,
    setZoom,
    setSortStates,
    setPoint,
    filteredLocation,
    setFilteredLocation,
    mapHoldingObject,
) => {
    setSortStates({
        timestamp: true,
        ipAddress: false,
        software: false,
        city: false,
        country: false,
        ISP: false,
        security: false,
        walletRootAddress: false,
    });

    if (filteredLocation) {
        setPoint(false);
        setFilteredLocation(false);
    }
    if (mapViewport.center.lat !== mapHoldingObject.lat || mapViewport.center.lng !== mapHoldingObject.lng) {
        setCenter({ lat: 30.832654, lng: 5.13437 });
    }
    if (zoom !== 2) {
        setClearCluster(true);
        setZoom(2);
    }
};

import html2canvas from 'html2canvas';

export const saveCanvasImage = (network) => {
    const canvas = network && network.canvas.frame.canvas;

    // Check if the canvas element is attached to the document
    if (document.body.contains(canvas)) {
        html2canvas(canvas).then((c) => {
            // Convert the canvas to a data URL
            const image = c.toDataURL();

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = image;
            link.download = 'canvas.png';
            link.click();
        });
    } else {
        console.error('The canvas element is not attached to the document.');
    }
};

/**
 * @description
 * This is DAAP.
 *
 * It renders:
 * - Artemis
 * - Arcane
 * - Address Attributer
 * @namespace DAAP
 */

import React, { useContext, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import Layout from './Components/Layout';
import { getGoogleMapsApiKey } from './Store/AppConfig/selectors';

// Route Components
import PrivateRoute from './hocs/PrivateRoute';
import UnPrivateRoute from './hocs/UnPrivateRoute';

// Context
import { AuthContext } from './Context/AuthContext';
import { GoogleMapsApiContext } from './Context/GoogleMapsApiContext';

// Suspense Fallback
import Fallback from './Components/Suspense/Fallback';
import UserTablePlaceholder from './Components/Admin/Placeholders/UserTablePlaceholder';
import StrikesPlaceholder from './Components/Strikes/Placeholders/StrikesPlaceholder';
import ArcanePlaceholder from './Components/Arcane/Placeholders/ArcanePlaceholder';
import loadAndStoreAppConfig from './Utils/Admin/loadAndStoreAppConfig';
// import loadAndStoreArtemisCases from './Components/Cases/util/loadAndStoreArtemisCases';

// Non Auth Components
const Login = lazy(() => import('./Pages/Login'));
const Authenticate = lazy(() => import('./Pages/Authenticate'));
const Forgot = lazy(() => import('./Pages/Forgot'));
// const Register = lazy(() => import('./Pages/Register'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const Error500 = lazy(() => import('./Components/Utility/pages-maintenance'));
const Error500Private = lazy(() => import('./Components/Utility/pages-maintenance-private'));

// Auth components
const Artemis = lazy(() => import('./Pages/Cases/Cases'));
const ArtemisCaseDetails = lazy(() => import('./Pages/Cases/CaseDetails'));
const ArtemisGraph = lazy(() => import('./Pages/Graph'));
const Arcane = lazy(() => import('./Pages/Arcane/index'));
const Strikes = lazy(() => import('./Pages/Strikes/index'));
const Admin = lazy(() => import('./Pages/Admin/index'));

function App() {
    const googleMapsApiKey = useSelector(getGoogleMapsApiKey);
    const { isAuthenticated, user } = useContext(AuthContext);
    const { setGoogleMapsApiLoaded, setLoadError } = useContext(GoogleMapsApiContext);
    const currentRoute = useLocation().pathname;

    useEffect(() => {
        if (isAuthenticated) {
            loadAndStoreAppConfig();
        }
    }, [isAuthenticated]);

    /*
    useEffect(() => {
        if (!isAuthenticated || !user) {
            return;
        }
        const { subscriptions = [] } = user;
        if (!subscriptions.includes('artemis')) {
            return;
        }

	try {
            loadAndStoreArtemisCases();
        } catch (error) {
            console.error(`App error from loadAndStoreArtemisCases: ${error}`);
        }
    }, [isAuthenticated, user]);
    */

    const UNPRIVATE_LOGIN = (
        <Route
            path="/login"
            element={
                <UnPrivateRoute>
                    <Suspense fallback={<Fallback />}>
                        <Login />
                    </Suspense>
                </UnPrivateRoute>
            }
        />
    );

    const UNPRIVATE_AUTHENTICATE = (
        <Route
            path="/authenticate"
            element={
                <UnPrivateRoute>
                    <Suspense fallback={<Fallback />}>
                        <Authenticate />
                    </Suspense>
                </UnPrivateRoute>
            }
        />
    );
    const UNPRIVATE_FORGOT = (
        <Route
            path="/forgot"
            element={
                <UnPrivateRoute>
                    <Suspense fallback={<Fallback />}>
                        <Forgot />
                    </Suspense>
                </UnPrivateRoute>
            }
        />
    );

    // const UNPRIVATE_REGISTER = (
    //     <Route
    //         path="/register"
    //         element={
    //             <UnPrivateRoute>
    //                 <Suspense fallback={<Fallback />}>
    //                     <Register />
    //                 </Suspense>
    //             </UnPrivateRoute>
    //         }
    //     />
    // );

    const UNPRIVATE_DASHBOARD = (
        <Route
            path="/"
            element={
                <Layout>
                    <Suspense fallback={<Fallback />}>
                        <Dashboard />
                    </Suspense>
                </Layout>
            }
        />
    );

    const UNPRIVATE_500 = (
        <Route
            path="/maintenance"
            element={
                <Suspense fallback={<Fallback />}>
                    <Error500 />
                </Suspense>
            }
        />
    );

    const PRIVATE_500 = (
        <Route
            path="/500"
            element={
                <Suspense fallback={<Fallback />}>
                    <Error500Private />
                </Suspense>
            }
        />
    );

    const PRIVATE_ARTEMIS = (
        <Route
            path="/artemis"
            exact
            element={
                <PrivateRoute roles={['admin', 'user']} subscription="artemis">
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <Artemis />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_ARTEMIS_CASE_DETAILS = (
        <Route
            path="/artemis/case"
            exact
            element={
                <PrivateRoute roles={['admin', 'user']} subscription="artemis">
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <ArtemisCaseDetails />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_ARTEMIS_GRAPH = (
        <Route
            path="/artemis/graph"
            exact
            element={
                <PrivateRoute roles={['admin', 'user']} subscription="artemis">
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <ArtemisGraph />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_ARCANE = (
        <Route
            path="/arcane"
            exact
            element={
                <PrivateRoute roles={['admin', 'user']} subscription="arcane">
                    <Layout>
                        <Suspense fallback={<ArcanePlaceholder />}>
                            <Arcane />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_STRIKES = (
        <Route
            path="/address-attributer"
            exact
            element={
                <PrivateRoute roles={['admin', 'user']} subscription="address-attributer">
                    <Layout>
                        <Suspense fallback={<StrikesPlaceholder />}>
                            <Strikes />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_ADMIN = (
        <Route
            path="/admin"
            exact
            element={
                <PrivateRoute roles={['admin']} subscription="none">
                    <Layout>
                        <Suspense fallback={<UserTablePlaceholder />}>
                            <Admin />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    // Reroute any invalid routes to the home page when the user is authenticated
    const REROUTE = (
        <Route
            path="*"
            element={
                <Layout>
                    <Suspense fallback={<Fallback />}>
                        <Navigate to="/" />
                    </Suspense>
                </Layout>
            }
        />
    );

    return (
        <>
            {!isAuthenticated &&
                !user.status &&
                // currentRoute !== '/register' &&
                currentRoute !== '/authenticate' &&
                currentRoute !== '/forgot' && <Navigate from="/logout" exact to="/login" />}
            {!isAuthenticated &&
                !user.status &&
                // currentRoute !== '/register' &&
                currentRoute !== '/authenticate' &&
                currentRoute !== '/forgot' && <Navigate from="/" to="/login" />}
            {!isAuthenticated &&
                user.status !== 500 &&
                // currentRoute !== '/register' &&
                currentRoute !== '/authenticate' &&
                currentRoute !== '/forgot' && <Navigate from="/maintenance" exact to="/login" />}
            {!isAuthenticated && user.status === 500 && <Navigate from="/" exact to="/maintenance" />}
            {!isAuthenticated && user.status === 500 && <Navigate from="/login" exact to="/maintenance" />}
            {isAuthenticated && googleMapsApiKey && (
                <LoadScript
                    googleMapsApiKey={`${googleMapsApiKey}&loading=async`}
                    onLoad={() => setGoogleMapsApiLoaded(true)}
                    onError={(err) => {
                        console.error('Error loading Google Maps script:', err);
                        setLoadError(err);
                    }}
                />
            )}
            <Routes>
                {UNPRIVATE_LOGIN}
                {UNPRIVATE_AUTHENTICATE}
                {UNPRIVATE_FORGOT}
                {/* {UNPRIVATE_REGISTER} */}
                {UNPRIVATE_DASHBOARD}
                {UNPRIVATE_500}
                {PRIVATE_500}
                {PRIVATE_ARTEMIS}
                {PRIVATE_ARTEMIS_CASE_DETAILS}
                {PRIVATE_ARTEMIS_GRAPH}
                {PRIVATE_ARCANE}
                {PRIVATE_STRIKES}
                {PRIVATE_ADMIN}
                {REROUTE}
            </Routes>
        </>
    );
}

export default App;

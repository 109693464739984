import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AttributionContext = createContext();

function Attribution({ children }) {
    const [attributionCache, setAttributionCache] = useState([]);
    const [loadingAtt, setLoadingAtt] = useState(false);
    const [loadingSenders, setLoadingSenders] = useState(false);
    const [loadingReceivers, setLoadingReceivers] = useState(false);
    const [attributionMessage, setAttributionMessage] = useState(null);
    const [observationCache, setObservationCache] = useState([]);
    const [infoCache, setInfoCache] = useState([]);
    const [ipCache, setIpCache] = useState([]);
    const [locations, setLocations] = useState(null);
    const [attributionColorsCache, setAttributionColorsCache] = useState([]);
    const [apiKey, setApiKey] = useState(null);
    const [updateAttributionCache, setUpdateAttributionCache] = useState(false);
    const [subscriptionsCache, setSubscriptionsCache] = useState({});
    const [servicesData, setServicesData] = useState(null);
    const [fusionApiFlag, setFusionApiFlag] = useState(false);
    const [bulkObservationLoading, setBulkObservationLoading] = useState(false);
    const [hideAttributionsButton, setHideAttributionsButton] = useState(false);
    const [status, setStatus] = useState('Fail');
    const [showApiMessage, setShowApiMessage] = useState(null);
    const [rateCache, setRateCache] = useState([]);
    const [artemisLimitExceeded, setArtemisLimitExceeded] = useState(null);

    useEffect(() => {
        const fetchAttributionsColor = async () => {
            try {
                const response = await axios.post(
                    '/api/fusion/attributions-color',
                    {},
                    {
                        headers: {
                            apikey: apiKey,
                        },
                    },
                );
                setAttributionColorsCache(response.data);
            } catch (error) {
                console.error('Error fetching attributions color', error);
            }
        };
        const fetchSubscriptions = async () => {
            try {
                const response = await axios.post(
                    '/api/fusion/subscriptions',
                    {},
                    {
                        headers: {
                            apikey: apiKey,
                        },
                    },
                );
                setSubscriptionsCache(response.data);
            } catch (error) {
                console.error('Error fetching subscriptions', error);
            }
        };

        if (apiKey && fusionApiFlag) {
            fetchSubscriptions();
            fetchAttributionsColor();
        }
    }, [apiKey, fusionApiFlag]);

    return (
        <AttributionContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                attributionCache,
                setAttributionCache,
                loadingAtt,
                setLoadingAtt,
                attributionMessage,
                setAttributionMessage,
                loadingSenders,
                setLoadingSenders,
                loadingReceivers,
                setLoadingReceivers,
                observationCache,
                setObservationCache,
                infoCache,
                setInfoCache,
                locations,
                setLocations,
                attributionColorsCache,
                setAttributionColorsCache,
                apiKey,
                setApiKey,
                ipCache,
                setIpCache,
                updateAttributionCache,
                setUpdateAttributionCache,
                subscriptionsCache,
                setSubscriptionsCache,
                servicesData,
                setServicesData,
                fusionApiFlag,
                setFusionApiFlag,
                bulkObservationLoading,
                setBulkObservationLoading,
                hideAttributionsButton,
                setHideAttributionsButton,
                status,
                setStatus,
                showApiMessage,
                setShowApiMessage,
                rateCache,
                setRateCache,
                artemisLimitExceeded,
                setArtemisLimitExceeded,
            }}
        >
            {children}
        </AttributionContext.Provider>
    );
}

export default Attribution;

import React, { createContext, useState } from 'react';

export const NoteContext = createContext();

export function Note({ children }) {
    const [noteModal, setNoteModal] = useState(false);
    const [noteEditModal, setNoteEditModal] = useState(false);
    const [newNoteModal, setNewNoteModal] = useState(false);
    const [note, setNote] = useState({});
    const [message, setMessage] = useState(null);
    const [threshhold, setthreshhold] = useState(0);
    const [disDefault, setdisDefault] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [currentXY, setCurrentXY] = useState(null);

    return (
        <NoteContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                noteModal,
                setNoteModal,
                noteEditModal,
                setNoteEditModal,
                newNoteModal,
                setNewNoteModal,
                note,
                setNote,
                message,
                setMessage,
                threshhold,
                setthreshhold,
                disDefault,
                setdisDefault,
                noteText,
                setNoteText,
                currentXY,
                setCurrentXY,
            }}
        >
            {children}
        </NoteContext.Provider>
    );
}

export default Note;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import App from './App';
import store from './Store';
import SearchProvider from './Context/SearchContext';
import GraphProvider from './Context/GraphContext';
import AuthProvider from './Context/AuthContext';
import NoteProvider from './Context/NoteContext';
import AttributionProvider from './Context/AttributionContext';
import MapProvider from './Context/MapContext';
import CaseProvider from './Context/CaseContext';
import GoogleMapsApiProvider from './Context/GoogleMapsApiContext';
import ArcaneProvider from './Context/ArcaneContext';
import StrikesProvider from './Context/StrikesContext';
import FusionProvider from './Context/FusionContext';
import ActiveProvider from './Context/ActiveContext';
import './Themes/theme-light.scss';
import './index.css';

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            errorPolicy: 'all', // set the error policy for all useQuery hooks
        },
    },
});

const consoleErr = console.error;
const consoleWarn = console.warn;

const SUPPRESSED_ERRORS = [
    'Support for defaultProps will be removed from function components in a future major release.',
    'findDOMNode is deprecated',
];

const SUPPRESSED_WARNINGS = ['google.maps.Marker is deprecated'];
console.error = function filterWarnings(msg, ...args) {
    if (
        typeof msg !== 'string' ||
        (typeof msg === 'string' && !SUPPRESSED_ERRORS.some((entry) => msg.includes(entry)))
    ) {
        consoleErr(msg, ...args);
    }
};

console.warn = function filterWarnings(msg, ...args) {
    if (
        typeof msg !== 'string' ||
        (typeof msg === 'string' && !SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry)))
    ) {
        consoleWarn(msg, ...args);
    }
};

const app = (
    <Provider store={store}>
        <ApolloProvider client={client}>
            <AttributionProvider>
                <SearchProvider>
                    <GraphProvider>
                        <AuthProvider>
                            <ArcaneProvider>
                                <StrikesProvider>
                                    <FusionProvider>
                                        <ActiveProvider>
                                            <CaseProvider>
                                                <NoteProvider>
                                                    <MapProvider>
                                                        <GoogleMapsApiProvider>
                                                            <BrowserRouter>
                                                                <App />
                                                            </BrowserRouter>
                                                        </GoogleMapsApiProvider>
                                                    </MapProvider>
                                                </NoteProvider>
                                            </CaseProvider>
                                        </ActiveProvider>
                                    </FusionProvider>
                                </StrikesProvider>
                            </ArcaneProvider>
                        </AuthProvider>
                    </GraphProvider>
                </SearchProvider>
            </AttributionProvider>
        </ApolloProvider>
    </Provider>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(app);

import React from 'react';
import './Styles/ArcaneMapPlaceholder.css';

function ArcaneMapPlaceholder() {
    return (
        <div className="placeholder-glow mb-3 mt-3 px-4">
            <div className="placeholder-lg placeholder arcaneTitlePlaceholder col-1 font-size-20" />
            <div className="d-flex justify-content-between align-items-center">
                <div className="placeholder-lg placeholder col-3 mb-1 mb-3" />
                <div className="d-flex justify-content-between align-items-center">
                    <div className="placeholder-lg placeholder zoomToggleTitlePlaceholder col-1 float-end ms-2 mt-1 " />
                    <div className="placeholder-lg placeholder zoomTogglePlaceholder float-end ms-2 mt-1 " />
                </div>
            </div>
            <div className="placeholder-lg placeholder arcaneMapPlaceholder w-100 col-12 mb-1" />
        </div>
    );
}

export default ArcaneMapPlaceholder;

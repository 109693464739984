import { Tooltip } from 'react-tooltip';
import React from 'react';

// Capitalizes every 1st letter of every word in a string
export const capitalize = (words) => {
    if (words) {
        const separateWord = words.toLowerCase().split(' ');
        for (let i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }
    return words;
};

// Formats a number with a comma separated as well as decimal places
export const formattedNumber = (number, decimal) =>
    Number(number)
        .toFixed(decimal)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Formats a BTC Number
export const formattedBTC = (number, decimal) => Number(number).toFixed(decimal);

export const formattedBTCWithCommas = (number, decimal) => {
    const intPart = Math.trunc(number);
    const decimalPart = Math.abs(number - intPart)
        .toFixed(decimal)
        .slice(2);

    return `${formattedNumber(intPart, 0)}.${decimalPart}`;
};

export const deepClone = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let clone;

    if (obj instanceof Date) {
        clone = new Date(obj);
    } else if (obj instanceof RegExp) {
        clone = new RegExp(obj);
    } else if (obj instanceof HTMLElement) {
        clone = obj.cloneNode(true);
    } else if (Array.isArray(obj)) {
        clone = obj.map((item) => deepClone(item));
    } else if (typeof obj === 'object' && obj !== null) {
        clone = {};
        Object.keys(obj).forEach((key) => {
            clone[key] = deepClone(obj[key]);
        });
    } else {
        clone = obj;
    }

    return clone;
};

// Splits array into array of arrays
export const chunk = (items, size) => {
    const chunks = [];
    items = [].concat(...items);

    while (items.length) {
        chunks.push(items.splice(0, size));
    }

    return chunks;
};

// Converts date to UTC epoch time
export const epochUTC = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const epochStartTime = start.getTime();
    const epochEndTime = end.getTime();

    // Get the time zone offset in minutes
    const timeZoneOffset = start.getTimezoneOffset();

    // Convert the time zone offset to milliseconds
    const timeZoneOffsetMs = timeZoneOffset * 60 * 1000;
    // Convert epochStartTime to UTC epoch time
    const epochStartTimeUTC = epochStartTime - timeZoneOffsetMs;
    // Convert epochEndTime to UTC epoch time and add a day's worth of milliseconds (24 hours) to include the end date
    const epochEndTimeUTC = epochEndTime - timeZoneOffsetMs + 24 * 60 * 60 * 1000;

    // Return the UTC epoch times
    return {
        epochStartTimeUTC,
        epochEndTimeUTC,
    };
};

// Comma seperates array items
export const commaSeparateArrayItems = (content) => {
    if (content) {
        return Array.isArray(content) ? content.join(', ') : content;
    }
    return content;
};

// Renders the tooltip
export const renderTooltip = (targetId, position = 'fixed', additionalStyles = '') => {
    if (targetId) {
        return <Tooltip id={targetId} className={`position-${position} px-2 py-1 z-3 ${additionalStyles}`} />;
    }

    return null;
};

// Renders the tooltip while also comma separating arrays
export const renderTooltipArrayCheck = (content) => {
    const tooltipContent = commaSeparateArrayItems(content);
    return tooltipContent;
};

// Finds the sum of the length of all arrays in an object
export const getTotalArrayCount = (data) =>
    Object.values(data)
        .filter(Array.isArray)
        .reduce((count, addresses) => count + addresses.length, 0);

export function getTxListPropsForNextPage(data = []) {
    let prevMin;
    let prevMax;

    data.forEach((row) => {
        const { timestamp_epoch: ts } = row;
        if (!prevMin || ts < prevMin) {
            prevMin = ts;
        }
        if (!prevMax || ts > prevMax) {
            prevMax = ts;
        }
    });

    return {
        prevMin,
        prevMax,
        prevLastTxId: data.length ? data[data.length - 1].tx_id : undefined,
    };
}

// Remove underscores, capitalize each word after underscores and add puncation if the label starts with mt
export const transformLabel = (label) =>
    label
        .replace(/[_-]/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase())
        .replace(/^mt/gi, 'Mt.');

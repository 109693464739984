import Axios from 'axios';
import CaseService from '../../../Services/CaseService';
import { clearData } from '../../SideDrawer/actions';

export const fetchData = async (shareModal, setUpdateCase, setUsersSelected, saved) => {
    if (shareModal) {
        try {
            const updatedCase = await CaseService.fetchOneCase(saved.id);

            if (updatedCase && updatedCase.data) {
                const filteredUsers = updatedCase.data.sharedWith
                    .filter((u) => u.email !== null)
                    .map((user) => ({
                        label: user.email, // Change the key from 'email' to 'label'
                        value: user.id, // Change the key from 'id' to 'value'
                    }));

                setUpdateCase(updatedCase); // Save the fetched case data to state

                setUsersSelected(filteredUsers);
            }
        } catch (error) {
            console.error(error);
        }
    }
};

// Shares case with specified users
export const shareCase = async (setUsers) => {
    try {
        const result = await Axios.get(`/api/graphs/share`);
        const options = [];
        /* eslint-disable-next-line */
        result.data.users.map((u) => options.push({ label: u.email, value: u._id }));
        setUsers(options);
    } catch (error) {
        console.error(error);
    }
};

// Shares Saved Graph With Selected Users
export const shareGraph = async (
    updateCase,
    usersSelected,
    setMessage,
    closeShareModal,
    setAllCases,
    setReducedCases,
) => {
    try {
        if (updateCase) {
            const res = await Axios.post('/api/graphs/shared', {
                users: usersSelected,
                graph: updateCase.data,
            });

            if (res.status === 200) {
                if (usersSelected.length > 0) {
                    setMessage('Graph Shared');
                } else {
                    setMessage('Graph Unshared');
                }
                setTimeout(() => closeShareModal(), 1000);
                // fetch case in background
                const resp = await CaseService.fetchCases();
                if (resp && resp.data) {
                    setAllCases(resp.data.all_cases);
                    setReducedCases(resp.data.reduced_cases);
                }
            } else {
                setMessage('Error in sharing graph');
                setTimeout(() => closeShareModal(), 1000);
            }
        }
    } catch (error) {
        console.error(error);
    }
};

// Unshare Saved Graph for yourself
export const unshareGraph = async (
    updateCase,
    usersSelected,
    setMessage,
    closeUnshareModal,
    setAllCases,
    setReducedCases,
    set_address,
    setSelectedNodeId,
    setSideDrawerIsOpen,
    setSelectedGroup,
    setSaved,
    saved,
    setDirection,
    setType,
    setColumn,
    setDateRange,
    setDateFilter,
    setSkip,
    setInboundAndOutboundTxIds,
    setLoading,
    setActiveTab,
    setLocations,
    clearIcons,
    setIsSaved,
    setProcessedNodeIds,
    setObservationCache,
) => {
    try {
        if (updateCase) {
            const res = await Axios.post('/api/graphs/unshare', {
                userId: usersSelected,
                graphId: updateCase,
            });

            if (res.status === 200) {
                setMessage('Graph Unshared');
                // close modal and clear data
                setTimeout(() => {
                    closeUnshareModal();
                    clearData(
                        set_address,
                        setSelectedNodeId,
                        setSideDrawerIsOpen,
                        setSelectedGroup,
                        setSaved,
                        saved,
                        setDirection,
                        setType,
                        setColumn,
                        setDateRange,
                        setDateFilter,
                        setSkip,
                        setInboundAndOutboundTxIds,
                        setLoading,
                        setActiveTab,
                        setLocations,
                        clearIcons,
                        setIsSaved,
                        setProcessedNodeIds,
                        setObservationCache,
                    );
                }, 1000);
                // fetch case in background
                const resp = await CaseService.fetchCases();
                if (resp && resp.data) {
                    setAllCases(resp.data.all_cases);
                    setReducedCases(resp.data.reduced_cases);
                }
            }
        } else {
            setMessage('Error in Graph Unshared');
            setTimeout(() => closeUnshareModal(), 1000);
        }
    } catch (error) {
        setMessage('Error in Graph Unshared');
        setTimeout(() => closeUnshareModal(), 1000);
        console.error(error);
    }
};

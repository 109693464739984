import CaseService from '../../../Services/CaseService';
import { htmlTitle, nodeAttributionTitle, noProviderDataTitle, nodeAddressTitle } from '../../Vis/titles';
import { shareCase } from './Share';
import { getBulkAttributions } from '../../Fusion/attributions';
import { themeStyles } from '../../../Themes/styles';

// Fetches Selected Saved Case From DB
export const fetchOneCase = async (
    id,
    setDrawerIsOpen,
    setLoadingAddresses,
    setActiveAddress,
    setSaved,
    setIsSaved,
    setMessage,
    network,
    setUsers,
    setAttributionCache,
    attributionCache,
    user,
    setAutoSave,
) => {
    setIsSaved(false);
    setDrawerIsOpen(false);
    setLoadingAddresses(true);
    setActiveAddress(null);

    const floorX = 0;
    const floorY = Math.floor(0 - window.innerHeight / 2 / 30) * 30;

    const res = await CaseService.fetchOneCase(id);

    if (res && res.data) {
        res.data.nodes.forEach((el) => {
            el.title = htmlTitle(el.title);
        });
        res.data.edges.forEach((el) => {
            el.title = htmlTitle(el.title);
        });
    }

    if (res?.status === 200) {
        /* eslint-disable-next-line */
        setAutoSave(user._id === res.data.savedBy);

        if (res.data.nodes.length >= 1) {
            res.data.nodes.map((obj) => {
                if (obj.type === 'address' && obj.attributionData) {
                    const cache = attributionCache.find((att) => att.address === obj.address);
                    if (!cache) {
                        setAttributionCache((c) => [
                            ...c,
                            {
                                address: obj.address,
                                attribution: obj.attributionData,
                            },
                        ]);
                    }
                }
                return obj;
            });
        }

        setSaved({
            /* eslint-disable-next-line */
            id: res.data._id,
            case: res.data.case,
            address: res.data.address,
            graph: res.data.graph,
            nodes: res.data.nodes,
            edges: res.data.edges,
            sharedWith: res.data.sharedWith,
            savedBy: res.data.savedBy,
            graphType: res.data.graphType,
        });

        setAutoSave(true);
        shareCase(setUsers);
        setActiveAddress(res.data.address);
        setIsSaved(true);
        setLoadingAddresses(false);
    } else {
        setMessage('Error retrieving address attribution. Try again!');
        setLoadingAddresses(false);
    }

    setTimeout(() => {
        network.redraw();
        network.fit();
        network.moveTo({
            offset: {
                x: floorX,
                y: floorY,
            },
        });
    }, 100);
};

// Fetches Selected Saved Case From DB
export const updateAttributionFromSavedNodes = async (
    setSaved,
    network,
    setAttributionCache,
    apiKey,
    attribution_color_scheme,
    saved,
    mismatchedNodes,
) => {
    const styles = themeStyles();
    const { nodeColor, nodeBorderColor } = styles;
    const floorX = 0;
    const floorY = Math.floor(0 - window.innerHeight / 2 / 30) * 30;
    if (apiKey && mismatchedNodes && mismatchedNodes.length > 0) {
        try {
            const newNodes = [];
            const bulkRes = await getBulkAttributions(apiKey, mismatchedNodes);
            if (bulkRes) {
                saved.nodes.forEach((node) => {
                    const attributionToUpdate = bulkRes.find((att) => att.address === node.address);

                    if (attributionToUpdate) {
                        const attribution = attributionToUpdate;
                        const label =
                            attribution &&
                            attribution.attribution &&
                            attribution.attribution.find((lab) => lab.name !== 'Unknown');

                        const matchingColorScheme =
                            label &&
                            label.type &&
                            attribution_color_scheme.find(
                                (colorScheme) => colorScheme.type === label.type.toLowerCase(),
                            );

                        const attributionColor = attribution
                            ? matchingColorScheme
                                ? matchingColorScheme.color
                                : '#8E91A3'
                            : '#8E91A3';

                        const newNode = {
                            ...node,
                            attribution: !!attributionToUpdate?.attribution,
                            attributionData: attributionToUpdate.attribution || [],
                            label: `${node.address && `${node.address.substring(0, 20)}...`}\n${
                                label ? label.name : ''
                            }`,
                            title: apiKey
                                ? attribution && attribution.attribution
                                    ? nodeAttributionTitle(attribution.attribution, attribution_color_scheme)
                                    : noProviderDataTitle()
                                : nodeAddressTitle(node.address),
                            color: {
                                background: nodeColor,
                                border: attributionColor || nodeBorderColor,
                                highlight: {
                                    background: nodeColor,
                                    border: attributionColor || nodeBorderColor,
                                },
                                hover: {
                                    border: attributionColor || nodeBorderColor,
                                },
                            },
                            isSearched: false,
                        };
                        newNodes.push(newNode);
                    } else {
                        newNodes.push(node);
                    }
                });

                setAttributionCache((cache) => [
                    ...cache,
                    ...bulkRes.map((obj) => ({
                        ...obj,
                        attribution: obj.attribution || [],
                    })),
                ]);

                setSaved((prevSaved) => ({
                    ...prevSaved,
                    nodes: newNodes,
                }));
            }
            setTimeout(() => {
                network.redraw();
                network.fit();
                network.moveTo({
                    offset: {
                        x: floorX,
                        y: floorY,
                    },
                });
            }, 100);
        } catch (error) {
            console.error('Error retrieving address attribution. Try again!', error);
        }
    }
};

import React, { createContext, useState } from 'react';
import CaseService from '../Services/CaseService';

export const CaseContext = createContext();

function Case({ children }) {
    const [allCases, setAllCases] = useState(null);
    const [reducedCases, setReducedCases] = useState(null);
    const [completed, setCompleted] = useState(0);
    const [progress, setProgress] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [message, setMessage] = useState(null);
    const [threshhold, setthreshhold] = useState(0);
    const [disDefault, setdisDefault] = useState(false);
    const [drp_link, setdrp_link] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [caseInput, setCaseInput] = useState(true);
    const [existingCase, setExistingCase] = useState(false);
    const [newCase, setNewCase] = useState(true);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [activeAddress, setActiveAddress] = useState(null);
    const [expand, setExpand] = useState(false);
    const [title, setTitle] = useState('');
    const [updateCases, setUpdateCases] = useState(false);
    const [caseSearch, setCaseSearch] = useState('');
    const [graphSearch, setGraphSearch] = useState('');
    const [supportModal, setSupportModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [unshareModal, setUnshareModal] = useState(false);
    const [loadingCases, setLoadingCases] = useState(false);

    const fetchCases = async () => {
        try {
            const resp = await CaseService.fetchCases();
            if (resp && resp.data) {
                setReducedCases(resp.data.reduced_cases);
                setAllCases(resp.data.all_cases);
            }
        } catch (error) {
            // Handle any errors that occurred during fetching data
            console.error(error);
        }
    };

    return (
        <CaseContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                allCases,
                setAllCases,
                reducedCases,
                setReducedCases,
                completed,
                setCompleted,
                progress,
                setProgress,
                addModal,
                setAddModal,
                message,
                setMessage,
                threshhold,
                setthreshhold,
                disDefault,
                setdisDefault,
                drp_link,
                setdrp_link,
                drawerIsOpen,
                setDrawerIsOpen,
                caseInput,
                setCaseInput,
                existingCase,
                setExistingCase,
                newCase,
                setNewCase,
                loadingAddresses,
                setLoadingAddresses,
                activeAddress,
                setActiveAddress,
                expand,
                setExpand,
                title,
                setTitle,
                updateCases,
                setUpdateCases,
                caseSearch,
                setCaseSearch,
                graphSearch,
                setGraphSearch,
                supportModal,
                setSupportModal,
                deleteModal,
                setDeleteModal,
                loadingCases,
                setLoadingCases,
                shareModal,
                setShareModal,
                fetchCases,
                unshareModal,
                setUnshareModal,
            }}
        >
            {children}
        </CaseContext.Provider>
    );
}

export default Case;

import React, { createContext, useState } from 'react';

export const GoogleMapsApiContext = createContext();

function GoogleMapsApiProvider({ children }) {
    const [googleMapsApiLoaded, setGoogleMapsApiLoaded] = useState(false);
    const [loadError, setLoadError] = useState(null);
    return (
        <GoogleMapsApiContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{ googleMapsApiLoaded, setGoogleMapsApiLoaded, loadError, setLoadError }}
        >
            {children}
        </GoogleMapsApiContext.Provider>
    );
}

export default GoogleMapsApiProvider;

export const reCenter = (network, setSelectedNodeId) => {
    if (setSelectedNodeId) {
        setSelectedNodeId(false);
    }
    if (network) {
        network.redraw();
        network.fit();
        network.moveTo({
            offset: {
                x: 0,
                y: Math.floor(0 - window.innerHeight / 2 / 30) * 30,
            },
        });
    }
};

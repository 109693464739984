export const countries = [
    {
        Country: 'Afghanistan',
        country_code_2: 'AF',
        country_code_3: 'AFG',
        'Numeric code': 4,
        'Latitude (average)': 33,
        'Longitude (average)': 65,
    },
    {
        Country: 'Albania',
        country_code_2: 'AL',
        country_code_3: 'ALB',
        'Numeric code': 8,
        'Latitude (average)': 41,
        'Longitude (average)': 20,
    },
    {
        Country: 'Algeria',
        country_code_2: 'DZ',
        country_code_3: 'DZA',
        'Numeric code': 12,
        'Latitude (average)': 28,
        'Longitude (average)': 3,
    },
    {
        Country: 'American Samoa',
        country_code_2: 'AS',
        country_code_3: 'ASM',
        'Numeric code': 16,
        'Latitude (average)': -14.3333,
        'Longitude (average)': -170,
    },
    {
        Country: 'Andorra',
        country_code_2: 'AD',
        country_code_3: 'AND',
        'Numeric code': 20,
        'Latitude (average)': 42.5,
        'Longitude (average)': 1.6,
    },
    {
        Country: 'Angola',
        country_code_2: 'AO',
        country_code_3: 'AGO',
        'Numeric code': 24,
        'Latitude (average)': -12.5,
        'Longitude (average)': 18.5,
    },
    {
        Country: 'Anguilla',
        country_code_2: 'AI',
        country_code_3: 'AIA',
        'Numeric code': 660,
        'Latitude (average)': 18.25,
        'Longitude (average)': -63.1667,
    },
    {
        Country: 'Antarctica',
        country_code_2: 'AQ',
        country_code_3: 'ATA',
        'Numeric code': 10,
        'Latitude (average)': -90,
        'Longitude (average)': 0,
    },
    {
        Country: 'Antigua and Barbuda',
        country_code_2: 'AG',
        country_code_3: 'ATG',
        'Numeric code': 28,
        'Latitude (average)': 17.05,
        'Longitude (average)': -61.8,
    },
    {
        Country: 'Argentina',
        country_code_2: 'AR',
        country_code_3: 'ARG',
        'Numeric code': 32,
        'Latitude (average)': -34,
        'Longitude (average)': -64,
    },
    {
        Country: 'Armenia',
        country_code_2: 'AM',
        country_code_3: 'ARM',
        'Numeric code': 51,
        'Latitude (average)': 40,
        'Longitude (average)': 45,
    },
    {
        Country: 'Aruba',
        country_code_2: 'AW',
        country_code_3: 'ABW',
        'Numeric code': 533,
        'Latitude (average)': 12.5,
        'Longitude (average)': -69.9667,
    },
    {
        Country: 'Australia',
        country_code_2: 'AU',
        country_code_3: 'AUS',
        'Numeric code': 36,
        'Latitude (average)': -27,
        'Longitude (average)': 133,
    },
    {
        Country: 'Austria',
        country_code_2: 'AT',
        country_code_3: 'AUT',
        'Numeric code': 40,
        'Latitude (average)': 47.3333,
        'Longitude (average)': 13.3333,
    },
    {
        Country: 'Azerbaijan',
        country_code_2: 'AZ',
        country_code_3: 'AZE',
        'Numeric code': 31,
        'Latitude (average)': 40.5,
        'Longitude (average)': 47.5,
    },
    {
        Country: 'Bahamas',
        country_code_2: 'BS',
        country_code_3: 'BHS',
        'Numeric code': 44,
        'Latitude (average)': 24.25,
        'Longitude (average)': -76,
    },
    {
        Country: 'Bahrain',
        country_code_2: 'BH',
        country_code_3: 'BHR',
        'Numeric code': 48,
        'Latitude (average)': 26,
        'Longitude (average)': 50.55,
    },
    {
        Country: 'Bangladesh',
        country_code_2: 'BD',
        country_code_3: 'BGD',
        'Numeric code': 50,
        'Latitude (average)': 24,
        'Longitude (average)': 90,
    },
    {
        Country: 'Barbados',
        country_code_2: 'BB',
        country_code_3: 'BRB',
        'Numeric code': 52,
        'Latitude (average)': 13.1667,
        'Longitude (average)': -59.5333,
    },
    {
        Country: 'Belarus',
        country_code_2: 'BY',
        country_code_3: 'BLR',
        'Numeric code': 112,
        'Latitude (average)': 53,
        'Longitude (average)': 28,
    },
    {
        Country: 'Belgium',
        country_code_2: 'BE',
        country_code_3: 'BEL',
        'Numeric code': 56,
        'Latitude (average)': 50.8333,
        'Longitude (average)': 4,
    },
    {
        Country: 'Belize',
        country_code_2: 'BZ',
        country_code_3: 'BLZ',
        'Numeric code': 84,
        'Latitude (average)': 17.25,
        'Longitude (average)': -88.75,
    },
    {
        Country: 'Benin',
        country_code_2: 'BJ',
        country_code_3: 'BEN',
        'Numeric code': 204,
        'Latitude (average)': 9.5,
        'Longitude (average)': 2.25,
    },
    {
        Country: 'Bermuda',
        country_code_2: 'BM',
        country_code_3: 'BMU',
        'Numeric code': 60,
        'Latitude (average)': 32.3333,
        'Longitude (average)': -64.75,
    },
    {
        Country: 'Bhutan',
        country_code_2: 'BT',
        country_code_3: 'BTN',
        'Numeric code': 64,
        'Latitude (average)': 27.5,
        'Longitude (average)': 90.5,
    },
    {
        Country: 'Bolivia, Plurinational State of',
        country_code_2: 'BO',
        country_code_3: 'BOL',
        'Numeric code': 68,
        'Latitude (average)': -17,
        'Longitude (average)': -65,
    },
    {
        Country: 'Bolivia',
        country_code_2: 'BO',
        country_code_3: 'BOL',
        'Numeric code': 68,
        'Latitude (average)': -17,
        'Longitude (average)': -65,
    },
    {
        Country: 'Bosnia and Herzegovina',
        country_code_2: 'BA',
        country_code_3: 'BIH',
        'Numeric code': 70,
        'Latitude (average)': 44,
        'Longitude (average)': 18,
    },
    {
        Country: 'Botswana',
        country_code_2: 'BW',
        country_code_3: 'BWA',
        'Numeric code': 72,
        'Latitude (average)': -22,
        'Longitude (average)': 24,
    },
    {
        Country: 'Bouvet Island',
        country_code_2: 'BV',
        country_code_3: 'BVT',
        'Numeric code': 74,
        'Latitude (average)': -54.4333,
        'Longitude (average)': 3.4,
    },
    {
        Country: 'Brazil',
        country_code_2: 'BR',
        country_code_3: 'BRA',
        'Numeric code': 76,
        'Latitude (average)': -10,
        'Longitude (average)': -55,
    },
    {
        Country: 'British Indian Ocean Territory',
        country_code_2: 'IO',
        country_code_3: 'IOT',
        'Numeric code': 86,
        'Latitude (average)': -6,
        'Longitude (average)': 71.5,
    },
    {
        Country: 'Brunei Darussalam',
        country_code_2: 'BN',
        country_code_3: 'BRN',
        'Numeric code': 96,
        'Latitude (average)': 4.5,
        'Longitude (average)': 114.6667,
    },
    {
        Country: 'Brunei',
        country_code_2: 'BN',
        country_code_3: 'BRN',
        'Numeric code': 96,
        'Latitude (average)': 4.5,
        'Longitude (average)': 114.6667,
    },
    {
        Country: 'Bulgaria',
        country_code_2: 'BG',
        country_code_3: 'BGR',
        'Numeric code': 100,
        'Latitude (average)': 43,
        'Longitude (average)': 25,
    },
    {
        Country: 'Burkina Faso',
        country_code_2: 'BF',
        country_code_3: 'BFA',
        'Numeric code': 854,
        'Latitude (average)': 13,
        'Longitude (average)': -2,
    },
    {
        Country: 'Burundi',
        country_code_2: 'BI',
        country_code_3: 'BDI',
        'Numeric code': 108,
        'Latitude (average)': -3.5,
        'Longitude (average)': 30,
    },
    {
        Country: 'Cambodia',
        country_code_2: 'KH',
        country_code_3: 'KHM',
        'Numeric code': 116,
        'Latitude (average)': 13,
        'Longitude (average)': 105,
    },
    {
        Country: 'Cameroon',
        country_code_2: 'CM',
        country_code_3: 'CMR',
        'Numeric code': 120,
        'Latitude (average)': 6,
        'Longitude (average)': 12,
    },
    {
        Country: 'Canada',
        country_code_2: 'CA',
        country_code_3: 'CAN',
        'Numeric code': 124,
        'Latitude (average)': 60,
        'Longitude (average)': -95,
    },
    {
        Country: 'Cape Verde',
        country_code_2: 'CV',
        country_code_3: 'CPV',
        'Numeric code': 132,
        'Latitude (average)': 16,
        'Longitude (average)': -24,
    },
    {
        Country: 'Cayman Islands',
        country_code_2: 'KY',
        country_code_3: 'CYM',
        'Numeric code': 136,
        'Latitude (average)': 19.5,
        'Longitude (average)': -80.5,
    },
    {
        Country: 'Central African Republic',
        country_code_2: 'CF',
        country_code_3: 'CAF',
        'Numeric code': 140,
        'Latitude (average)': 7,
        'Longitude (average)': 21,
    },
    {
        Country: 'Chad',
        country_code_2: 'TD',
        country_code_3: 'TCD',
        'Numeric code': 148,
        'Latitude (average)': 15,
        'Longitude (average)': 19,
    },
    {
        Country: 'Chile',
        country_code_2: 'CL',
        country_code_3: 'CHL',
        'Numeric code': 152,
        'Latitude (average)': -30,
        'Longitude (average)': -71,
    },
    {
        Country: 'China',
        country_code_2: 'CN',
        country_code_3: 'CHN',
        'Numeric code': 156,
        'Latitude (average)': 35,
        'Longitude (average)': 105,
    },
    {
        Country: 'Christmas Island',
        country_code_2: 'CX',
        country_code_3: 'CXR',
        'Numeric code': 162,
        'Latitude (average)': -10.5,
        'Longitude (average)': 105.6667,
    },
    {
        Country: 'Cocos (Keeling) Islands',
        country_code_2: 'CC',
        country_code_3: 'CCK',
        'Numeric code': 166,
        'Latitude (average)': -12.5,
        'Longitude (average)': 96.8333,
    },
    {
        Country: 'Colombia',
        country_code_2: 'CO',
        country_code_3: 'COL',
        'Numeric code': 170,
        'Latitude (average)': 4,
        'Longitude (average)': -72,
    },
    {
        Country: 'Comoros',
        country_code_2: 'KM',
        country_code_3: 'COM',
        'Numeric code': 174,
        'Latitude (average)': -12.1667,
        'Longitude (average)': 44.25,
    },
    {
        Country: 'Congo',
        country_code_2: 'CG',
        country_code_3: 'COG',
        'Numeric code': 178,
        'Latitude (average)': -1,
        'Longitude (average)': 15,
    },
    {
        Country: 'Congo, the Democratic Republic of the',
        country_code_2: 'CD',
        country_code_3: 'COD',
        'Numeric code': 180,
        'Latitude (average)': 0,
        'Longitude (average)': 25,
    },
    {
        Country: 'Cook Islands',
        country_code_2: 'CK',
        country_code_3: 'COK',
        'Numeric code': 184,
        'Latitude (average)': -21.2333,
        'Longitude (average)': -159.7667,
    },
    {
        Country: 'Costa Rica',
        country_code_2: 'CR',
        country_code_3: 'CRI',
        'Numeric code': 188,
        'Latitude (average)': 10,
        'Longitude (average)': -84,
    },
    {
        Country: "Côte d'Ivoire",
        country_code_2: 'CI',
        country_code_3: 'CIV',
        'Numeric code': 384,
        'Latitude (average)': 8,
        'Longitude (average)': -5,
    },
    {
        Country: 'Ivory Coast',
        country_code_2: 'CI',
        country_code_3: 'CIV',
        'Numeric code': 384,
        'Latitude (average)': 8,
        'Longitude (average)': -5,
    },
    {
        Country: 'Croatia',
        country_code_2: 'HR',
        country_code_3: 'HRV',
        'Numeric code': 191,
        'Latitude (average)': 45.1667,
        'Longitude (average)': 15.5,
    },
    {
        Country: 'Cuba',
        country_code_2: 'CU',
        country_code_3: 'CUB',
        'Numeric code': 192,
        'Latitude (average)': 21.5,
        'Longitude (average)': -80,
    },
    {
        Country: 'Cyprus',
        country_code_2: 'CY',
        country_code_3: 'CYP',
        'Numeric code': 196,
        'Latitude (average)': 35,
        'Longitude (average)': 33,
    },
    {
        Country: 'Czech Republic',
        country_code_2: 'CZ',
        country_code_3: 'CZE',
        'Numeric code': 203,
        'Latitude (average)': 49.75,
        'Longitude (average)': 15.5,
    },
    {
        Country: 'Czechia',
        country_code_2: 'CZ',
        country_code_3: 'CZE',
        'Numeric code': 203,
        'Latitude (average)': 49.75,
        'Longitude (average)': 15.5,
    },
    {
        Country: 'Denmark',
        country_code_2: 'DK',
        country_code_3: 'DNK',
        'Numeric code': 208,
        'Latitude (average)': 56,
        'Longitude (average)': 10,
    },
    {
        Country: 'Djibouti',
        country_code_2: 'DJ',
        country_code_3: 'DJI',
        'Numeric code': 262,
        'Latitude (average)': 11.5,
        'Longitude (average)': 43,
    },
    {
        Country: 'Dominica',
        country_code_2: 'DM',
        country_code_3: 'DMA',
        'Numeric code': 212,
        'Latitude (average)': 15.4167,
        'Longitude (average)': -61.3333,
    },
    {
        Country: 'Dominican Republic',
        country_code_2: 'DO',
        country_code_3: 'DOM',
        'Numeric code': 214,
        'Latitude (average)': 19,
        'Longitude (average)': -70.6667,
    },
    {
        Country: 'Ecuador',
        country_code_2: 'EC',
        country_code_3: 'ECU',
        'Numeric code': 218,
        'Latitude (average)': -2,
        'Longitude (average)': -77.5,
    },
    {
        Country: 'Egypt',
        country_code_2: 'EG',
        country_code_3: 'EGY',
        'Numeric code': 818,
        'Latitude (average)': 27,
        'Longitude (average)': 30,
    },
    {
        Country: 'El Salvador',
        country_code_2: 'SV',
        country_code_3: 'SLV',
        'Numeric code': 222,
        'Latitude (average)': 13.8333,
        'Longitude (average)': -88.9167,
    },
    {
        Country: 'Equatorial Guinea',
        country_code_2: 'GQ',
        country_code_3: 'GNQ',
        'Numeric code': 226,
        'Latitude (average)': 2,
        'Longitude (average)': 10,
    },
    {
        Country: 'Eritrea',
        country_code_2: 'ER',
        country_code_3: 'ERI',
        'Numeric code': 232,
        'Latitude (average)': 15,
        'Longitude (average)': 39,
    },
    {
        Country: 'Estonia',
        country_code_2: 'EE',
        country_code_3: 'EST',
        'Numeric code': 233,
        'Latitude (average)': 59,
        'Longitude (average)': 26,
    },
    {
        Country: 'Ethiopia',
        country_code_2: 'ET',
        country_code_3: 'ETH',
        'Numeric code': 231,
        'Latitude (average)': 8,
        'Longitude (average)': 38,
    },
    {
        Country: 'Falkland Islands',
        country_code_2: 'FK',
        country_code_3: 'FLK',
        'Numeric code': 238,
        'Latitude (average)': -51.75,
        'Longitude (average)': -59,
    },
    {
        Country: 'Faroe Islands',
        country_code_2: 'FO',
        country_code_3: 'FRO',
        'Numeric code': 234,
        'Latitude (average)': 62,
        'Longitude (average)': -7,
    },
    {
        Country: 'Fiji',
        country_code_2: 'FJ',
        country_code_3: 'FJI',
        'Numeric code': 242,
        'Latitude (average)': -18,
        'Longitude (average)': 175,
    },
    {
        Country: 'Finland',
        country_code_2: 'FI',
        country_code_3: 'FIN',
        'Numeric code': 246,
        'Latitude (average)': 64,
        'Longitude (average)': 26,
    },
    {
        Country: 'France',
        country_code_2: 'FR',
        country_code_3: 'FRA',
        'Numeric code': 250,
        'Latitude (average)': 46,
        'Longitude (average)': 2,
    },
    {
        Country: 'French Guiana',
        country_code_2: 'GF',
        country_code_3: 'GUF',
        'Numeric code': 254,
        'Latitude (average)': 4,
        'Longitude (average)': -53,
    },
    {
        Country: 'French Polynesia',
        country_code_2: 'PF',
        country_code_3: 'PYF',
        'Numeric code': 258,
        'Latitude (average)': -15,
        'Longitude (average)': -140,
    },
    {
        Country: 'French Southern Territories',
        country_code_2: 'TF',
        country_code_3: 'ATF',
        'Numeric code': 260,
        'Latitude (average)': -43,
        'Longitude (average)': 67,
    },
    {
        Country: 'Gabon',
        country_code_2: 'GA',
        country_code_3: 'GAB',
        'Numeric code': 266,
        'Latitude (average)': -1,
        'Longitude (average)': 11.75,
    },
    {
        Country: 'Gambia',
        country_code_2: 'GM',
        country_code_3: 'GMB',
        'Numeric code': 270,
        'Latitude (average)': 13.4667,
        'Longitude (average)': -16.5667,
    },
    {
        Country: 'Georgia',
        country_code_2: 'GE',
        country_code_3: 'GEO',
        'Numeric code': 268,
        'Latitude (average)': 42,
        'Longitude (average)': 43.5,
    },
    {
        Country: 'Germany',
        country_code_2: 'DE',
        country_code_3: 'DEU',
        'Numeric code': 276,
        'Latitude (average)': 51,
        'Longitude (average)': 9,
    },
    {
        Country: 'Ghana',
        country_code_2: 'GH',
        country_code_3: 'GHA',
        'Numeric code': 288,
        'Latitude (average)': 8,
        'Longitude (average)': -2,
    },
    {
        Country: 'Gibraltar',
        country_code_2: 'GI',
        country_code_3: 'GIB',
        'Numeric code': 292,
        'Latitude (average)': 36.1833,
        'Longitude (average)': -5.3667,
    },
    {
        Country: 'Greece',
        country_code_2: 'GR',
        country_code_3: 'GRC',
        'Numeric code': 300,
        'Latitude (average)': 39,
        'Longitude (average)': 22,
    },
    {
        Country: 'Greenland',
        country_code_2: 'GL',
        country_code_3: 'GRL',
        'Numeric code': 304,
        'Latitude (average)': 72,
        'Longitude (average)': -40,
    },
    {
        Country: 'Grenada',
        country_code_2: 'GD',
        country_code_3: 'GRD',
        'Numeric code': 308,
        'Latitude (average)': 12.1167,
        'Longitude (average)': -61.6667,
    },
    {
        Country: 'Guadeloupe',
        country_code_2: 'GP',
        country_code_3: 'GLP',
        'Numeric code': 312,
        'Latitude (average)': 16.25,
        'Longitude (average)': -61.5833,
    },
    {
        Country: 'Guam',
        country_code_2: 'GU',
        country_code_3: 'GUM',
        'Numeric code': 316,
        'Latitude (average)': 13.4667,
        'Longitude (average)': 144.7833,
    },
    {
        Country: 'Guatemala',
        country_code_2: 'GT',
        country_code_3: 'GTM',
        'Numeric code': 320,
        'Latitude (average)': 15.5,
        'Longitude (average)': -90.25,
    },
    {
        Country: 'Guernsey',
        country_code_2: 'GG',
        country_code_3: 'GGY',
        'Numeric code': 831,
        'Latitude (average)': 49.5,
        'Longitude (average)': -2.56,
    },
    {
        Country: 'Guinea',
        country_code_2: 'GN',
        country_code_3: 'GIN',
        'Numeric code': 324,
        'Latitude (average)': 11,
        'Longitude (average)': -10,
    },
    {
        Country: 'Guinea-Bissau',
        country_code_2: 'GW',
        country_code_3: 'GNB',
        'Numeric code': 624,
        'Latitude (average)': 12,
        'Longitude (average)': -15,
    },
    {
        Country: 'Guyana',
        country_code_2: 'GY',
        country_code_3: 'GUY',
        'Numeric code': 328,
        'Latitude (average)': 5,
        'Longitude (average)': -59,
    },
    {
        Country: 'Haiti',
        country_code_2: 'HT',
        country_code_3: 'HTI',
        'Numeric code': 332,
        'Latitude (average)': 19,
        'Longitude (average)': -72.4167,
    },
    {
        Country: 'Heard Island and McDonald Islands',
        country_code_2: 'HM',
        country_code_3: 'HMD',
        'Numeric code': 334,
        'Latitude (average)': -53.1,
        'Longitude (average)': 72.5167,
    },
    {
        Country: 'Holy See (Vatican City State)',
        country_code_2: 'VA',
        country_code_3: 'VAT',
        'Numeric code': 336,
        'Latitude (average)': 41.9,
        'Longitude (average)': 12.45,
    },
    {
        Country: 'Honduras',
        country_code_2: 'HN',
        country_code_3: 'HND',
        'Numeric code': 340,
        'Latitude (average)': 15,
        'Longitude (average)': -86.5,
    },
    {
        Country: 'Hong Kong',
        country_code_2: 'HK',
        country_code_3: 'HKG',
        'Numeric code': 344,
        'Latitude (average)': 22.25,
        'Longitude (average)': 114.1667,
    },
    {
        Country: 'Hungary',
        country_code_2: 'HU',
        country_code_3: 'HUN',
        'Numeric code': 348,
        'Latitude (average)': 47,
        'Longitude (average)': 20,
    },
    {
        Country: 'Iceland',
        country_code_2: 'IS',
        country_code_3: 'ISL',
        'Numeric code': 352,
        'Latitude (average)': 65,
        'Longitude (average)': -18,
    },
    {
        Country: 'India',
        country_code_2: 'IN',
        country_code_3: 'IND',
        'Numeric code': 356,
        'Latitude (average)': 20,
        'Longitude (average)': 77,
    },
    {
        Country: 'Indonesia',
        country_code_2: 'ID',
        country_code_3: 'IDN',
        'Numeric code': 360,
        'Latitude (average)': -5,
        'Longitude (average)': 120,
    },
    {
        Country: 'Iran',
        country_code_2: 'IR',
        country_code_3: 'IRN',
        'Numeric code': 364,
        'Latitude (average)': 32,
        'Longitude (average)': 53,
    },
    {
        Country: 'Iraq',
        country_code_2: 'IQ',
        country_code_3: 'IRQ',
        'Numeric code': 368,
        'Latitude (average)': 33,
        'Longitude (average)': 44,
    },
    {
        Country: 'Ireland',
        country_code_2: 'IE',
        country_code_3: 'IRL',
        'Numeric code': 372,
        'Latitude (average)': 53,
        'Longitude (average)': -8,
    },
    {
        Country: 'Isle of Man',
        country_code_2: 'IM',
        country_code_3: 'IMN',
        'Numeric code': 833,
        'Latitude (average)': 54.23,
        'Longitude (average)': -4.55,
    },
    {
        Country: 'Israel',
        country_code_2: 'IL',
        country_code_3: 'ISR',
        'Numeric code': 376,
        'Latitude (average)': 31.5,
        'Longitude (average)': 34.75,
    },
    {
        Country: 'Italy',
        country_code_2: 'IT',
        country_code_3: 'ITA',
        'Numeric code': 380,
        'Latitude (average)': 42.8333,
        'Longitude (average)': 12.8333,
    },
    {
        Country: 'Jamaica',
        country_code_2: 'JM',
        country_code_3: 'JAM',
        'Numeric code': 388,
        'Latitude (average)': 18.25,
        'Longitude (average)': -77.5,
    },
    {
        Country: 'Japan',
        country_code_2: 'JP',
        country_code_3: 'JPN',
        'Numeric code': 392,
        'Latitude (average)': 36,
        'Longitude (average)': 138,
    },
    {
        Country: 'Jersey',
        country_code_2: 'JE',
        country_code_3: 'JEY',
        'Numeric code': 832,
        'Latitude (average)': 49.21,
        'Longitude (average)': -2.13,
    },
    {
        Country: 'Jordan',
        country_code_2: 'JO',
        country_code_3: 'JOR',
        'Numeric code': 400,
        'Latitude (average)': 31,
        'Longitude (average)': 36,
    },
    {
        Country: 'Kazakhstan',
        country_code_2: 'KZ',
        country_code_3: 'KAZ',
        'Numeric code': 398,
        'Latitude (average)': 48,
        'Longitude (average)': 68,
    },
    {
        Country: 'Kenya',
        country_code_2: 'KE',
        country_code_3: 'KEN',
        'Numeric code': 404,
        'Latitude (average)': 1,
        'Longitude (average)': 38,
    },
    {
        Country: 'Kiribati',
        country_code_2: 'KI',
        country_code_3: 'KIR',
        'Numeric code': 296,
        'Latitude (average)': 1.4167,
        'Longitude (average)': 173,
    },
    {
        Country: 'North Korea',
        country_code_2: 'KP',
        country_code_3: 'PRK',
        'Numeric code': 408,
        'Latitude (average)': 40,
        'Longitude (average)': 127,
    },
    {
        Country: 'South Korea',
        country_code_2: 'KR',
        country_code_3: 'KOR',
        'Numeric code': 410,
        'Latitude (average)': 37,
        'Longitude (average)': 127.5,
    },
    {
        Country: 'South Korea',
        country_code_2: 'KR',
        country_code_3: 'KOR',
        'Numeric code': 410,
        'Latitude (average)': 37,
        'Longitude (average)': 127.5,
    },
    {
        Country: 'Kuwait',
        country_code_2: 'KW',
        country_code_3: 'KWT',
        'Numeric code': 414,
        'Latitude (average)': 29.3375,
        'Longitude (average)': 47.6581,
    },
    {
        Country: 'Kyrgyzstan',
        country_code_2: 'KG',
        country_code_3: 'KGZ',
        'Numeric code': 417,
        'Latitude (average)': 41,
        'Longitude (average)': 75,
    },
    {
        Country: 'Laos',
        country_code_2: 'LA',
        country_code_3: 'LAO',
        'Numeric code': 418,
        'Latitude (average)': 18,
        'Longitude (average)': 105,
    },
    {
        Country: 'Latvia',
        country_code_2: 'LV',
        country_code_3: 'LVA',
        'Numeric code': 428,
        'Latitude (average)': 57,
        'Longitude (average)': 25,
    },
    {
        Country: 'Lebanon',
        country_code_2: 'LB',
        country_code_3: 'LBN',
        'Numeric code': 422,
        'Latitude (average)': 33.8333,
        'Longitude (average)': 35.8333,
    },
    {
        Country: 'Lesotho',
        country_code_2: 'LS',
        country_code_3: 'LSO',
        'Numeric code': 426,
        'Latitude (average)': -29.5,
        'Longitude (average)': 28.5,
    },
    {
        Country: 'Liberia',
        country_code_2: 'LR',
        country_code_3: 'LBR',
        'Numeric code': 430,
        'Latitude (average)': 6.5,
        'Longitude (average)': -9.5,
    },
    {
        Country: 'Libya',
        country_code_2: 'LY',
        country_code_3: 'LBY',
        'Numeric code': 434,
        'Latitude (average)': 25,
        'Longitude (average)': 17,
    },
    {
        Country: 'Liechtenstein',
        country_code_2: 'LI',
        country_code_3: 'LIE',
        'Numeric code': 438,
        'Latitude (average)': 47.1667,
        'Longitude (average)': 9.5333,
    },
    {
        Country: 'Lithuania',
        country_code_2: 'LT',
        country_code_3: 'LTU',
        'Numeric code': 440,
        'Latitude (average)': 56,
        'Longitude (average)': 24,
    },
    {
        Country: 'Luxembourg',
        country_code_2: 'LU',
        country_code_3: 'LUX',
        'Numeric code': 442,
        'Latitude (average)': 49.75,
        'Longitude (average)': 6.1667,
    },
    {
        Country: 'Macao',
        country_code_2: 'MO',
        country_code_3: 'MAC',
        'Numeric code': 446,
        'Latitude (average)': 22.1667,
        'Longitude (average)': 113.55,
    },
    {
        Country: 'Macedonia',
        country_code_2: 'MK',
        country_code_3: 'MKD',
        'Numeric code': 807,
        'Latitude (average)': 41.8333,
        'Longitude (average)': 22,
    },
    {
        Country: 'Madagascar',
        country_code_2: 'MG',
        country_code_3: 'MDG',
        'Numeric code': 450,
        'Latitude (average)': -20,
        'Longitude (average)': 47,
    },
    {
        Country: 'Malawi',
        country_code_2: 'MW',
        country_code_3: 'MWI',
        'Numeric code': 454,
        'Latitude (average)': -13.5,
        'Longitude (average)': 34,
    },
    {
        Country: 'Malaysia',
        country_code_2: 'MY',
        country_code_3: 'MYS',
        'Numeric code': 458,
        'Latitude (average)': 2.5,
        'Longitude (average)': 112.5,
    },
    {
        Country: 'Maldives',
        country_code_2: 'MV',
        country_code_3: 'MDV',
        'Numeric code': 462,
        'Latitude (average)': 3.25,
        'Longitude (average)': 73,
    },
    {
        Country: 'Mali',
        country_code_2: 'ML',
        country_code_3: 'MLI',
        'Numeric code': 466,
        'Latitude (average)': 17,
        'Longitude (average)': -4,
    },
    {
        Country: 'Malta',
        country_code_2: 'MT',
        country_code_3: 'MLT',
        'Numeric code': 470,
        'Latitude (average)': 35.8333,
        'Longitude (average)': 14.5833,
    },
    {
        Country: 'Marshall Islands',
        country_code_2: 'MH',
        country_code_3: 'MHL',
        'Numeric code': 584,
        'Latitude (average)': 9,
        'Longitude (average)': 168,
    },
    {
        Country: 'Martinique',
        country_code_2: 'MQ',
        country_code_3: 'MTQ',
        'Numeric code': 474,
        'Latitude (average)': 14.6667,
        'Longitude (average)': -61,
    },
    {
        Country: 'Mauritania',
        country_code_2: 'MR',
        country_code_3: 'MRT',
        'Numeric code': 478,
        'Latitude (average)': 20,
        'Longitude (average)': -12,
    },
    {
        Country: 'Mauritius',
        country_code_2: 'MU',
        country_code_3: 'MUS',
        'Numeric code': 480,
        'Latitude (average)': -20.2833,
        'Longitude (average)': 57.55,
    },
    {
        Country: 'Mayotte',
        country_code_2: 'YT',
        country_code_3: 'MYT',
        'Numeric code': 175,
        'Latitude (average)': -12.8333,
        'Longitude (average)': 45.1667,
    },
    {
        Country: 'Mexico',
        country_code_2: 'MX',
        country_code_3: 'MEX',
        'Numeric code': 484,
        'Latitude (average)': 23,
        'Longitude (average)': -102,
    },
    {
        Country: 'Micronesia',
        country_code_2: 'FM',
        country_code_3: 'FSM',
        'Numeric code': 583,
        'Latitude (average)': 6.9167,
        'Longitude (average)': 158.25,
    },
    {
        Country: 'Moldova',
        country_code_2: 'MD',
        country_code_3: 'MDA',
        'Numeric code': 498,
        'Latitude (average)': 47,
        'Longitude (average)': 29,
    },
    {
        Country: 'Monaco',
        country_code_2: 'MC',
        country_code_3: 'MCO',
        'Numeric code': 492,
        'Latitude (average)': 43.7333,
        'Longitude (average)': 7.4,
    },
    {
        Country: 'Mongolia',
        country_code_2: 'MN',
        country_code_3: 'MNG',
        'Numeric code': 496,
        'Latitude (average)': 46,
        'Longitude (average)': 105,
    },
    {
        Country: 'Montenegro',
        country_code_2: 'ME',
        country_code_3: 'MNE',
        'Numeric code': 499,
        'Latitude (average)': 42,
        'Longitude (average)': 19,
    },
    {
        Country: 'Montserrat',
        country_code_2: 'MS',
        country_code_3: 'MSR',
        'Numeric code': 500,
        'Latitude (average)': 16.75,
        'Longitude (average)': -62.2,
    },
    {
        Country: 'Morocco',
        country_code_2: 'MA',
        country_code_3: 'MAR',
        'Numeric code': 504,
        'Latitude (average)': 32,
        'Longitude (average)': -5,
    },
    {
        Country: 'Mozambique',
        country_code_2: 'MZ',
        country_code_3: 'MOZ',
        'Numeric code': 508,
        'Latitude (average)': -18.25,
        'Longitude (average)': 35,
    },
    {
        Country: 'Myanmar',
        country_code_2: 'MM',
        country_code_3: 'MMR',
        'Numeric code': 104,
        'Latitude (average)': 22,
        'Longitude (average)': 98,
    },
    {
        Country: 'Burma',
        country_code_2: 'MM',
        country_code_3: 'MMR',
        'Numeric code': 104,
        'Latitude (average)': 22,
        'Longitude (average)': 98,
    },
    {
        Country: 'Namibia',
        country_code_2: 'NA',
        country_code_3: 'NAM',
        'Numeric code': 516,
        'Latitude (average)': -22,
        'Longitude (average)': 17,
    },
    {
        Country: 'Nauru',
        country_code_2: 'NR',
        country_code_3: 'NRU',
        'Numeric code': 520,
        'Latitude (average)': -0.5333,
        'Longitude (average)': 166.9167,
    },
    {
        Country: 'Nepal',
        country_code_2: 'NP',
        country_code_3: 'NPL',
        'Numeric code': 524,
        'Latitude (average)': 28,
        'Longitude (average)': 84,
    },
    {
        Country: 'Netherlands',
        country_code_2: 'NL',
        country_code_3: 'NLD',
        'Numeric code': 528,
        'Latitude (average)': 52.5,
        'Longitude (average)': 5.75,
    },
    {
        Country: 'Netherlands Antilles',
        country_code_2: 'AN',
        country_code_3: 'ANT',
        'Numeric code': 530,
        'Latitude (average)': 12.25,
        'Longitude (average)': -68.75,
    },
    {
        Country: 'New Caledonia',
        country_code_2: 'NC',
        country_code_3: 'NCL',
        'Numeric code': 540,
        'Latitude (average)': -21.5,
        'Longitude (average)': 165.5,
    },
    {
        Country: 'New Zealand',
        country_code_2: 'NZ',
        country_code_3: 'NZL',
        'Numeric code': 554,
        'Latitude (average)': -41,
        'Longitude (average)': 174,
    },
    {
        Country: 'Nicaragua',
        country_code_2: 'NI',
        country_code_3: 'NIC',
        'Numeric code': 558,
        'Latitude (average)': 13,
        'Longitude (average)': -85,
    },
    {
        Country: 'Niger',
        country_code_2: 'NE',
        country_code_3: 'NER',
        'Numeric code': 562,
        'Latitude (average)': 16,
        'Longitude (average)': 8,
    },
    {
        Country: 'Nigeria',
        country_code_2: 'NG',
        country_code_3: 'NGA',
        'Numeric code': 566,
        'Latitude (average)': 10,
        'Longitude (average)': 8,
    },
    {
        Country: 'Niue',
        country_code_2: 'NU',
        country_code_3: 'NIU',
        'Numeric code': 570,
        'Latitude (average)': -19.0333,
        'Longitude (average)': -169.8667,
    },
    {
        Country: 'Norfolk Island',
        country_code_2: 'NF',
        country_code_3: 'NFK',
        'Numeric code': 574,
        'Latitude (average)': -29.0333,
        'Longitude (average)': 167.95,
    },
    {
        Country: 'Northern Mariana Islands',
        country_code_2: 'MP',
        country_code_3: 'MNP',
        'Numeric code': 580,
        'Latitude (average)': 15.2,
        'Longitude (average)': 145.75,
    },
    {
        Country: 'Norway',
        country_code_2: 'NO',
        country_code_3: 'NOR',
        'Numeric code': 578,
        'Latitude (average)': 62,
        'Longitude (average)': 10,
    },
    {
        Country: 'Oman',
        country_code_2: 'OM',
        country_code_3: 'OMN',
        'Numeric code': 512,
        'Latitude (average)': 21,
        'Longitude (average)': 57,
    },
    {
        Country: 'Pakistan',
        country_code_2: 'PK',
        country_code_3: 'PAK',
        'Numeric code': 586,
        'Latitude (average)': 30,
        'Longitude (average)': 70,
    },
    {
        Country: 'Palau',
        country_code_2: 'PW',
        country_code_3: 'PLW',
        'Numeric code': 585,
        'Latitude (average)': 7.5,
        'Longitude (average)': 134.5,
    },
    {
        Country: 'Palestine',
        country_code_2: 'PS',
        country_code_3: 'PSE',
        'Numeric code': 275,
        'Latitude (average)': 32,
        'Longitude (average)': 35.25,
    },
    {
        Country: 'Panama',
        country_code_2: 'PA',
        country_code_3: 'PAN',
        'Numeric code': 591,
        'Latitude (average)': 9,
        'Longitude (average)': -80,
    },
    {
        Country: 'Papua New Guinea',
        country_code_2: 'PG',
        country_code_3: 'PNG',
        'Numeric code': 598,
        'Latitude (average)': -6,
        'Longitude (average)': 147,
    },
    {
        Country: 'Paraguay',
        country_code_2: 'PY',
        country_code_3: 'PRY',
        'Numeric code': 600,
        'Latitude (average)': -23,
        'Longitude (average)': -58,
    },
    {
        Country: 'Peru',
        country_code_2: 'PE',
        country_code_3: 'PER',
        'Numeric code': 604,
        'Latitude (average)': -10,
        'Longitude (average)': -76,
    },
    {
        Country: 'Philippines',
        country_code_2: 'PH',
        country_code_3: 'PHL',
        'Numeric code': 608,
        'Latitude (average)': 13,
        'Longitude (average)': 122,
    },
    {
        Country: 'Pitcairn',
        country_code_2: 'PN',
        country_code_3: 'PCN',
        'Numeric code': 612,
        'Latitude (average)': -24.7,
        'Longitude (average)': -127.4,
    },
    {
        Country: 'Poland',
        country_code_2: 'PL',
        country_code_3: 'POL',
        'Numeric code': 616,
        'Latitude (average)': 52,
        'Longitude (average)': 20,
    },
    {
        Country: 'Portugal',
        country_code_2: 'PT',
        country_code_3: 'PRT',
        'Numeric code': 620,
        'Latitude (average)': 39.5,
        'Longitude (average)': -8,
    },
    {
        Country: 'Puerto Rico',
        country_code_2: 'PR',
        country_code_3: 'PRI',
        'Numeric code': 630,
        'Latitude (average)': 18.25,
        'Longitude (average)': -66.5,
    },
    {
        Country: 'Qatar',
        country_code_2: 'QA',
        country_code_3: 'QAT',
        'Numeric code': 634,
        'Latitude (average)': 25.5,
        'Longitude (average)': 51.25,
    },
    {
        Country: 'Réunion',
        country_code_2: 'RE',
        country_code_3: 'REU',
        'Numeric code': 638,
        'Latitude (average)': -21.1,
        'Longitude (average)': 55.6,
    },
    {
        Country: 'Romania',
        country_code_2: 'RO',
        country_code_3: 'ROU',
        'Numeric code': 642,
        'Latitude (average)': 46,
        'Longitude (average)': 25,
    },
    {
        Country: 'Russia',
        country_code_2: 'RU',
        country_code_3: 'RUS',
        'Numeric code': 643,
        'Latitude (average)': 60,
        'Longitude (average)': 100,
    },
    {
        Country: 'Rwanda',
        country_code_2: 'RW',
        country_code_3: 'RWA',
        'Numeric code': 646,
        'Latitude (average)': -2,
        'Longitude (average)': 30,
    },
    {
        Country: 'Saint Helena, Ascension and Tristan da Cunha',
        country_code_2: 'SH',
        country_code_3: 'SHN',
        'Numeric code': 654,
        'Latitude (average)': -15.9333,
        'Longitude (average)': -5.7,
    },
    {
        Country: 'Saint Kitts and Nevis',
        country_code_2: 'KN',
        country_code_3: 'KNA',
        'Numeric code': 659,
        'Latitude (average)': 17.3333,
        'Longitude (average)': -62.75,
    },
    {
        Country: 'Saint Lucia',
        country_code_2: 'LC',
        country_code_3: 'LCA',
        'Numeric code': 662,
        'Latitude (average)': 13.8833,
        'Longitude (average)': -61.1333,
    },
    {
        Country: 'Saint Pierre and Miquelon',
        country_code_2: 'PM',
        country_code_3: 'SPM',
        'Numeric code': 666,
        'Latitude (average)': 46.8333,
        'Longitude (average)': -56.3333,
    },
    {
        Country: 'Saint Vincent and the Grenadines',
        country_code_2: 'VC',
        country_code_3: 'VCT',
        'Numeric code': 670,
        'Latitude (average)': 13.25,
        'Longitude (average)': -61.2,
    },
    {
        Country: 'Saint Vincent & the Grenadines',
        country_code_2: 'VC',
        country_code_3: 'VCT',
        'Numeric code': 670,
        'Latitude (average)': 13.25,
        'Longitude (average)': -61.2,
    },
    {
        Country: 'St. Vincent and the Grenadines',
        country_code_2: 'VC',
        country_code_3: 'VCT',
        'Numeric code': 670,
        'Latitude (average)': 13.25,
        'Longitude (average)': -61.2,
    },
    {
        Country: 'Samoa',
        country_code_2: 'WS',
        country_code_3: 'WSM',
        'Numeric code': 882,
        'Latitude (average)': -13.5833,
        'Longitude (average)': -172.3333,
    },
    {
        Country: 'San Marino',
        country_code_2: 'SM',
        country_code_3: 'SMR',
        'Numeric code': 674,
        'Latitude (average)': 43.7667,
        'Longitude (average)': 12.4167,
    },
    {
        Country: 'Sao Tome and Principe',
        country_code_2: 'ST',
        country_code_3: 'STP',
        'Numeric code': 678,
        'Latitude (average)': 1,
        'Longitude (average)': 7,
    },
    {
        Country: 'Saudi Arabia',
        country_code_2: 'SA',
        country_code_3: 'SAU',
        'Numeric code': 682,
        'Latitude (average)': 25,
        'Longitude (average)': 45,
    },
    {
        Country: 'Senegal',
        country_code_2: 'SN',
        country_code_3: 'SEN',
        'Numeric code': 686,
        'Latitude (average)': 14,
        'Longitude (average)': -14,
    },
    {
        Country: 'Serbia',
        country_code_2: 'RS',
        country_code_3: 'SRB',
        'Numeric code': 688,
        'Latitude (average)': 44,
        'Longitude (average)': 21,
    },
    {
        Country: 'Seychelles',
        country_code_2: 'SC',
        country_code_3: 'SYC',
        'Numeric code': 690,
        'Latitude (average)': -4.5833,
        'Longitude (average)': 55.6667,
    },
    {
        Country: 'Sierra Leone',
        country_code_2: 'SL',
        country_code_3: 'SLE',
        'Numeric code': 694,
        'Latitude (average)': 8.5,
        'Longitude (average)': -11.5,
    },
    {
        Country: 'Singapore',
        country_code_2: 'SG',
        country_code_3: 'SGP',
        'Numeric code': 702,
        'Latitude (average)': 1.3667,
        'Longitude (average)': 103.8,
    },
    {
        Country: 'Slovakia',
        country_code_2: 'SK',
        country_code_3: 'SVK',
        'Numeric code': 703,
        'Latitude (average)': 48.6667,
        'Longitude (average)': 19.5,
    },
    {
        Country: 'Slovenia',
        country_code_2: 'SI',
        country_code_3: 'SVN',
        'Numeric code': 705,
        'Latitude (average)': 46,
        'Longitude (average)': 15,
    },
    {
        Country: 'Solomon Islands',
        country_code_2: 'SB',
        country_code_3: 'SLB',
        'Numeric code': 90,
        'Latitude (average)': -8,
        'Longitude (average)': 159,
    },
    {
        Country: 'Somalia',
        country_code_2: 'SO',
        country_code_3: 'SOM',
        'Numeric code': 706,
        'Latitude (average)': 10,
        'Longitude (average)': 49,
    },
    {
        Country: 'South Africa',
        country_code_2: 'ZA',
        country_code_3: 'ZAF',
        'Numeric code': 710,
        'Latitude (average)': -29,
        'Longitude (average)': 24,
    },
    {
        Country: 'South Georgia and the South Sandwich Islands',
        country_code_2: 'GS',
        country_code_3: 'SGS',
        'Numeric code': 239,
        'Latitude (average)': -54.5,
        'Longitude (average)': -37,
    },
    {
        Country: 'Spain',
        country_code_2: 'ES',
        country_code_3: 'ESP',
        'Numeric code': 724,
        'Latitude (average)': 40,
        'Longitude (average)': -4,
    },
    {
        Country: 'Sri Lanka',
        country_code_2: 'LK',
        country_code_3: 'LKA',
        'Numeric code': 144,
        'Latitude (average)': 7,
        'Longitude (average)': 81,
    },
    {
        Country: 'Sudan',
        country_code_2: 'SD',
        country_code_3: 'SDN',
        'Numeric code': 736,
        'Latitude (average)': 15,
        'Longitude (average)': 30,
    },
    {
        Country: 'Suriname',
        country_code_2: 'SR',
        country_code_3: 'SUR',
        'Numeric code': 740,
        'Latitude (average)': 4,
        'Longitude (average)': -56,
    },
    {
        Country: 'Svalbard and Jan Mayen',
        country_code_2: 'SJ',
        country_code_3: 'SJM',
        'Numeric code': 744,
        'Latitude (average)': 78,
        'Longitude (average)': 20,
    },
    {
        Country: 'Swaziland',
        country_code_2: 'SZ',
        country_code_3: 'SWZ',
        'Numeric code': 748,
        'Latitude (average)': -26.5,
        'Longitude (average)': 31.5,
    },
    {
        Country: 'Sweden',
        country_code_2: 'SE',
        country_code_3: 'SWE',
        'Numeric code': 752,
        'Latitude (average)': 62,
        'Longitude (average)': 15,
    },
    {
        Country: 'Switzerland',
        country_code_2: 'CH',
        country_code_3: 'CHE',
        'Numeric code': 756,
        'Latitude (average)': 47,
        'Longitude (average)': 8,
    },
    {
        Country: 'Syria',
        country_code_2: 'SY',
        country_code_3: 'SYR',
        'Numeric code': 760,
        'Latitude (average)': 35,
        'Longitude (average)': 38,
    },
    {
        Country: 'Taiwan',
        country_code_2: 'TW',
        country_code_3: 'TWN',
        'Numeric code': 158,
        'Latitude (average)': 23.5,
        'Longitude (average)': 121,
    },
    {
        Country: 'Tajikistan',
        country_code_2: 'TJ',
        country_code_3: 'TJK',
        'Numeric code': 762,
        'Latitude (average)': 39,
        'Longitude (average)': 71,
    },
    {
        Country: 'Tanzania',
        country_code_2: 'TZ',
        country_code_3: 'TZA',
        'Numeric code': 834,
        'Latitude (average)': -6,
        'Longitude (average)': 35,
    },
    {
        Country: 'Thailand',
        country_code_2: 'TH',
        country_code_3: 'THA',
        'Numeric code': 764,
        'Latitude (average)': 15,
        'Longitude (average)': 100,
    },
    {
        Country: 'Timor-Leste',
        country_code_2: 'TL',
        country_code_3: 'TLS',
        'Numeric code': 626,
        'Latitude (average)': -8.55,
        'Longitude (average)': 125.5167,
    },
    {
        Country: 'Togo',
        country_code_2: 'TG',
        country_code_3: 'TGO',
        'Numeric code': 768,
        'Latitude (average)': 8,
        'Longitude (average)': 1.1667,
    },
    {
        Country: 'Tokelau',
        country_code_2: 'TK',
        country_code_3: 'TKL',
        'Numeric code': 772,
        'Latitude (average)': -9,
        'Longitude (average)': -172,
    },
    {
        Country: 'Tonga',
        country_code_2: 'TO',
        country_code_3: 'TON',
        'Numeric code': 776,
        'Latitude (average)': -20,
        'Longitude (average)': -175,
    },
    {
        Country: 'Trinidad and Tobago',
        country_code_2: 'TT',
        country_code_3: 'TTO',
        'Numeric code': 780,
        'Latitude (average)': 11,
        'Longitude (average)': -61,
    },
    {
        Country: 'Tunisia',
        country_code_2: 'TN',
        country_code_3: 'TUN',
        'Numeric code': 788,
        'Latitude (average)': 34,
        'Longitude (average)': 9,
    },
    {
        Country: 'Turkey',
        country_code_2: 'TR',
        country_code_3: 'TUR',
        'Numeric code': 792,
        'Latitude (average)': 39,
        'Longitude (average)': 35,
    },
    {
        Country: 'Turkmenistan',
        country_code_2: 'TM',
        country_code_3: 'TKM',
        'Numeric code': 795,
        'Latitude (average)': 40,
        'Longitude (average)': 60,
    },
    {
        Country: 'Turks and Caicos',
        country_code_2: 'TC',
        country_code_3: 'TCA',
        'Numeric code': 796,
        'Latitude (average)': 21.75,
        'Longitude (average)': -71.5833,
    },
    {
        Country: 'Tuvalu',
        country_code_2: 'TV',
        country_code_3: 'TUV',
        'Numeric code': 798,
        'Latitude (average)': -8,
        'Longitude (average)': 178,
    },
    {
        Country: 'Uganda',
        country_code_2: 'UG',
        country_code_3: 'UGA',
        'Numeric code': 800,
        'Latitude (average)': 1,
        'Longitude (average)': 32,
    },
    {
        Country: 'Ukraine',
        country_code_2: 'UA',
        country_code_3: 'UKR',
        'Numeric code': 804,
        'Latitude (average)': 49,
        'Longitude (average)': 32,
    },
    {
        Country: 'United Arab Emirates',
        country_code_2: 'AE',
        country_code_3: 'ARE',
        'Numeric code': 784,
        'Latitude (average)': 24,
        'Longitude (average)': 54,
    },
    {
        Country: 'United Kingdom',
        country_code_2: 'GB',
        country_code_3: 'GBR',
        'Numeric code': 826,
        'Latitude (average)': 54,
        'Longitude (average)': -2,
    },
    {
        Country: 'United States',
        country_code_2: 'US',
        country_code_3: 'USA',
        'Numeric code': 840,
        'Latitude (average)': 38,
        'Longitude (average)': -97,
    },
    {
        Country: 'United States Minor Outlying Islands',
        country_code_2: 'UM',
        country_code_3: 'UMI',
        'Numeric code': 581,
        'Latitude (average)': 19.2833,
        'Longitude (average)': 166.6,
    },
    {
        Country: 'Uruguay',
        country_code_2: 'UY',
        country_code_3: 'URY',
        'Numeric code': 858,
        'Latitude (average)': -33,
        'Longitude (average)': -56,
    },
    {
        Country: 'Uzbekistan',
        country_code_2: 'UZ',
        country_code_3: 'UZB',
        'Numeric code': 860,
        'Latitude (average)': 41,
        'Longitude (average)': 64,
    },
    {
        Country: 'Vanuatu',
        country_code_2: 'VU',
        country_code_3: 'VUT',
        'Numeric code': 548,
        'Latitude (average)': -16,
        'Longitude (average)': 167,
    },
    {
        Country: 'Venezuela',
        country_code_2: 'VE',
        country_code_3: 'VEN',
        'Numeric code': 862,
        'Latitude (average)': 8,
        'Longitude (average)': -66,
    },
    {
        Country: 'Vietnam',
        country_code_2: 'VN',
        country_code_3: 'VNM',
        'Numeric code': 704,
        'Latitude (average)': 16,
        'Longitude (average)': 106,
    },
    {
        Country: 'Virgin Islands, British',
        country_code_2: 'VG',
        country_code_3: 'VGB',
        'Numeric code': 92,
        'Latitude (average)': 18.5,
        'Longitude (average)': -64.5,
    },
    {
        Country: 'Virgin Islands, U.S.',
        country_code_2: 'VI',
        country_code_3: 'VIR',
        'Numeric code': 850,
        'Latitude (average)': 18.3333,
        'Longitude (average)': -64.8333,
    },
    {
        Country: 'Wallis and Futuna',
        country_code_2: 'WF',
        country_code_3: 'WLF',
        'Numeric code': 876,
        'Latitude (average)': -13.3,
        'Longitude (average)': -176.2,
    },
    {
        Country: 'Western Sahara',
        country_code_2: 'EH',
        country_code_3: 'ESH',
        'Numeric code': 732,
        'Latitude (average)': 24.5,
        'Longitude (average)': -13,
    },
    {
        Country: 'Yemen',
        country_code_2: 'YE',
        country_code_3: 'YEM',
        'Numeric code': 887,
        'Latitude (average)': 15,
        'Longitude (average)': 48,
    },
    {
        Country: 'Zambia',
        country_code_2: 'ZM',
        country_code_3: 'ZMB',
        'Numeric code': 894,
        'Latitude (average)': -15,
        'Longitude (average)': 30,
    },
    {
        Country: 'Zimbabwe',
        country_code_2: 'ZW',
        country_code_3: 'ZWE',
        'Numeric code': 716,
        'Latitude (average)': -20,
        'Longitude (average)': 30,
    },
];

import React, { createContext, useState } from 'react';

export const MapContext = createContext();

function Map({ children }) {
    const [clearCluster, setClearCluster] = useState(true);
    const [loadingObservations, setLoadingObservations] = useState(false);
    const [locationsHoldingArray, setLocationsHoldingArray] = useState(null);
    const [mapHoldingObject, setMapHoldingObject] = useState({ zoom: 3, lat: 30.832654, lng: 5.13437 });
    const [filteredAddresses, setFilteredAddresses] = useState(null);
    const [point, setPoint] = useState(false);
    const [zoom, setZoom] = useState(2);
    const [center, setCenter] = useState({ lat: 30.832654, lng: 5.13437 });
    const [mapViewport, setMapViewport] = useState({
        center: { lat: 0, long: 0 },
        latitudeRange: { min: 0, max: 0 },
        longitudeRange: { min: 0, max: 0 },
        zoom: 2,
    });
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [filteredLocation, setFilteredLocation] = useState(false);
    const [toggleZoom, setToggleZoom] = useState(false);
    const [filteredSearch, setFilteredSearch] = useState(false);
    const [filteredSearchResults, setFilteredSearchResults] = useState(null);
    const [search, setSearch] = useState('');
    const [sortStates, setSortStates] = useState({
        timestamp: true,
        ipAddress: false,
        software: false,
        city: false,
        country: false,
        ISP: false,
        security: false,
        walletRootAddress: false,
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [customChk, setCustomChk] = useState(false);
    const [locationsWithinView, setLocationsWithinView] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [activeProviderTab, setActiveProviderTab] = useState('chainalysis');
    const [loadingVpn, setLoadingVpn] = useState(false);
    const [vpnError, setVpnError] = useState(false);

    return (
        <MapContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                clearCluster,
                setClearCluster,
                loadingObservations,
                setLoadingObservations,
                locationsHoldingArray,
                setLocationsHoldingArray,
                mapHoldingObject,
                setMapHoldingObject,
                filteredAddresses,
                setFilteredAddresses,
                point,
                setPoint,
                zoom,
                setZoom,
                center,
                setCenter,
                lat,
                setLat,
                lng,
                setLng,
                filteredLocation,
                setFilteredLocation,
                toggleZoom,
                setToggleZoom,
                filteredSearch,
                setFilteredSearch,
                search,
                setSearch,
                sortStates,
                setSortStates,
                selectedItems,
                setSelectedItems,
                customChk,
                setCustomChk,
                mapViewport,
                setMapViewport,
                locationsWithinView,
                setLocationsWithinView,
                filteredSearchResults,
                setFilteredSearchResults,
                showReset,
                setShowReset,
                activeProviderTab,
                setActiveProviderTab,
                loadingVpn,
                setLoadingVpn,
                vpnError,
                setVpnError,
            }}
        >
            {children}
        </MapContext.Provider>
    );
}

export default Map;

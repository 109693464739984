import React from 'react';
import { Container, Card, Row, Col, CardBody } from 'reactstrap';
import StrikesInfoPlaceholder from './StrikesInfoPlaceholder';
import StrikesTablePlaceholder from './StrikesTablePlaceholder';

function StrikesPlaceholder() {
    const strikesTablePlaceholderStyle = { minHeight: window.innerHeight >= 964 ? '700px' : '500px' };

    return (
        <div className="page-content position-relative z-3 min-vh-100">
            <Container fluid className="mh-100">
                <Card className="border shadow-sm z-1">
                    <Row className="w-100 mx-0">
                        <StrikesInfoPlaceholder />
                    </Row>
                </Card>
                <Card className="shadow-sm border z-1" id="main-card" style={strikesTablePlaceholderStyle}>
                    <CardBody>
                        <Row>
                            <Col lg={12}>
                                <StrikesTablePlaceholder />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default StrikesPlaceholder;

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActiveContext } from '../../../../../Context/ActiveContext';
import { getSelectedCase, getSelectedGraph } from '../../../../../Store/ArtemisCases/selectors';
import { renderTooltip } from '../../../../../Utils/Helpers/helpers';

function BackToCaseDetailsButton() {
    const selectedCase = useSelector(getSelectedCase);
    const selectedGraph = useSelector(getSelectedGraph);
    const navigate = useNavigate();
    const { setActive } = useContext(ActiveContext);

    const { name: caseName = '' } = selectedCase || {};
    const { name: graphName = '' } = selectedGraph || {};
    const label = (caseName || graphName) && `${caseName} / ${graphName}`;
    const toolTipContent = `Return to ${caseName} case details`;

    const activateArtemisCase = () => {
        setActive({
            artemis: false,
            artemisCase: true,
            artemisGraph: false,
            arcane: false,
            strikes: false,
            admin: false,
            home: false,
        });
        navigate('/artemis/case');
    };
    return (
        <>
            {renderTooltip('backToCaseDetailsTooltip', 'absolute')}
            <button
                type="button"
                className="font-size-20 btn pl-2 pr-2 header-item align-center"
                onClick={activateArtemisCase}
                aria-label="Back To Case Details"
                style={{ alignSelf: 'center' }}
                data-tooltip-place="bottom"
                data-tooltip-id="backToCaseDetailsTooltip"
                data-tooltip-content={toolTipContent}
            >
                {label}
            </button>
        </>
    );
}

export default BackToCaseDetailsButton;

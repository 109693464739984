import Axios from 'axios';

/* eslint-disable no-await-in-loop */
const CaseService = {
    // New to support Case Management and
    // enhanced graph sharing
    getUsersCases: async () => {
        let cases = [];
        let queryError;
        let msg;
        try {
            const casesResp = await fetch('/api/cases');
            if (casesResp && casesResp.status === 200) {
                const casesData = await casesResp.json();
                ({ cases = [], error: queryError, msg } = casesData);
            } else {
                console.error(
                    `CaseManagement cases query returned bad response: ${casesResp ? casesResp.status : 'empty'}`,
                );
            }
        } catch (error) {
            console.error(`CaseManagement cases query caught error: ${error}`);
        }

        if (queryError) {
            console.error(`CaseManagement cases query response had error, msg: ${msg}`);
            return cases;
        }

        for (let i = 0; i < cases.length; i++) {
            const { _id } = cases[i];
            try {
                // Get case details and the case's graph info, but not the full list
                // of nodes and edges:
                const caseDetailsResp = await fetch(`/api/cases/${_id}`);
                const caseDetailData = await caseDetailsResp.json();
                const { case: theCase = {}, error: caseQueryError, msg: caseErrorMsg } = caseDetailData;
                const { graphs = [] } = theCase;
                if (caseQueryError) {
                    console.error(`CaseManagement cases/${_id} case query error: ${caseErrorMsg}`);
                }
                cases[i].graphs = graphs;
            } catch (error) {
                console.error(`CaseManagement cases/${_id} query caught error: ${error}`);
                // console.dir(error);
            }
        }
        return cases;
    },
    getGraph: async (caseId, graphId) => {
        let graph;
        let isError;
        let msg;
        try {
            const resp = await fetch(`/api/cases/${caseId}/graphs/${graphId}`);
            const data = await resp.json();
            const { status } = resp;
            ({ graph, error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement graph query returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement graph query caught error: ${msg}`);
            isError = true;
        }
        return { graph, msg, isError };
    },
    createGraph: async (caseId, graph) => {
        let theGraph;
        let isError;
        let msg;
        try {
            const resp = await Axios.post(`/api/cases/${caseId}/graphs`, { graph });
            const { status, data } = resp;
            ({ graph: theGraph, error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement createGraph query returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement createGraph query caught error: ${msg}`);
            isError = true;
        }

        return { graph: theGraph, msg, isError };
    },
    updateGraph: async (caseId, graph) => {
        let theGraph;
        let isError;
        let msg;
        try {
            const resp = await Axios.put(`/api/cases/${caseId}/graphs/${graph._id}`, { graph });
            const { status, data } = resp;
            ({ graph: theGraph, error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement updateGraph query returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement updateGraph query caught error: ${msg}`);
            isError = true;
        }

        return { graph: theGraph, msg, isError };
    },
    deleteGraph: async (caseId, graphId) => {
        let isError;
        let msg;
        try {
            const resp = await Axios.delete(`/api/cases/${caseId}/graphs/${graphId}`);
            const { status, data } = resp;
            ({ error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement deleteGraph query returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement deleteGraph query caught error: ${msg}`);
            isError = true;
        }

        return { msg, isError };
    },
    createCase: async (aCase) => {
        let theCase;
        let isError;
        let msg;
        try {
            const resp = await Axios.post(`/api/cases`, { case: aCase });
            const { status, data } = resp;
            ({ case: theCase, error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement createCase query returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement createCase query caught error: ${msg}`);
            isError = true;
        }

        return { case: theCase, msg, isError };
    },
    updateCase: async (aCase) => {
        let theCase;
        let isError;
        let msg;

        const caseId = aCase._id;

        try {
            const resp = await Axios.put(`/api/cases/${caseId}`, { case: aCase });

            const { status, data } = resp;
            ({ case: theCase, error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement updateCase query returned bad response: ${status}: ${msg}`);
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement updateCase query caught error: ${msg}`);
            isError = true;
        }

        return { case: theCase, msg, isError };
    },

    copyGraph: async (fromCaseId, toCaseId, graphId) => {
        let graph;
        let isError;
        let msg;
        try {
            const resp = await Axios.post(`/api/cases/${fromCaseId}/graphs/${graphId}/copy`, { toCaseId });
            const { status, data } = resp;
            ({ graph, error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement copyGraph operation returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement copyGraph operation caught error: ${msg}`);
            isError = true;
        }

        return { graph, msg, isError };
    },

    // Previous:
    // Fetches All Saved Cases From DB
    fetchCases: async () => {
        try {
            const res = await fetch(`/api/graphs/saved`);
            if (res && res.status === 200) {
                // Parse the response body as JSON
                const data = await res.json();

                // Remove duplicates from most recent
                const seen = new Set();
                const reduced_cases = data.response.filter((el) => {
                    const duplicate = seen.has(el.case);
                    seen.add(el.case);
                    return !duplicate;
                });

                const data_object = {
                    all_cases: data.total,
                    reduced_cases,
                };

                return {
                    data: data_object,
                    status: 200,
                };
            }
            return {
                message: 'Failed to load cases',
                status: res?.status,
            };
        } catch (error) {
            return {
                message: error.message,
                status: 500,
            };
        }
    },

    // Fetches Selected Saved Case From DB
    fetchOneCase: async (singleCase) => {
        try {
            const response = await fetch(`/api/graphs/single-saved?id=${singleCase}`);

            if (response && response.status === 200) {
                // Parse the response body as JSON
                const data = await response.json();

                // Return the data to the component after processing
                return { status: response.status, data };
            }
            return {
                message: 'Failed to load case',
                status: response?.status,
            };
        } catch (error) {
            return {
                message: error.message,
                status: 500,
            };
        }
    },
    deleteCase: async (caseId) => {
        let isError;
        let msg;

        try {
            const resp = await Axios.delete(`/api/cases/${caseId}`);
            const { status, data } = resp;
            ({ error: isError, msg } = data);
            if (status !== 200) {
                console.error(`CaseManagement deleteCase query returned bad response: ${status}: ${msg}`);
                isError = true;
            }
        } catch (error) {
            msg = error.toString();
            console.error(`CaseManagement deleteCase query caught error: ${msg}`);
            isError = true;
        }

        return { msg, isError };
    },

    // Runs when clicking the next button on the modal
    onNext: async (saved) => {
        try {
            const res = await fetch(`/api/cases/case-check?name=${saved.case}`);
            if (res && res.status === 200) {
                const data = await res.json();
                return {
                    data,
                    status: 200,
                };
            }
            return {
                message: 'Failed to load next case',
                status: res?.status,
            };
        } catch (error) {
            return {
                message: error.message,
                status: 500,
            };
        }
    },
};

export default CaseService;

import React, { createContext, useState } from 'react';

export const FusionContext = createContext();

function Fusion({ children }) {
    const [attributions, setAttributions] = useState(null);
    const [assets, setAssets] = useState(null);

    return (
        <FusionContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                attributions,
                assets,
                setAttributions,
                setAssets,
            }}
        >
            {children}
        </FusionContext.Provider>
    );
}

export default Fusion;

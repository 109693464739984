import Axios from 'axios';
import { noProviderDataTitle, nodeAttributionTitle, nodeAddressTitle, transactionTitle } from './titles';
import { getSingleAttributions } from '../Fusion/attributions';
// import CaseService from '../../Services/CaseService';
import { runLocationsCheck } from '../Fusion/observations';
import { deepClone } from '../Helpers/helpers';
import { getAgencyInsightsIcon } from '../AgencyInsights/agencyInsights';

export const add_node_to_graph = async (
    address,
    search,
    graphType,
    searchNodeColor,
    searchNodeBorderColor,
    text,
    saved,
    setSaved,
    set_search,
    setArtemisSearch,
    txDetails,
    transactionNodeColor,
    transactionNodeBorderColor,
    network,
    attributionCache,
    setAttributionCache,
    apiKey,
    setLoadingAtt,
    attribution_color_scheme,
    highlightedNode,
    setPhysicsEnabled,
    subscriptionsCache,
    setObservationCache,
    observationCache,
    setRateCache,
    hasAgencyInsights,
    userDatasetAccess,
    agencyInsightsTabCache,
    setAgencyInsightsTabCache,
) => {
    if (address && search && network) {
        setLoadingAtt(false);
        const floorX = 0;
        const floorY = Math.floor(0 - window.innerHeight / 2 / 30) * 30;

        let node;
        const fetchAttribution = async () => {
            setLoadingAtt(true);

            const check_address =
                attributionCache !== undefined && attributionCache.find((item) => item.address === address);

            const attribution = !address.startsWith('bt-')
                ? !check_address
                    ? await getSingleAttributions(apiKey, address)
                    : check_address.attribution
                : null;

            const label = attribution && attribution.find((l) => l.name !== 'Unknown' && l.status !== 'Error');

            const matchingColorScheme =
                label &&
                label.type &&
                attribution_color_scheme.find((colorScheme) => colorScheme.type === label.type.toLowerCase());

            const attributionColor = attribution
                ? matchingColorScheme
                    ? matchingColorScheme.color
                    : '#8E91A3'
                : '#8E91A3';

            const isTxDetailMatch = address === (txDetails && txDetails.tx_id);
            const backgroundColor = isTxDetailMatch ? transactionNodeColor : searchNodeColor;

            const borderColor =
                attributionColor || (isTxDetailMatch ? transactionNodeBorderColor : searchNodeBorderColor);

            node = [
                {
                    id: address,
                    title: apiKey
                        ? attribution && attribution.name !== null
                            ? nodeAttributionTitle(attribution, attribution_color_scheme)
                            : noProviderDataTitle()
                        : nodeAddressTitle(address),
                    font: { multi: true },
                    label: `${address && `${address.substring(0, 20)}...`}\n${
                        label !== undefined && label ? label.name : ''
                    }`,
                    address,
                    type: 'address',
                    isSearched: true,
                    color: {
                        background: backgroundColor,
                        border: borderColor,
                        highlight: {
                            border: borderColor,
                            background: backgroundColor,
                        },
                        hover: {
                            border: borderColor,
                        },
                    },
                    size: 28,
                    x: floorX,
                    y: floorY,
                    physics: true,
                    attribution: !!attribution,
                    attributionData: attribution,
                },
            ];

            if (!check_address) {
                setAttributionCache((cache) => [
                    ...cache,
                    {
                        address,
                        attribution,
                    },
                ]);
            }
        };

        await fetchAttribution();

        setSaved({
            id: saved.id && saved.id.length >= 1 ? saved.id : '',
            case: saved.case && saved.case.length >= 1 ? saved.case : '',
            graph: saved.graph && saved.graph.length >= 1 ? saved.graph : '',
            address,
            nodes: [...saved.nodes, ...node],
            edges: [...saved.edges, ...[]],
            sharedWith: saved.sharedWith,
            savedBy: saved.savedBy,
            graphType,
        });

        runLocationsCheck(
            subscriptionsCache,
            address,
            apiKey,
            setObservationCache,
            observationCache,
            node,
            setSaved,
            setRateCache,
        );

        getAgencyInsightsIcon(
            node,
            hasAgencyInsights,
            setSaved,
            address,
            userDatasetAccess,
            agencyInsightsTabCache,
            setAgencyInsightsTabCache,
        );

        setSaved({
            id: saved.id && saved.id.length >= 1 ? saved.id : '',
            case: saved.case && saved.case.length >= 1 ? saved.case : '',
            graph: saved.graph && saved.graph.length >= 1 ? saved.graph : '',
            address,
            nodes: [...saved.nodes, ...node],
            edges: [...saved.edges, ...[]],
            sharedWith: saved.sharedWith,
            savedBy: saved.savedBy,
            graphType,
        });

        if (node) {
            setLoadingAtt(false);
        }

        setTimeout(() => {
            setArtemisSearch(false);
            set_search('');
            network.redraw();
            network.fit();
            network.moveTo({
                offset: {
                    x: floorX,
                    y: floorY,
                },
            });
        }, 100);

        if (node) {
            setPhysicsEnabled(true);
        }
    }
};

// Update SINGLE node properties
export const updateSingleNodeXY = async (nodes, id, network, autoSave, saved, isSaved, setIsSaving) => {
    const X_Y = network.getPosition(id);
    const node = nodes.find((n) => n.id === id);

    if (node) {
        node.x = X_Y.x;
        node.y = X_Y.y;

        // Update local storage
        const storedSavedData = JSON.parse(localStorage.getItem('artemisSavedStorage'));
        const nodeInLocalStorage = storedSavedData.nodes.find((n) => n.id === id);
        if (nodeInLocalStorage) {
            nodeInLocalStorage.x = X_Y.x;
            nodeInLocalStorage.y = X_Y.y;
            localStorage.setItem('artemisSavedStorage', JSON.stringify(storedSavedData));
        }

        if (autoSave) {
            setIsSaving(true);
            if (saved.case.length >= 1 && saved.graph.length >= 1 && isSaved) {
                const data = {
                    node_id: node.id,
                    x: X_Y.x,
                    y: X_Y.y,
                    id: saved.id,
                };
                try {
                    const res = await Axios.post('/api/graphs/node-update', { data });
                    if (res.status === 200) {
                        setTimeout(() => setIsSaving(false), 200);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }
};

// Updates Node Attributions When API Key is added in settings
export const updateNodeAttributions = (
    nodes,
    attribution_color_scheme,
    attributionCache,
    setSaved,
    searchNodeColor,
) => {
    const updatedNodes =
        nodes &&
        nodes.map((n) => {
            if (n.type === 'address') {
                const att = attributionCache.find((a) => a.address === n.id);
                if (att) {
                    const label = att.attribution?.find((l) => l.name !== 'Unknown' && l.status !== 'Error');
                    const matchingColorScheme =
                        label &&
                        label.type &&
                        attribution_color_scheme.find((colorScheme) => colorScheme.type === label.type.toLowerCase());
                    const attributionColor = matchingColorScheme ? matchingColorScheme.color : '#8E91A3';
                    const backgroundColor = searchNodeColor;

                    return {
                        ...n,
                        color: {
                            background: backgroundColor,
                            border: attributionColor,
                            highlight: {
                                border: attributionColor,
                                background: backgroundColor,
                            },
                            hover: {
                                border: attributionColor,
                            },
                        },
                        label: `${`${n.id.substring(0, 20)}...`}\n${label !== undefined ? label.name : ''}`,
                        font: {
                            multi: true,
                        },
                        title: n.id.includes('note-')
                            ? undefined
                            : n.type === 'address'
                              ? att && att.attribution
                                  ? nodeAttributionTitle(att.attribution, attribution_color_scheme)
                                  : noProviderDataTitle()
                              : n.type === 'transaction'
                                ? transactionTitle(
                                      n.id,
                                      n.timestamp,
                                      n.total_btc_input,
                                      n.total_btc_output,
                                      n.total_usd_input,
                                      n.total_usd_output,
                                  )
                                : nodeAddressTitle(n.id),
                        attribution: !!att?.attribution,
                        attributionData: att && att.attribution,
                    };
                }
            } else {
                return {
                    ...n,
                };
            }

            return n;
        });

    setSaved((prevSaved) => ({
        ...prevSaved,
        nodes: updatedNodes,
    }));
};

export const deleteNode = (
    saved,
    setSaved,
    selectedNodeId,
    set_address,
    setSelectedNodeId,
    hideMenu,
    setSideDrawerIsOpen,
    active_address,
    set_active_address,
    active_transaction,
    set_active_transaction,
    setIsRightClick,
) => {
    if (!selectedNodeId.includes('note-')) {
        setIsRightClick(true);
    }
    const tooltipElement = document.querySelector('.vis-tooltip');
    if (tooltipElement) {
        tooltipElement.style.display = 'none';
    }

    const filteredNotes = saved.nodes.filter((node) => selectedNodeId === node.node_id);

    saved.nodes = saved.nodes.filter((node) => node.id !== selectedNodeId && !filteredNotes.includes(node));

    saved.edges = saved.edges.filter((edge) => edge.from !== selectedNodeId && edge.to !== selectedNodeId);

    if (!selectedNodeId.includes('note-')) {
        if (active_address === selectedNodeId) {
            setSideDrawerIsOpen(false);
            set_active_address('');
        } else if (active_transaction === selectedNodeId) {
            setSideDrawerIsOpen(false);
            set_active_transaction('');
        }

        set_address('');
        setSelectedNodeId();
        setIsRightClick(false);
    }

    setSaved({
        ...saved,
        nodes: saved.nodes,
        edges: saved.edges,
    });

    hideMenu();
};

// Saves Graph to backend
export const updateNodeAndEdges = async (saved, setIsSaving /* , setReducedCases, setAllCases */) => {
    // Perform a deep copy of the saved object
    const new_saved = deepClone(saved);

    // Preserves HTML Styling For Title When Saving
    new_saved.nodes.forEach((el) => {
        if (el.title && el.title.outerHTML) {
            el.title = el.title.outerHTML.toString();
        } else if (el.title && !el.title.outerEmail) {
            el.title = el.title.toString();
        } else {
            el.title = `<div>Click for address details</div>`;
        }
    });

    // Preserves HTML Styling For Title When Saving
    new_saved.edges.forEach((el) => {
        if (el.title && el.title.outerHTML) {
            el.title = el.title.outerHTML.toString();
        } else if (el.title && !el.title.outerEmail) {
            el.title = el.title.toString();
        }
    });

    try {
        setIsSaving(true);
        const res = await Axios.post('/api/graphs/update-all', { data: new_saved });
        if (res.status === 200) {
            setTimeout(() => setIsSaving(false), 200);
            // TODO: remove this
            /*
            const resp = await CaseService.fetchCases();
            if (resp && resp.data) {
                setReducedCases(resp.data.reduced_cases);
                setAllCases(resp.data.all_cases);
            }
            */
        }
    } catch (error) {
        setIsSaving(false);
    }
};

/* eslint-disable no-unused-vars */
import Axios from 'axios';
import { capitalize } from '../Helpers/helpers';
import { countries } from '../Common/Countries/index';
import ip from '../../Assets/Images/ip.webp';

export const getObservations = async (
    observationCache,
    setObservationCache,
    selectedNodeId,
    setLocations,
    provider,
    apiKey,
    setLoadingObservations,
    filteredLocation,
    isFiltered,
    setLocationsHoldingArray,
    saved,
    network,
    setSaved,
    setShowReset,
    activeProviderTab,
    setRateCache,
    rateCache,
) => {
    try {
        const providerMapping = {
            ca: 'Chainalysis',
        };
        const rateLimitReached = rateCache.find(
            (cache) =>
                cache.address === selectedNodeId &&
                cache.res.message ===
                    'The number of calls being requested exceeds the maximum daily call limit for this API key.' &&
                cache.res.source.toLowerCase() === providerMapping[provider].toLowerCase(),
        );

        setShowReset(false);
        if (!filteredLocation || !isFiltered) {
            const check_observations_cache = observationCache.find(
                (obs) => obs.address === selectedNodeId && obs.provider === provider,
            );

            if (!check_observations_cache && selectedNodeId && !selectedNodeId.startsWith('bt-') && !rateLimitReached) {
                setLoadingObservations(true);
                const res = await Axios.post(
                    `/api/fusion/observations?address=${selectedNodeId}&provider=${provider}`,
                    {
                        apiKey,
                    },
                );

                if (res && res.data.locations && res.status === 200) {
                    // Add country code here to all locations
                    res.data.locations.map((loc, idx) => {
                        const code = countries.find((c) => c.Country === capitalize(loc.country));

                        if (code) {
                            loc.country_code = code.country_code_2;
                        }
                        loc.id = idx + 1;
                        return code;
                    });

                    res.data.locations.sort((a, b) => {
                        const timestampA = new Date(a.timestamp);
                        const timestampB = new Date(b.timestamp);
                        return timestampB - timestampA;
                    });

                    setObservationCache((cache) => [
                        ...cache,
                        {
                            address: selectedNodeId,
                            locations: res.data.locations,
                            provider,
                        },
                    ]);

                    if (res.data.locations && res.data.locations.length >= 1) {
                        if (activeProviderTab === 'chainalysis' && provider === 'ca') {
                            setLocations(res.data.locations);
                            setLocationsHoldingArray(res.data.locations);
                        }

                        const updatedNodes = saved.nodes.map((node) => {
                            if (node.id === selectedNodeId) {
                                return {
                                    ...node,
                                    locations: true,
                                };
                            }
                            return node;
                        });

                        setSaved({
                            ...saved,
                            nodes: updatedNodes,
                        });
                    }
                } else if (
                    res?.data?.message ===
                    'The number of calls being requested exceeds the maximum daily call limit for this API key.'
                ) {
                    setLocations([]);
                    setRateCache((cache) => [
                        ...cache,
                        {
                            address: selectedNodeId,
                            res: res.data,
                        },
                    ]);
                }
            }

            if (check_observations_cache) {
                setLocations(check_observations_cache.locations);
                setLocationsHoldingArray(check_observations_cache.locations);
                if (check_observations_cache.locations) {
                    setLoadingObservations(false);
                }
            }
        }

        if (!filteredLocation) {
            setTimeout(() => {
                setShowReset(true);
            }, 2000);
        }
    } catch (error) {
        console.error(error);
    }
};

export const getLocationsIcon = async (
    selectedNodeId,
    provider,
    apiKey,
    setObservationCache,
    observationCache,
    setRateCache,
) => {
    const check_observations_cache = observationCache.find(
        (obs) => obs.address === selectedNodeId && obs.provider === provider,
    );

    if (!check_observations_cache && selectedNodeId && !selectedNodeId.startsWith('bt-')) {
        try {
            const res = await Axios.post(`/api/fusion/observations?address=${selectedNodeId}&provider=${provider}`, {
                apiKey,
            });

            if (res && res.data.locations && res.status === 200) {
                // Add country code here to all locations
                res.data.locations.map((loc, idx) => {
                    const code = countries.find((c) => c.Country === capitalize(loc.country));

                    if (code) {
                        loc.country_code = code.country_code_2;
                    }
                    loc.id = idx + 1;
                    return code;
                });

                res.data.locations.sort((a, b) => {
                    const timestampA = new Date(a.timestamp);
                    const timestampB = new Date(b.timestamp);
                    return timestampB - timestampA;
                });

                setObservationCache((cache) => [
                    ...cache,
                    {
                        address: selectedNodeId,
                        locations: res.data.locations,
                        provider,
                    },
                ]);

                if (res.data.locations.length >= 1) {
                    return true;
                }
            } else if (
                res?.data?.message ===
                'The number of calls being requested exceeds the maximum daily call limit for this API key.'
            ) {
                setRateCache((cache) => [
                    ...cache,
                    {
                        address: selectedNodeId,
                        res: res.data,
                    },
                ]);
            }
        } catch (error) {
            console.error({
                error,
                message: 'Error fetching locations for the selected provider',
            });
        }
    }
    return false;
};

export const getBulkLocationsIcon = async (
    selectedNodeIds,
    provider,
    apiKey,
    setObservationCache,
    observationCache,
) => {
    const nodesToFetch = selectedNodeIds.filter(
        (nodeId) =>
            !observationCache.some((obs) => obs.address === nodeId && obs.provider === provider) &&
            !nodeId.startsWith('bt-'),
    );

    const fetchedAddresses = [];

    if (nodesToFetch.length > 0) {
        try {
            const res = await Axios.post(`/api/fusion/bulk/observations?provider=${provider}`, {
                addresses: nodesToFetch,
                apiKey,
            });

            if (res && res.data && res.status === 200) {
                res.data.forEach((dataObject) => {
                    if (dataObject.locations) {
                        dataObject.locations.map((loc, idx) => {
                            const code = countries.find((c) => c.Country === capitalize(loc.country));

                            if (code) {
                                loc.country_code = code.country_code_2;
                            }

                            loc.id = idx + 1;
                            return loc;
                        });

                        dataObject.locations.sort((a, b) => {
                            const timestampA = new Date(a.timestamp);
                            const timestampB = new Date(b.timestamp);
                            return timestampB - timestampA;
                        });

                        if (dataObject.locations.length >= 1) {
                            fetchedAddresses.push(dataObject.address);
                        }
                    }
                });
            }
        } catch (error) {
            console.error({
                error,
                message: 'Error fetching bulk locations for the selected provider',
            });
        }
    }

    return fetchedAddresses;
};

export const addressInfo = async (
    address,
    getAddressInfo,
    setAddressData,
    setLoadingAddressData,
    setTxsOverTimeData,
    // getTxsOverTime,
    infoCache,
    setInfoCache,
    navigate,
    // getTxsOverTimeDaily,
    setTxsOverTimeDailyData,
) => {
    const cache = infoCache.find((c) => c.address === address);

    if (cache) {
        setAddressData(cache.addressData);
        setTxsOverTimeData(cache.txsOverTimeData);
        setTxsOverTimeDailyData(cache.txsOverTimeDailyData);
    } else {
        setLoadingAddressData(true);
        try {
            const [addrInfo /* txsOverTimeInfo, txsOverTimeDailyInfo */] = await Promise.all([
                getAddressInfo({
                    variables: {
                        address,
                    },
                }),
                // getTxsOverTime({
                //     variables: {
                //         address,
                //     },
                // }),
                // getTxsOverTimeDaily({
                //     variables: {
                //         address,
                //     },
                // }),
            ]);

            // TODO: add a message for when addrInfo or txsOverTime have an error
            // if (
            //     addrInfo?.errors?.message === 'ServiceUnavailable' ||
            //     txsOverTimeInfo?.errors?.message === 'ServiceUnavailable'
            // ) {
            //     return;
            // }

            setInfoCache([
                ...infoCache,
                {
                    address,
                    addressData: addrInfo.data.get_address_info[0],
                    // txsOverTimeData: txsOverTimeInfo.data.get_txs_over_time,
                    // txsOverTimeDailyData: txsOverTimeDailyInfo.data.get_txs_over_time_daily,
                },
            ]);
            // setTxsOverTimeData(txsOverTimeInfo.data.get_txs_over_time);
            // setTxsOverTimeDailyData(txsOverTimeInfo.data.get_txs_over_time_daily);
            setAddressData(addrInfo.data.get_address_info[0]);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingAddressData(false);
        }
    }
};

export const displayIpIcon = (node, network) => {
    const icon = new Image();

    icon.onload = () => {
        if (network) {
            // TODO: Do we need to ever remove the 'afterDrawing' listeners that are attached?
            // Would not removing a listener contribute to a memory leak in a long running UI?
            network.on('afterDrawing', (ctx) => {
                const nodeId = node.id;
                const nodeExists = network.body.nodes[nodeId] !== undefined;

                if (network.body.data.nodes.length >= 1 && nodeExists) {
                    try {
                        const nodePosition = network.getPosition(nodeId);
                        if (nodePosition) {
                            const iconX = nodePosition.x + 28 / 2 + 6; // Adjust the offset as needed
                            const iconY = nodePosition.y - 28 / 2 - 20; // Adjust the offset as needed
                            const iconSize = 18; // Adjust the icon size as needed
                            ctx.save(); // Save the current context state
                            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                            ctx.shadowOffsetX = 2;
                            ctx.shadowOffsetY = 2;
                            ctx.shadowBlur = 4;
                            ctx.drawImage(icon, iconX, iconY, iconSize, iconSize);
                            ctx.restore(); // Restore the previous context state
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            });
            network.redraw();
        }
    };

    icon.src = ip;
};

export const runLocationsCheck = async (
    subscriptionsCache,
    address,
    apiKey,
    setObservationCache,
    observationCache,
    nodes,
    setSaved,
    setRateCache,
) => {
    let chainalysisLocationsAvailable;
    const providers = subscriptionsCache?.providers || [];

    if (providers.some((provider) => ['Chainalysis'].includes(provider.provider))) {
        chainalysisLocationsAvailable = await getLocationsIcon(
            address,
            'ca',
            apiKey,
            setObservationCache,
            observationCache,
            setRateCache,
        );
    }

    // Add cluser ID here
    nodes.map((n) => {
        if (n.type === 'address') {
            n.locations = !!chainalysisLocationsAvailable;
        }
        return n;
    });

    setSaved((saved) => ({ ...saved }));
};

export const runBulkLocationsCheck = async (
    tx,
    subscriptionsCache,
    apiKey,
    setObservationCache,
    observationCache,
    nodes,
    setSaved,
) => {
    let allAddresses = [...tx.from_addresses, ...tx.to_addresses].map((obj) => obj.address);
    allAddresses = [...new Set(allAddresses)];

    // Find All Addresses With Missing Cluster IDS in Observations Cache
    const addresses = allAddresses.filter((str) => !observationCache.some((obj) => obj.address === str));

    const providers = subscriptionsCache?.providers || [];
    let chainalysisLocationsAvailable = [];
    if (providers.some((provider) => ['Chainalysis'].includes(provider.provider))) {
        chainalysisLocationsAvailable = await getBulkLocationsIcon(
            addresses,
            'ca',
            apiKey,
            setObservationCache,
            observationCache,
        );
    }

    await nodes.map((n) => {
        if (n.type === 'address') {
            const isInChainalysis = chainalysisLocationsAvailable
                ? chainalysisLocationsAvailable.includes(n.address)
                : false;

            const locationAvailableInCache = isInChainalysis;

            if (locationAvailableInCache) {
                n.locations = true;
            }
        }

        return n;
    });

    setSaved((saved) => ({ ...saved }));
};

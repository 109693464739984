import React, { createContext, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
// import { htmlTitle } from '../Utils/Vis/titles';
import {
    GET_INBOUND_AND_OUTBOUND_TX_IDS,
    GET_AUTOCOMPLETE_ADDRESS,
    GET_ADDRESS_INFO,
    GET_AGENCY_INSIGHTS_RESULTS,
    GET_AGENCY_INSIGHTS_ACCOUNT_DETAILS,
    GET_INBOUND_TX_IDS,
    GET_OUTBOUND_TX_IDS,
    GET_TX_ID_DETAILS,
    GET_TXS_OVER_TIME,
    GET_TXS_OVER_TIME_DAILY,
    HAS_AGENCY_INSIGHTS,
} from '../GraphQL/queries';
import { drawGrid, setCanvasZoom } from '../Utils/Vis/grid';
import { snapPositions } from '../Utils/Vis/events';
import { gridSize } from '../Themes/styles';
import { epochUTC, getTxListPropsForNextPage } from '../Utils/Helpers/helpers';

export const GraphContext = createContext();

function Context({ children }) {
    const [showAgencyInsightsData, setShowAgencyInsightsData] = useState(true);
    const [agencyInsightsLinkClicked, setAgencyInsightsLinkClicked] = useState(false);
    const [backLinkClicked, setBackLinkClicked] = useState(false);
    const [inputIsWrapped, setInputIsWrapped] = useState(false);
    const [outputIsWrapped, setOutputIsWrapped] = useState(false);
    const [saveGraphButton, setSaveGraphButton] = useState(true);
    const [sideDrawerIsOpen, setSideDrawerIsOpen] = useState(false);
    const [physicsEnabled, setPhysicsEnabled] = useState(false);
    const [isRightClick, setIsRightClick] = useState(false);
    const [highlightedNode, setHighlightedNode] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [network, setNetwork] = useState(null);
    const [active_address, set_active_address] = useState();
    const [active_transaction, set_active_transaction] = useState();
    const [loading, setLoading] = useState(true);
    const [copy_address, set_copy_address] = useState('');
    const [copyAgencyAddress, setCopyAgencyAddress] = useState('');
    const [open, setOpen] = useState(false);
    const [direction, setDirection] = useState('ASC');
    const [skip, setSkip] = useState({
        all: 0,
        inbound: 0,
        outbound: 0,
    });
    const [txIdListPagingProps, setTxIdListPagingProps] = useState({
        prevMin: undefined,
        prevMax: undefined,
        prevLastTxId: undefined,
    });
    const [isScrolling, setIsScrolling] = useState(false);
    const [column, setColumn] = useState('default');
    const [txView, setTxView] = useState('all');
    const [type, setType] = useState('default');
    const [selectedGroup, setSelectedGroup] = useState();
    const [dateRange, setDateRange] = useState([null, null]);
    const [dateFilter, setDateFilter] = useState(false);
    const [startDate, endDate] = dateRange;
    const [txDetails, setTxDetails] = useState();
    const [inboundAndOutboundTxIds, setInboundAndOutboundTxIds] = useState();
    const [inboundTxIds, setInboundTxIds] = useState();
    const [outboundTxIds, setOutboundTxIds] = useState();
    const [graphType, setGraphType] = useState();
    const [graph, setGraph] = useState({
        nodes: [],
        edges: [],
    });
    const [nodeType, setNodeType] = useState('');
    const [selectedNodeId, setSelectedNodeId] = useState();

    const settings = JSON.parse(localStorage.getItem('settings')) || {};

    const [autoSave, setAutoSave] = useState(settings.autoSave ?? true);
    const [nodeCentering, setNodeCentering] = useState(settings.nodeCentering ?? false);
    const [showLabels, setShowLabels] = useState(settings.showLabels ?? true);
    const [processedNodeIds, setProcessedNodeIds] = useState(new Set());

    const [size, setSize] = useState({
        width: '100%',
        height: '100%',
        x: 0,
        y: 0,
    });
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [saved, setSaved] = useState({
        id: '',
        case: '',
        graph: '',
        address: '',
        nodes: [],
        edges: [],
        sharedWith: [],
        savedBy: '',
        graphType,
        // has additional fields
        // locations to generate ip icon
        // insights to generate agency insights icon
    });

    // TODO: remove me when case managment and
    // enhanced graph sharing is done
    /*
    useEffect(() => {
        const storedSavedData = localStorage.getItem('artemisSavedStorage');
        if (storedSavedData) {
            const initialState = JSON.parse(storedSavedData);

            initialState.nodes.forEach((el) => {
                if (el.title && el.title.innerHTML) {
                    el.title = htmlTitle(el.title.innerHTML);
                }
            });

            initialState.edges.forEach((el) => {
                if (el.title && el.title.innerHTML) {
                    el.title = htmlTitle(el.title.innerHTML);
                }
            });

            setSaved(initialState);
        }
    }, []);
    */

    const [activeTab, setActiveTab] = useState('infoTab');

    const graph_items = {
        nodes: saved.nodes,
        edges: saved.edges,
    };

    snapPositions(network, graph_items, gridSize);

    useEffect(() => {
        if (network) {
            const MIN_ZOOM = 0.1;
            const MAX_ZOOM = 1.6;
            const lastZoomPosition = { x: 0, y: 0 };

            setCanvasZoom(network, MIN_ZOOM, MAX_ZOOM, lastZoomPosition);
            drawGrid(network);
        }
    }, [network]);

    const [getAutoComplete, { data: auto_complete_address, error: auto_complete_error }] = useLazyQuery(
        GET_AUTOCOMPLETE_ADDRESS,
        {
            fetchPolicy: 'no-cache',
        },
    );

    const [addressData, setAddressData] = useState(null);
    const [loadingAddressData, setLoadingAddressData] = useState(true);
    const [getAddressInfo] = useLazyQuery(GET_ADDRESS_INFO, {
        fetchPolicy: 'no-cache',
    });

    const [agencyInsightsTabCache, setAgencyInsightsTabCache] = useState([]);
    const [agencyInsightsSlideoutCache, setAgencyInsightsSlideoutCache] = useState([]);
    const [agencyInsightsAccountDetails, setAgencyInsightsAccountDetails] = useState([]);
    const [getAgencyInsightsAccountDetails] = useLazyQuery(GET_AGENCY_INSIGHTS_ACCOUNT_DETAILS, {
        fetchPolicy: 'no-cache',
    });

    const [agencyInsightsResults, setAgencyInsightsResults] = useState([]);
    const [getAgencyInsightsResults] = useLazyQuery(GET_AGENCY_INSIGHTS_RESULTS, {
        fetchPolicy: 'no-cache',
    });
    const [hasAgencyInsights] = useLazyQuery(HAS_AGENCY_INSIGHTS, {
        fetchPolicy: 'no-cache',
    });

    const [selectedUserId, setSelectedUserId] = useState([]);
    const [loadingAgencyInsightsSlideout, setLoadingAgencyInsightsSlideout] = useState(false);

    const [
        getInboundAndOutboundTxIds,
        { data: inbound_and_outbound_tx_ids, error: inbound_and_outbound_tx_ids_error },
    ] = useLazyQuery(GET_INBOUND_AND_OUTBOUND_TX_IDS, {
        fetchPolicy: 'no-cache',
    });

    const [getInboundTxIds, { data: inbound_tx_ids, error: inbound_tx_ids_error }] = useLazyQuery(GET_INBOUND_TX_IDS, {
        fetchPolicy: 'no-cache',
    });

    const [getOutboundTxIds, { data: outbound_tx_ids, error: outbound_tx_ids_error }] = useLazyQuery(
        GET_OUTBOUND_TX_IDS,
        {
            fetchPolicy: 'no-cache',
        },
    );

    const [getTxDetails, { data: tx_details, error: tx_details_error }] = useLazyQuery(GET_TX_ID_DETAILS, {
        fetchPolicy: 'no-cache',
    });

    const [txsOverTimeData, setTxsOverTimeData] = useState(null);
    const [txsOverTimeDailyData, setTxsOverTimeDailyData] = useState(null);
    const [getTxsOverTime] = useLazyQuery(GET_TXS_OVER_TIME, {
        fetchPolicy: 'no-cache',
    });
    const [getTxsOverTimeDaily] = useLazyQuery(GET_TXS_OVER_TIME_DAILY, {
        fetchPolicy: 'no-cache',
    });

    const get_inbound_and_outbound_tx_ids = (
        address,
        declared_type,
        first_seen,
        last_seen,
        declared_column,
        declared_skip,
        page_min_val,
        page_max_val,
        page_last_tx_id,
    ) => {
        getInboundAndOutboundTxIds({
            variables: {
                bitcoinAddress: address,
                skip: declared_skip ? Number(declared_skip) : skip[txView],
                column: declared_column || column,
                direction,
                type: declared_type || type,
                ...(declared_type && declared_type === 'sort_date' && { first_seen: first_seen / 1000 }),
                ...(declared_type && declared_type === 'sort_date' && { last_seen: last_seen / 1000 }),
                page_min_val,
                page_max_val,
                page_last_tx_id,
            },
        });
    };

    useEffect(() => {
        if (inbound_and_outbound_tx_ids?.get_inbound_and_outbound_tx_ids) {
            if (inboundAndOutboundTxIds && isScrolling) {
                setIsScrolling(false);
                setInboundAndOutboundTxIds([
                    ...inboundAndOutboundTxIds,
                    ...inbound_and_outbound_tx_ids.get_inbound_and_outbound_tx_ids,
                ]);
            } else if (!isScrolling && nodeType !== 'transaction') {
                setInboundAndOutboundTxIds(inbound_and_outbound_tx_ids?.get_inbound_and_outbound_tx_ids);
            }
            const pagingProps = getTxListPropsForNextPage(inbound_and_outbound_tx_ids.get_inbound_and_outbound_tx_ids);
            setTxIdListPagingProps(pagingProps);
        } else if (inbound_and_outbound_tx_ids_error?.message) {
            // TODO: need to inform user of load error
            // console.error(`in and out tx ids error: ${inbound_and_outbound_tx_ids_error.message}`);
            if (isScrolling) {
                setIsScrolling(false);
            }
            if (inboundAndOutboundTxIds) {
                setInboundAndOutboundTxIds([...inboundAndOutboundTxIds]);
            } else {
                setInboundAndOutboundTxIds([]);
            }
        }
    }, [inbound_and_outbound_tx_ids]);

    const get_inbound_tx_ids = (
        address,
        declared_type,
        first_seen,
        last_seen,
        declared_column,
        declared_skip,
        page_min_val,
        page_max_val,
        page_last_tx_id,
    ) => {
        getInboundTxIds({
            variables: {
                bitcoinAddress: address,
                skip: declared_skip ? Number(declared_skip) : skip[txView],
                column: declared_column || column,
                direction,
                type: declared_type || type,
                ...(declared_type && declared_type === 'sort_date' && { first_seen: first_seen / 1000 }),
                ...(declared_type && declared_type === 'sort_date' && { last_seen: last_seen / 1000 }),
                page_min_val,
                page_max_val,
                page_last_tx_id,
            },
        });
    };

    useEffect(() => {
        if (inbound_tx_ids?.get_inbound_tx_ids) {
            if (inboundTxIds && isScrolling) {
                setIsScrolling(false);
                setInboundTxIds([...inboundTxIds, ...inbound_tx_ids.get_inbound_tx_ids]);
            } else if (!isScrolling) {
                setInboundTxIds(inbound_tx_ids?.get_inbound_tx_ids);
            }
            const pagingProps = getTxListPropsForNextPage(inbound_tx_ids.get_inbound_tx_ids);
            setTxIdListPagingProps(pagingProps);
        } else if (inbound_tx_ids_error?.message) {
            // TODO: need to inform user of load error
            // console.error(`in tx ids error: ${inbound_tx_ids_error.message}`);
            if (isScrolling) {
                setIsScrolling(false);
            }
            if (inboundTxIds) {
                setInboundTxIds([...inboundTxIds]);
            } else {
                setInboundTxIds([]);
            }
        }
    }, [inbound_tx_ids]);

    const get_outbound_tx_ids = (
        address,
        declared_type,
        first_seen,
        last_seen,
        declared_column,
        declared_skip,
        page_min_val,
        page_max_val,
        page_last_tx_id,
    ) => {
        getOutboundTxIds({
            variables: {
                bitcoinAddress: address,
                skip: declared_skip ? Number(declared_skip) : skip[txView],
                column: declared_column || column,
                direction,
                type: declared_type || type,
                ...(declared_type && declared_type === 'sort_date' && { first_seen: first_seen / 1000 }),
                ...(declared_type && declared_type === 'sort_date' && { last_seen: last_seen / 1000 }),
                page_min_val,
                page_max_val,
                page_last_tx_id,
            },
        });
    };

    useEffect(() => {
        if (outbound_tx_ids?.get_outbound_tx_ids) {
            if (outboundTxIds && isScrolling) {
                setIsScrolling(false);
                setOutboundTxIds([...outboundTxIds, ...outbound_tx_ids.get_outbound_tx_ids]);
            } else if (!isScrolling) {
                setOutboundTxIds(outbound_tx_ids?.get_outbound_tx_ids);
            }
            const pagingProps = getTxListPropsForNextPage(outbound_tx_ids.get_outbound_tx_ids);
            setTxIdListPagingProps(pagingProps);
        } else if (outbound_tx_ids_error?.message) {
            // TODO: need to inform user of load error
            // console.error(`out tx ids error: ${outbound_tx_ids_error.message}`);
            if (isScrolling) {
                setIsScrolling(false);
            }
            if (outboundTxIds) {
                setOutboundTxIds([...outboundTxIds]);
            } else {
                setOutboundTxIds([]);
            }
        }
    }, [outbound_tx_ids]);

    useEffect(() => {
        if (tx_details?.get_tx_id_details) {
            setTxDetails(tx_details.get_tx_id_details);
        }
    }, [tx_details]);

    const handleTransactionPagination = (doSkip) => {
        const { prevMin, prevMax, prevLastTxId } = txIdListPagingProps;

        if (txView === 'all') {
            if (!dateFilter) {
                get_inbound_and_outbound_tx_ids(
                    active_address,
                    type,
                    undefined,
                    undefined,
                    undefined,
                    doSkip,
                    prevMin,
                    prevMax,
                    prevLastTxId,
                );
            } else {
                const { epochStartTimeUTC, epochEndTimeUTC } = epochUTC(dateRange[0], dateRange[1]);

                get_inbound_and_outbound_tx_ids(
                    active_address,
                    'sort_date',
                    epochStartTimeUTC,
                    epochEndTimeUTC,
                    'timestamp',
                    doSkip,
                    prevMin,
                    prevMax,
                    prevLastTxId,
                );
            }
        } else if (txView === 'inbound') {
            if (!dateFilter) {
                get_inbound_tx_ids(
                    active_address,
                    type,
                    undefined,
                    undefined,
                    undefined,
                    doSkip,
                    prevMin,
                    prevMax,
                    prevLastTxId,
                );
            } else {
                const { epochStartTimeUTC, epochEndTimeUTC } = epochUTC(dateRange[0], dateRange[1]);

                get_inbound_tx_ids(
                    active_address,
                    'sort_date',
                    epochStartTimeUTC,
                    epochEndTimeUTC,
                    'timestamp',
                    doSkip,
                    prevMin,
                    prevMax,
                    prevLastTxId,
                );
            }
        } else if (txView === 'outbound') {
            if (!dateFilter) {
                get_outbound_tx_ids(
                    active_address,
                    type,
                    undefined,
                    undefined,
                    undefined,
                    doSkip,
                    prevMin,
                    prevMax,
                    prevLastTxId,
                );
            } else {
                const { epochStartTimeUTC, epochEndTimeUTC } = epochUTC(dateRange[0], dateRange[1]);

                get_outbound_tx_ids(
                    active_address,
                    'sort_date',
                    epochStartTimeUTC,
                    epochEndTimeUTC,
                    'timestamp',
                    doSkip,
                    prevMin,
                    prevMax,
                    prevLastTxId,
                );
            }
        }
    };

    if (
        auto_complete_error ||
        inbound_and_outbound_tx_ids_error ||
        inbound_tx_ids_error ||
        outbound_tx_ids_error ||
        tx_details_error
    ) {
        const message = auto_complete_error
            ? auto_complete_error.message
            : inbound_and_outbound_tx_ids_error
              ? inbound_and_outbound_tx_ids_error.message
              : inbound_tx_ids_error
                ? inbound_tx_ids_error.message
                : outbound_tx_ids_error
                  ? outbound_tx_ids_error.message
                  : tx_details_error.message;

        if (message === 'User Not Authenticated') {
            window.location.href = '/login';
        }
    }

    return (
        <GraphContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                showAgencyInsightsData,
                setShowAgencyInsightsData,
                agencyInsightsLinkClicked,
                setAgencyInsightsLinkClicked,
                backLinkClicked,
                setBackLinkClicked,
                inputIsWrapped,
                setInputIsWrapped,
                outputIsWrapped,
                setOutputIsWrapped,
                active_address,
                active_transaction,
                set_active_transaction,
                copy_address,
                set_copy_address,
                copyAgencyAddress,
                setCopyAgencyAddress,
                auto_complete_address,
                auto_complete_error,
                column,
                direction,
                getAutoComplete,
                getAddressInfo,
                addressData,
                setAddressData,
                loadingAddressData,
                setLoadingAddressData,
                getTxDetails,
                txsOverTimeData,
                setTxsOverTimeData,
                txsOverTimeDailyData,
                setTxsOverTimeDailyData,
                getTxsOverTime,
                getTxsOverTimeDaily,
                get_inbound_and_outbound_tx_ids,
                get_inbound_tx_ids,
                get_outbound_tx_ids,
                graph,
                graphType,
                inboundAndOutboundTxIds,
                inboundTxIds,
                isSaved,
                open,
                outboundTxIds,
                saved,
                selectedNodeId,
                nodeCentering,
                setNodeCentering,
                setColumn,
                setDirection,
                setGraph,
                setGraphType,
                setInboundAndOutboundTxIds,
                setIsSaved,
                setOpen,
                setSaved,
                setSelectedNodeId,
                setSize,
                setSkip,
                setTxDetails,
                set_active_address,
                size,
                skip,
                txDetails,
                tx_details,
                type,
                setType,
                selectedGroup,
                setSelectedGroup,
                dateRange,
                setDateRange,
                dateFilter,
                setDateFilter,
                startDate,
                endDate,
                txView,
                setTxView,
                handleTransactionPagination,
                isScrolling,
                setIsScrolling,
                nodeType,
                setNodeType,
                network,
                setNetwork,
                isOpen,
                setIsOpen,
                loading,
                setLoading,
                showLabels,
                setShowLabels,
                highlightedNode,
                setHighlightedNode,
                isRightClick,
                setIsRightClick,
                physicsEnabled,
                setPhysicsEnabled,
                activeTab,
                setActiveTab,
                sideDrawerIsOpen,
                setSideDrawerIsOpen,
                autoSave,
                setAutoSave,
                isSaving,
                setIsSaving,
                saveGraphButton,
                setSaveGraphButton,
                agencyInsightsAccountDetails,
                setAgencyInsightsAccountDetails,
                getAgencyInsightsAccountDetails,
                agencyInsightsResults,
                setAgencyInsightsResults,
                getAgencyInsightsResults,
                hasAgencyInsights,
                agencyInsightsTabCache,
                setAgencyInsightsTabCache,
                agencyInsightsSlideoutCache,
                setAgencyInsightsSlideoutCache,
                loadingAgencyInsightsSlideout,
                setLoadingAgencyInsightsSlideout,
                selectedUserId,
                setSelectedUserId,
                processedNodeIds,
                setProcessedNodeIds,
            }}
        >
            {children}
        </GraphContext.Provider>
    );
}

export default Context;

import policeBadge from '../../Assets/Images/police-badge.webp';

export const getAgencyInsightsIcon = async (
    nodes,
    hasAgencyInsights,
    setSaved,
    address,
    userDatasetAccess,
    agencyInsightsTabCache,
    setAgencyInsightsTabCache,
) => {
    // Filter nodes that are of type 'address'
    const addressNodes = nodes.filter((n) => n.type === 'address');

    // Call hasAgencyInsights only if there are address nodes
    if (addressNodes.length > 0) {
        const hasInsights = await hasAgencyInsights({
            variables: {
                address,
                userDatasetAccess,
            },
        });
        addressNodes.forEach((n) => {
            n.insights = hasInsights && hasInsights.data.has_agency_insights?.agency_sources.length > 0;
            setAgencyInsightsTabCache([
                ...agencyInsightsTabCache,
                {
                    address,
                    insights: hasInsights.data.has_agency_insights?.agency_sources.length > 0,
                },
            ]);
        });
    }

    setSaved((saved) => ({ ...saved }));
};

export const loadAgencyInsights = async (
    address,
    setLoadingAddressData,
    agencyInsightsTabCache,
    setAgencyInsightsTabCache,
    navigate,
    getAgencyInsightsResults,
    setAgencyInsightsResults,
    userDatasetAccess,
    saved,
    setSaved,
) => {
    const cache = agencyInsightsTabCache.find((c) => c.address === address);
    if (cache?.agencyInsightsResults) {
        setAgencyInsightsResults(cache.agencyInsightsResults);
    } else {
        setLoadingAddressData(true);
        try {
            const [agencyInsightsResults] = await Promise.all([
                getAgencyInsightsResults({
                    variables: {
                        address,
                        userDatasetAccess,
                    },
                }),
            ]);

            // TODO: Add a message when agencyInsightsResults has an error
            // if (agencyInsightsResults?.errors?.message === 'ServiceUnavailable') {
            //     return;
            // }

            const cacheIndex = agencyInsightsTabCache.findIndex((item) => item.address === address);
            const results = agencyInsightsResults.data.get_agency_insights_results;
            if (cacheIndex !== -1) {
                // Update the existing entry with new data
                const updatedCache = agencyInsightsTabCache.map((item, index) =>
                    index === cacheIndex ? { ...item, agencyInsightsResults: results } : item,
                );
                setAgencyInsightsTabCache(updatedCache);
            } else {
                // Add new entry to the cache if it does not exist
                setAgencyInsightsTabCache([
                    ...agencyInsightsTabCache,
                    {
                        address,
                        agencyInsightsResults: results,
                    },
                ]);
            }

            setAgencyInsightsResults(results);
            // re render ipicon
            if (results.deposit_addresses.length > 0 || results.withdraw_addresses.length > 0) {
                const updatedNodes = saved.nodes.map((node) => {
                    if (node.id === address) {
                        return {
                            ...node,
                            insights: true,
                        };
                    }
                    return node;
                });

                setSaved({
                    ...saved,
                    nodes: updatedNodes,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingAddressData(false);
        }
    }
};

export const getAgencyInsightsSlideout = async (
    userId,
    setLoadingAgencyInsightsSlideout,
    agencyInsightsSlideoutCache,
    setAgencyInsightsSlideoutCache,
    getAgencyInsightsAccountDetails,
    setAgencyInsightsAccountDetails,
) => {
    const cache = agencyInsightsSlideoutCache.find((c) => c.userId === userId);
    if (cache) {
        setAgencyInsightsAccountDetails(cache.agencyInsightsAccountDetails);
    } else {
        setLoadingAgencyInsightsSlideout(true);
        try {
            const [agencyInsights] = await Promise.all([
                getAgencyInsightsAccountDetails({
                    variables: {
                        userId,
                    },
                }),
            ]);
            // add gremlin outage logic here
            setAgencyInsightsSlideoutCache([
                ...agencyInsightsSlideoutCache,
                {
                    userId,
                    agencyInsightsAccountDetails: agencyInsights?.data?.get_agency_insights_account_details,
                },
            ]);

            setAgencyInsightsAccountDetails(agencyInsights?.data?.get_agency_insights_account_details);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingAgencyInsightsSlideout(false);
        }
    }
};

export const getBulkAgencyInsightsIcon = async (
    nodes,
    hasAgencyInsights,
    setSaved,
    tx,
    userDatasetAccess,
    agencyInsightsTabCache,
    setAgencyInsightsTabCache,
) => {
    let allAddresses = [...tx.from_addresses, ...tx.to_addresses].map((obj) => obj.address);
    allAddresses = [...new Set(allAddresses)];

    const addressInsightsMap = {};

    // Loop through all addresses and check insights for each
    for (let i = 0; i < allAddresses.length; i++) {
        const address = allAddresses[i];
        /* eslint-disable-next-line no-await-in-loop */
        const hasInsights = await hasAgencyInsights({
            variables: {
                address,
                userDatasetAccess,
            },
        });
        addressInsightsMap[address] = hasInsights?.data.has_agency_insights.agency_sources;
        if (!agencyInsightsTabCache.some((cache) => cache.address === address)) {
            setAgencyInsightsTabCache((prevCache) => [
                ...prevCache,
                {
                    address,
                    insights: addressInsightsMap[address].length > 0,
                },
            ]);
        }
    }

    nodes.map((n) => {
        if (n.type === 'address' && addressInsightsMap[n.address] !== undefined) {
            n.insights = addressInsightsMap[n.address].length > 0;
        }
        return n;
    });

    setSaved((saved) => ({ ...saved }));
};

export const displayAgencyInsightsIcon = (node, network) => {
    const icon = new Image();
    icon.src = policeBadge;
    icon.onload = () => {
        if (network) {
            // TODO: lint complaints: unexpected unamed function
            // is there a problem with not ever removing a listener?
            network.on('afterDrawing', (ctx) => {
                const nodeId = node.id;
                const nodeExists = network.body.nodes[nodeId] !== undefined; // Check if node exists

                if (nodeExists) {
                    try {
                        const nodePosition = network.getPosition(nodeId);
                        if (nodePosition) {
                            const iconX = nodePosition.x - 28 / 2 - 26; // Adjust the offset as needed
                            const iconY = nodePosition.y - 34; // Adjust the offset as needed
                            const iconSize = 18; // Adjust the icon size as needed
                            ctx.save(); // Save the current context state
                            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                            ctx.shadowOffsetX = 2;
                            ctx.shadowOffsetY = 2;
                            ctx.shadowBlur = 4;
                            ctx.drawImage(icon, iconX, iconY, iconSize, iconSize);
                            ctx.restore(); // Restore the previous context state
                        }
                    } catch (error) {
                        console.error(`displayAgencyInsightsIcon afterDrawing error: ${error}`);
                    }
                }
            });
            network.redraw();
        }
    };
};

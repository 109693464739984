import Axios from 'axios';

export const getSingleAttributions = async (apiKey, address) => {
    let rval;
    if (apiKey) {
        try {
            if (!address.startsWith('bt-')) {
                const response = await Axios.post(`/api/fusion/attributions?address=${address}`, {
                    apiKey,
                });
                rval = response.data.attribution;
            } else if (address.startsWith('bt-')) {
                rval = address;
            }
        } catch (error) {
            console.error(error);
        }
    }
    return rval;
};

export const getBulkAttributions = async (apiKey, addresses) => {
    let rval;
    if (apiKey) {
        const btAddresses = [];
        const nonBtAddresses = [];
        addresses.forEach((address) => {
            if (address.startsWith('bt-')) {
                btAddresses.push(address);
            } else {
                nonBtAddresses.push(address);
            }
        });

        try {
            if (nonBtAddresses && nonBtAddresses.length > 0) {
                const response = await Axios.post(`/api/fusion/attributions/bulk`, {
                    apiKey,
                    addresses: nonBtAddresses,
                });

                rval = response.data;
            } else if (btAddresses && btAddresses.length > 0) {
                rval = btAddresses;
            }
        } catch (error) {
            console.error(error);
        }
    }
    return rval;
};

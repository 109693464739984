import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

function PrivateRoute({ children, roles, subscription }) {
    const { isAuthenticated, user, userStatus } = useContext(AuthContext);

    // If not authenticated and userStatus is not 500, navigate to login
    if (!isAuthenticated && userStatus !== 500) {
        return <Navigate to="/login" replace />;
    }

    // If userStatus is 500, navigate to maintenance
    if (userStatus === 500) {
        return <Navigate to="/maintenance" replace />;
    }

    // If authenticated and userStatus is not 500, but user role is not allowed, navigate to dashboard
    if (isAuthenticated && !roles.includes(user.role)) {
        return <Navigate to="/" replace />;
    }

    // If authenticated, userStatus is not 500, role is allowed but user does not have the required subscription or no api key, navigate to dashboard
    if (
        subscription &&
        subscription !== 'none' &&
        (!user.subscriptions || !user.subscriptions.includes(subscription))
    ) {
        return <Navigate to="/" replace />;
    }

    // If authenticated, userStatus is not 500, role and subscription are allowed, render the children
    return children;
}

export default PrivateRoute;

import React from 'react';
import { Container, Card, Row, Col, CardBody } from 'reactstrap';
import ArcaneTablePlaceholder from './ArcaneTablePlaceholder';
import ArcaneMapPlaceholder from './ArcaneMapPlaceholder';

function ArcanePlaceholder() {
    const arcaneCardPlaceholderStyle = { minHeight: window.innerHeight >= 964 ? '700px' : '500px' };

    return (
        <div className="page-content position-relative min-vh-100 z-2">
            <Container fluid className="mh-100">
                <Card className="border shadow-sm">
                    <Row className="w-100 mx-0">
                        <ArcaneMapPlaceholder />
                    </Row>
                </Card>
                <Card className="shadow-sm border z-1" id="main-card" style={arcaneCardPlaceholderStyle}>
                    <CardBody>
                        <Row>
                            <Col lg={12}>
                                <ArcaneTablePlaceholder />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default ArcanePlaceholder;

import Axios from 'axios';
import store from '../../Store';
import { setAppConfig } from '../../Store/AppConfig/appConfigSlice';

async function loadAppConfig() {
    let data;
    try {
        const results = await Axios.get('/api/admin/configuration');

        if (results.status === 200) {
            data = results.data;
        }
    } catch (error) {
        console.error(`Utils Admin loadAppConfig caught error loading config variables: ${error}`);
    }
    return data;
}

function storeData(data) {
    try {
        store.dispatch(setAppConfig(data));
    } catch (error) {
        console.error(`Utils Admin storeData caught error storign config variables: ${error}`);
    }
}

async function loadAndStoreAppConfig() {
    const data = await loadAppConfig();
    if (data) {
        storeData(data);
    }
}

export default loadAndStoreAppConfig;

import React, { useEffect } from 'react';
import { toggleFullscreen } from '../../../../../Utils/Header/Toggle';
import { renderTooltip } from '../../../../../Utils/Helpers/helpers';

function FullscreenButton({ fullscreen, setFullscreen, iconColor }) {
    const checkFullscreen = () => {
        setFullscreen(
            // "!!" will convert the document checks to true or false depending on document value
            !!(document.fullscreenElement || document.msFullscreenElement || document.webkitFullscreenElement),
        );
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', checkFullscreen);

        return () => {
            document.removeEventListener('fullscreenchange', checkFullscreen);
        };
    }, []);

    return (
        <>
            {renderTooltip('fullscreenTooltip', 'absolute')}
            <button
                type="button"
                onClick={() => {
                    setFullscreen(!fullscreen);
                    toggleFullscreen(fullscreen);
                }}
                className="btn header-item noti-icon buttonPush"
                data-toggle="fullscreen"
                aria-label="Toggle fullscreen"
            >
                <i
                    className={`bx bx-${!fullscreen ? 'fullscreen' : 'exit-fullscreen'}`}
                    style={{ color: iconColor }}
                    data-tooltip-place="bottom"
                    data-tooltip-id="fullscreenTooltip"
                    data-tooltip-content={!fullscreen ? 'Fullscreen' : 'Exit Fullscreen'}
                />
            </button>
        </>
    );
}

export default FullscreenButton;

import { CASES_SLICE_NAME } from './casesSlice';

export const getCasesList = (state) => {
    const slice = state[CASES_SLICE_NAME];
    return slice ? slice.casesList : [];
};

export const getCase = (state, id) => {
    const slice = state[CASES_SLICE_NAME];
    return slice ? slice.casesById[id] : undefined;
};

export const getGraph = (state, caseId, graphId) => {
    const slice = state[CASES_SLICE_NAME];
    if (!slice) {
        return undefined;
    }

    const { casesById = {} } = slice;
    const theCase = casesById[caseId];
    if (!theCase) {
        return undefined;
    }

    const theGraphs = theCase.graphs;
    if (!theGraphs) {
        return undefined;
    }

    const theGraph = theGraphs.filter(({ _id: id }) => id === graphId);
    return theGraph && theGraph.length === 1 ? theGraph[0] : undefined;
};

export const getSelectedCaseId = (state) => {
    const slice = state[CASES_SLICE_NAME];
    return slice ? slice.selectedCaseId : undefined;
};

export const getSelectedGraphIds = (state) => {
    const slice = state[CASES_SLICE_NAME];
    return slice ? slice.selectedGraphIds : undefined;
};

export const getSelectedCase = (state) => {
    const slice = state[CASES_SLICE_NAME];
    if (!slice || !slice.selectedCaseId) {
        return undefined;
    }
    return slice.casesById[slice.selectedCaseId];
};

export const getSelectedCaseGraphs = (state) => {
    const slice = state[CASES_SLICE_NAME];
    if (!slice || !slice.selectedCaseId) {
        return undefined;
    }

    const { casesById = {}, selectedCaseId } = slice;
    const theCase = casesById[selectedCaseId];
    return theCase ? theCase.graphs : undefined;
};

export const getSelectedGraph = (state) => {
    const slice = state[CASES_SLICE_NAME];
    if (!slice) {
        return undefined;
    }

    const { selectedCaseId, selectedGraphIds = [] } = slice;
    if (!selectedCaseId || selectedGraphIds.length !== 1) {
        return undefined;
    }
    return getGraph(state, selectedCaseId, selectedGraphIds[0]);
};

export const getAllSelectedGraphs = (state) => {
    const slice = state[CASES_SLICE_NAME];
    if (!slice) {
        return undefined;
    }

    const { selectedCaseId, selectedGraphIds = [] } = slice;
    if (!selectedCaseId) {
        return undefined;
    }
    const selectedGraphs = [];
    selectedGraphIds.forEach((id) => {
        selectedGraphs.push(getGraph(state, selectedCaseId, id));
    });
    return selectedGraphs;
};

import forge from 'node-forge';

const RSA_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC9WKyBIDiU6nZ4Uz0rPKfUMXvP
kqZEFer2sJmmAVLsYT3ZWPOvUesxZLP2rO1gHqRQMQ5/UXeGSrizPSrtDAP5KBOB
X77C2PhwWeXTuiWI9JztBj9/icAo7gUczLRS6b9KdhLzPZ89cOutauaoQojGxvXK
9oyvVIcBduwz4ZgL/wIDAQAB
-----END PUBLIC KEY-----`;

const encryptPassword = (password) => {
    const publicKey = forge.pki.publicKeyFromPem(RSA_PUBLIC_KEY);
    const encrypted = publicKey.encrypt(password, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
    });
    return forge.util.encode64(encrypted);
};

const Auth = {
    login: async (user) => {
        const encryptedPassword = encryptPassword(user.password);

        const res = await fetch('/api/user/login', {
            method: 'post',
            body: JSON.stringify({ ...user, password: encryptedPassword }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (res.status === 200) {
            return res.json().then((data) => data);
        }

        const errorBody =
            res.status === 401
                ? 'Invalid username or password!'
                : res.status === 409
                  ? 'User is already active'
                  : `An error occurred during login. (${res.statusText})`;

        return {
            isAuthenticated: false,
            user: {
                username: '',
                role: '',
                subscriptions: '',
                _id: '',
            },
            status: res.status,
            message: {
                msgBody: errorBody,
                msgError: true,
            },
        };
    },

    register: async (user) => {
        const res = await fetch('/api/user/register', {
            method: 'post',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await res.json();
        return data;
    },

    logout: async () => {
        const res = await fetch('/api/user/logout');
        const data = await res.json();
        return data;
    },

    isAuthenticated: async () => {
        const res = await fetch('/api/user/authenticated', {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
            },
        });
        if (res.status !== 401 && res.status !== 500) {
            return res.json().then((data) => data);
        }
        return {
            isAuthenticated: false,
            user: {
                username: '',
                role: '',
                subscriptions: [],
                datasets: [],
                _id: '',
            },
            status: res.status,
        };
    },
};

export default Auth;

/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Button } from 'reactstrap';
import './AdminPlaceholders.css';

function UserTablePlaceholder() {
    const [items, setItems] = useState([]);
    const tableHeight = window.innerHeight >= 893 ? window.innerHeight - 360 : window.innerHeight - 320;
    const rowHeight = 52; // The desired row height

    useEffect(() => {
        const maxRows = Math.floor(tableHeight / rowHeight);
        const newItems = [];

        for (let i = 0; i < maxRows; i++) {
            newItems.push(
                <tr key={i}>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg placeholder col-12" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder col-12" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg role-badge placeholder col-12" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg placeholder col-12" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg placeholder col-12" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg placeholder col-12" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg badge-placeholder placeholder col-12 ms-3" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg placeholder col-12 ms-4" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder-lg placeholder col-12 ms-4" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder col-12 ms-5" />
                    </td>
                    <td className="placeholder-glow">
                        <div className="placeholder col-12 ms-5" />
                    </td>
                    <td className="placeholder-glow pe-5">
                        <div className="placeholder col-12 ms-5" />
                    </td>
                </tr>,
            );
        }

        setItems(newItems);
    }, [tableHeight]);

    return (
        <div className="page-content p-0">
            <Row>
                <Col lg="12">
                    <Row>
                        <Col xs="12">
                            <Card className="shadow-sm border">
                                <CardBody>
                                    <CardTitle className="d-inline-block placeholder-glow placeholderCardTitle">
                                        <div className="placeholder-lg placeholder col-12" />
                                    </CardTitle>
                                    <CardSubtitle className="placeholder-glow placeholderCardSubtitle">
                                        <div className="placeholder-lg placeholder col-12" />
                                    </CardSubtitle>
                                    <Row className="mb-2 mt-1">
                                        <Col sm="6">
                                            <div className="d-flex flex-row">
                                                <div className="search-box mb-2 mt-2 d-inline-block placeholderSearchIcon">
                                                    <div className="position-relative placeholder-glow">
                                                        <Input className="placeholder-lg placeholder col-12" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm="6" className="align-items-end">
                                            <div className="text-sm-end mt-2 placeholder-glow">
                                                <i
                                                    className="placeholder-lg placeholder me-2 mb-2 downloadIconPlaceholder"
                                                    id="downloadtooltip"
                                                />
                                                <Button
                                                    outline
                                                    className="placeholder-sm placeholder btn-sm btn-outline btn-rounded circular-button mb-2 me-2"
                                                    aria-label="Refresh stats placeholder"
                                                />

                                                <Button
                                                    outline
                                                    className="col-2 placeholder-lg placeholder btn-sm btn-outline btn-rounded mb-2 me-2 openCreateModalButtonPlaceholder"
                                                    aria-label="Open register model placeholder"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive" id="admin-table">
                                        <Table
                                            className="table table-responsive table-centered table-nowrap mb-0"
                                            style={{ height: tableHeight }}
                                        >
                                            <thead className="theadPlaceholder">
                                                <tr>
                                                    <th className="placeholder-sm placeholderHeaderW3" />
                                                    <th className="header-placeholder placeholder-lg placeholderHeaderW14" />
                                                    <th className="placeholder-lg placeholderHeaderW5" />
                                                    <th className="placeholder-lg placeholderHeaderW8" />
                                                    <th className="placeholder-lg placeholderHeaderW13" />
                                                    <th className="placeholder-lg placeholderHeaderW13" />
                                                    <th className="placeholder-lg placeholderHeaderW4" />
                                                    <th className="placeholder-lg placeholderHeaderW13" />
                                                    <th className="placeholder-lg placeholderHeaderW13" />
                                                    <th className="placeholder-lg placeholderHeaderW3" />
                                                    <th className="placeholder-lg placeholderHeaderW3" />
                                                    <th className="placeholder-lg placeholderHeaderW3" />
                                                    <th className="placeholder-lg me-2 placeholderHeaderW8" />
                                                </tr>
                                            </thead>
                                            <tbody>{items}</tbody>
                                        </Table>
                                    </div>

                                    <div className="ms-2 mt-3 font-size-14">
                                        Loading <i className="bx bx-loader-circle bx-spin align-middle" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default UserTablePlaceholder;

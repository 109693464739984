export const drawGrid = async (network) => {
    if (network) {
        network.on('beforeDrawing', (ctx) => {
            const zoom = network.getScale(); // get current zoom level
            const { canvas } = network;
            const { container } = network.body;
            const { width, height } = container.getBoundingClientRect();
            const spacing = 30;

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const widthInWorld = width / zoom;
            const heightInWorld = height / zoom;

            const { x: topX, y: topY } = network.DOMtoCanvas({ x: 0, y: 0 });

            // calculate starting point for grid lines
            const startX = Math.floor(topX / spacing) * spacing;
            const startY = Math.floor(topY / spacing) * spacing;

            // draw grid
            ctx.strokeStyle = 'lightgrey';
            ctx.beginPath();
            for (let x = startX; x <= topX + widthInWorld; x += spacing) {
                ctx.moveTo(x, topY);
                ctx.lineTo(x, topY + heightInWorld);
            }
            for (let y = startY; y <= topY + heightInWorld; y += spacing) {
                ctx.moveTo(topX, y);
                ctx.lineTo(topX + widthInWorld, y);
            }
            ctx.stroke();
        });
    }
};

export const setCanvasZoom = async (network, MIN_ZOOM, MAX_ZOOM, lastZoomPosition) => {
    if (network) {
        lastZoomPosition = network.getViewPosition();

        // Get new zoom position when background is dragged
        network.on('dragEnd', () => {
            lastZoomPosition = network.getViewPosition();
        });

        network.on('zoom', (params) => {
            const scale = network.getScale();
            const { pointer } = params;

            if (scale <= MIN_ZOOM) {
                // Prevent zooming below MIN_ZOOM value
                network.moveTo({
                    scale: MIN_ZOOM,
                    position: lastZoomPosition, // Maintain the last zoom position
                });
            } else if (scale >= MAX_ZOOM) {
                // Prevent zooming above MAX_ZOOM value
                network.moveTo({
                    scale: MAX_ZOOM,
                    position: lastZoomPosition, // Maintain the last zoom position
                });
            } else {
                // Update the last zoom position when zooming within the range
                lastZoomPosition = network.getViewPosition(pointer);
            }
        });
    }
};

export const clearData = (
    set_address,
    setSelectedNodeId,
    setSideDrawerIsOpen,
    setSelectedGroup,
    setSaved,
    saved,
    setDirection,
    setType,
    setColumn,
    setDateRange,
    setDateFilter,
    setSkip,
    setInboundAndOutboundTxIds,
    setLoading,
    setActiveTab,
    setLocations,
    clearIcons,
    setIsSaved,
    setProcessedNodeIds,
    setObservationCache,
) => {
    clearIcons();
    setSaved({
        ...saved,
        id: '',
        case: '',
        graph: '',
        address: '',
        nodes: [],
        edges: [],
        sharedWith: [],
        savedBy: '',
        graphType: null,
    });

    set_address('');
    setSelectedNodeId('');
    setSideDrawerIsOpen(false);
    setType();
    setColumn('default');
    setDirection('ASC');
    setSkip({
        all: 0,
        inbound: 0,
        outbound: 0,
    });
    setSelectedGroup();
    setDateFilter(false);
    setDateRange([null, null]);
    setInboundAndOutboundTxIds();
    setLocations(null);
    setActiveTab('infoTab');
    setLoading(false);
    setIsSaved(false);
    setProcessedNodeIds(new Set());
    setObservationCache([]);
};

/* eslint-disable react/no-array-index-key, no-shadow, jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Card, CardHeader, Collapse, DropdownItem } from 'reactstrap';
import Axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { expandAccordion } from '../../../../../../Utils/Header/Cases/OpenCases';
import { fetchOneCase } from '../../../../../../Utils/Header/Cases/FetchCase';
import { renderTooltip } from '../../../../../../Utils/Helpers/helpers';

dayjs.extend(utc);

function GraphAccordion({
    reducedCases,
    title,
    expand,
    allCases,
    setDrawerIsOpen,
    setLoadingAddresses,
    setActiveAddress,
    setSaved,
    setIsSaved,
    setMessage,
    network,
    setExpand,
    setTitle,
    setUsers,
    setAttributionCache,
    attributionCache,
    users,
    drp_link,
    user,
    setAutoSave,
}) {
    const fetchUsers = async () => {
        const controller = new AbortController();
        const results = await Axios.get(`/api/user/users`, {
            signal: controller.signal,
        });

        if (results.status === 200) {
            setUsers(results.data);
            controller.abort();
        }
    };

    useEffect(() => {
        if (drp_link) {
            fetchUsers();
        }
    }, [drp_link]);

    const matchUsers =
        users &&
        users.length > 0 &&
        allCases &&
        allCases.map((c) => {
            const matchingUser = users.find((u) => c.savedBy === u._id && user._id !== c.savedBy);
            if (matchingUser) {
                return `Shared By: ${matchingUser.email}`;
            }
            if (user._id === c.savedBy) {
                return 'You Shared';
            }
            return 'Shared By: Deleted User';
        });

    return (
        <>
            {renderTooltip('sharedWithTooltip', 'absolute')}
            <SimpleBar style={{ maxHeight: '310px', width: '315px' }}>
                <div id="accordion">
                    {reducedCases && reducedCases.length >= 1 ? (
                        <div>
                            {reducedCases.map((p, i) => (
                                <Card className="mb-1" key={i + 1}>
                                    <CardHeader
                                        className="font-size-12 pointerCursor"
                                        name={p.case && p.case}
                                        onClick={() => expandAccordion(p.case, title, setTitle, setExpand)}
                                    >
                                        <div className="d-flex align-items-center" name={p.case && p.case}>
                                            <div className="me-3" name={p.case && p.case}>
                                                <i
                                                    name={p.case && p.case}
                                                    className={`bx ${
                                                        title === p.case && expand ? 'bxs-folder-open' : 'bxs-folder'
                                                    } mt-1 font-size-18 text-warning`}
                                                />
                                            </div>
                                            <div className="flex-grow-1" name={p.case && p.case}>
                                                <h6
                                                    className="m-0"
                                                    name={p.case && p.case}
                                                    onClick={() => expandAccordion(p.case, title, setTitle, setExpand)}
                                                >
                                                    <span
                                                        className="text-dark pointerCursor"
                                                        name={p.case && p.case}
                                                        onClick={() =>
                                                            expandAccordion(p.case, title, setTitle, setExpand)
                                                        }
                                                    >
                                                        {p.case.length >= 24 ? `${p.case.substring(0, 15)}...` : p.case}
                                                    </span>
                                                </h6>
                                                <span
                                                    className="fst-italic"
                                                    name={p.case && p.case}
                                                    onClick={() => expandAccordion(p.case, title, setTitle, setExpand)}
                                                >
                                                    Last Active - {dayjs(p.lastActive).format(`MM/DD/YYYY h:mm a`)}
                                                </span>
                                            </div>
                                            <div className="ms-auto">
                                                {allCases &&
                                                    allCases.length >= 1 &&
                                                    allCases.some(
                                                        (s) =>
                                                            s.case === p.case &&
                                                            s.sharedWith &&
                                                            s.sharedWith.length > 0,
                                                    ) &&
                                                    matchUsers && (
                                                        <i
                                                            className="bx bx-share-alt align-middle text-secondary font-size-15"
                                                            id={`shared-case-tooltip-${i}`}
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <Collapse isOpen={title === p.case ? expand : null}>
                                        {allCases && allCases.length >= 1
                                            ? allCases.map((s, i) =>
                                                  s.case === p.case ? (
                                                      <React.Fragment key={i + 1}>
                                                          <DropdownItem
                                                              className="font-size-12"
                                                              id={s._id}
                                                              onClick={() => {
                                                                  fetchOneCase(
                                                                      s._id,
                                                                      setDrawerIsOpen,
                                                                      setLoadingAddresses,
                                                                      setActiveAddress,
                                                                      setSaved,
                                                                      setIsSaved,
                                                                      setMessage,
                                                                      network,
                                                                      setUsers,
                                                                      setAttributionCache,
                                                                      attributionCache,
                                                                      user,
                                                                      setAutoSave,
                                                                  );
                                                              }}
                                                          >
                                                              <div className="d-flex align-items-center">
                                                                  <div className="me-3 ">
                                                                      <i className="fas fa-project-diagram mt-2 mb-2 font-size-11 text-info" />
                                                                  </div>
                                                                  <div className="flex-grow-1 align-self-center">
                                                                      <div className="text-muted d-flex align-items-center justify-content-between">
                                                                          <div>
                                                                              <b>
                                                                                  {s.graph.length >= 20
                                                                                      ? `${s.graph.substring(0, 18)}...`
                                                                                      : s.graph}
                                                                              </b>
                                                                              <p name={s.graph} className="mb-0">
                                                                                  {dayjs(s.lastActive).format(
                                                                                      `MM/DD/YYYY h:mm a`,
                                                                                  )}
                                                                              </p>
                                                                          </div>
                                                                          <div className="ms-auto">
                                                                              {s.sharedWith &&
                                                                                  s.sharedWith.length > 0 &&
                                                                                  matchUsers && (
                                                                                      <i
                                                                                          className={`bx bx-share-alt align-middle ${
                                                                                              matchUsers[i] !==
                                                                                              'You Shared'
                                                                                                  ? 'text-success'
                                                                                                  : 'text-danger'
                                                                                          } font-size-15`}
                                                                                          data-tooltip-place="bottom"
                                                                                          data-tooltip-id="sharedWithTooltip"
                                                                                          data-tooltip-content={
                                                                                              matchUsers[i]
                                                                                          }
                                                                                      />
                                                                                  )}
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <hr />
                                                              </div>
                                                          </DropdownItem>
                                                          <DropdownItem divider className="mb-0" />
                                                      </React.Fragment>
                                                  ) : null,
                                              )
                                            : null}
                                    </Collapse>
                                </Card>
                            ))}
                        </div>
                    ) : null}
                </div>
            </SimpleBar>
        </>
    );
}

export default GraphAccordion;

import { configureStore } from '@reduxjs/toolkit';
import appConfigSlice, { APPCONFIG_SLICE_NAME } from './AppConfig/appConfigSlice';
import casesSlice, { CASES_SLICE_NAME } from './ArtemisCases/casesSlice';

const store = configureStore({
    reducer: {
        [APPCONFIG_SLICE_NAME]: appConfigSlice,
        [CASES_SLICE_NAME]: casesSlice,
    },
});

export default store;

import React, { createContext, useState } from 'react';

export const ActiveContext = createContext();

function ActiveSidebar({ children }) {
    const [active, setActive] = useState({
        artemis: false,
        artemisCase: false,
        artemisGraph: false,
        arcane: false,
        strikes: false,
        admin: false,
        home: false,
    });

    return (
        <ActiveContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{ active, setActive }}
        >
            {children}
        </ActiveContext.Provider>
    );
}

export default ActiveSidebar;

import './Styles/titles.css';
import { formattedNumber, formattedBTC } from '../Helpers/helpers';

export const htmlTitle = (html) => {
    const container = document.createElement('div');
    container.innerHTML = html;
    container.className = 'vis-tooltip';
    return container;
};

export const nodeAddressTitle = (address) =>
    htmlTitle(
        `
            <table style="width: 100%;">
                <thead style="background:#dcdfdc">
                    <tr>
                        <th class="tableHeaderData">Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="tableData">
                            <span>${address}</span>
                        </td>
                    </tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                </tbody>
            </table>
        `,
    );

export const noProviderDataTitle = () => {
    let html = '';

    html += `
        <table>
            <tr>
                <td style="font-weight: bold; padding: 1vh;">No Data</td>
            </tr>
        </table>
    `;

    return htmlTitle(html);
};
export const nodeAttributionTitle = (attributions, attribution_color_scheme) => {
    let html = '';

    const knownAttributions = attributions.filter(
        (attribution) => attribution.type !== 'Unknown' && attribution.type !== undefined,
    );

    if (knownAttributions.length === 0) {
        html += `
            <table>
                <tr>
                    <td style="font-weight: bold; padding: 1vh;">Unknown</td>
                </tr>
            </table>
        `;
    } else {
        html += `
            <table>
                <thead style="background:#dcdfdc">
                    <tr>
                        <th class="tableProviderHeaderData">Provider</th>
                        <th class="tableAttributionHeaderData">Attribution</th>
                        <th class="tableCategoryHeaderData">Category</th>
                    </tr>
                </thead>
                <tbody>
            `;

        knownAttributions.forEach((attribution) => {
            if (attribution.source !== 'Unknown') {
                const matchingColorScheme =
                    attribution && attribution.type
                        ? attribution_color_scheme.find(
                              (colorScheme) => colorScheme.type === attribution.type.toLowerCase(),
                          )
                        : undefined;
                const color = matchingColorScheme ? matchingColorScheme.color : '#8E91A3';
                // Lint and prettier conflict on this edge case
                // as to how to indent mult-line template literal strings.
                // Let prettier win, tell lint to ignore these lines
                // up to the next enable comment:
                /* eslint-disable */
                html += `
                    <tr>
                        <td class="providerTableData">${attribution.source}</td>
                        <td class="attributionTableData" style="${
                            attribution.name !== 'Unknown' && attribution.type !== null ? 'color: #0f83ff' : ''
                        }">
                            ${
                                attribution.name !== null
                                    ? attribution.name.length > 60
                                        ? `${attribution.name.substring(0, 60)}...`
                                        : attribution.name
                                    : 'Unknown'
                            }
                        </td>
                        <td class="categoryTableData">
                            <span style="color:white; background:${color}" class="categoryBadge">
                                ${attribution.type !== null ? attribution.type : 'Unknown'}
                            </span>
                        </td>
                    </tr>
                `;
                /* eslint-enable */
            }
        });

        html += `
            </tbody>
        </table>
        `;
    }

    return htmlTitle(html);
};

export const transactionTitle = (
    tx_id,
    timestamp,
    total_input_btc,
    total_output_btc,
    total_input_usd,
    total_output_usd,
) =>
    htmlTitle(
        `
        <table>
            <thead style="background:#dcdfdc">
                <tr>
                    <th class="tableHeaderData">Tx ID</th>
                    <th class="tableHeaderData">Time</th>
                    <th class="tableHeaderData">Total Input Value</th>
                    <th class="tableHeaderData">Total Output Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="tableData">
                        <span style="color: #0f83ff">${`${tx_id.substring(0, 15)}...`}</span>
                    </td>
                    <td class="tableData">
                        ${timestamp}
                    </td>
                    <td class="tableData">
                        <div style="color:#6c757d"><b>${formattedBTC(total_input_btc, 8)} BTC</b></div>
                        <div style="color:#6c757d">${formattedNumber(total_input_usd, 2)} USD</div>
                    </td>
                    <td class="tableData">
                        <div style="color:#6c757d"><b>${formattedBTC(total_output_btc, 8)} BTC</b></div>
                        <div style="color:#6c757d">${formattedNumber(total_output_usd, 2)} USD</div>
                    </td>
                </tr>
                <tr><td></td></tr>
                <tr><td></td></tr>
            </tbody>
        </table>
    `,
    );

export const toEdgeTitle = (output, outputValueBtc, outputValueUsd) =>
    htmlTitle(
        `
            <table>
            <thead style="background:#dcdfdc;">
                <tr>
                    <th class="tableHeaderData">Amount</th>
                    <th class="tableHeaderData"></th>
                    <th class="tableHeaderData">To</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="tableData">
                        <div style="color:#6c757d"><b>${formattedBTC(outputValueBtc, 8)} BTC</b></div>
                        <div style="color:#6c757d">${formattedNumber(outputValueUsd, 2)} USD</div>
                    </td>
                    <td class="tableData">
                        <i class="fas fa-long-arrow-alt-right text-success ms-2 me-2"></i>
                    </td>
                     <td class="tableData">
                        ${`${output.substring(0, 15)}...`}
                    </td>
                </tr>
            </tbody>
        </table>
        `,
    );

export const fromEdgeTitle = (input, inputValueBtc, inputValueUsd) =>
    htmlTitle(
        `
          <table class="transactionTableContainer">
            <thead style="background:#dcdfdc; ">
                <tr >
                    <th class="tableHeaderData">From</th>
                    <th class="tableHeaderData"></th>
                    <th class="tableHeaderData">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="tableData">
                        ${`${input.substring(0, 15)}...`}
                    </td>
                    <td class="tableData">
                        <i class="fas fa-long-arrow-alt-right text-success ms-2 me-2"></i>
                    </td>
                    <td class="tableData">
                        <div style="color:#6c757d"><b>${formattedBTC(inputValueBtc, 8)} BTC</b></div>
                        <divstyle="color:#6c757d">${formattedNumber(inputValueUsd, 2)} USD</divstyle=>
                    </td>
                </tr>
            </tbody>
        </table>
        `,
    );

import React from 'react';
import { saveCanvasImage } from '../../../../../Utils/Header/SaveCanvasImage';
import { renderTooltip } from '../../../../../Utils/Helpers/helpers';

function SaveCanvasImageButton({ network, iconColor }) {
    return (
        <>
            {renderTooltip('saveCanvasTooltip', 'absolute')}
            <button
                type="button"
                onClick={() => {
                    saveCanvasImage(network);
                }}
                className="btn header-item noti-icon buttonPush"
                data-toggle="saveCanvas"
                aria-label="Download Canvas Image"
            >
                <i
                    className="bx bx-download"
                    style={{ color: iconColor }}
                    data-tooltip-place="bottom"
                    data-tooltip-id="saveCanvasTooltip"
                    data-tooltip-content="Save Canvas Image"
                />
            </button>
        </>
    );
}

export default SaveCanvasImageButton;

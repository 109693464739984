import { createSlice } from '@reduxjs/toolkit';
import * as C from './constants';

/**
 * @namespace AppConfigState
 */

const APPCONFIG_SLICE_NAME = 'appConfig';

const initialState = {
    [C.CONFIGNAME_IS_NEWSFEED_VISIBLE]: true,
    [C.CONFIGNAME_GOOGLEMAPS_APIKEY]: undefined,
};

const appConfigSlice = createSlice({
    name: APPCONFIG_SLICE_NAME,
    initialState,
    reducers: {
        setAppConfig(state, action) {
            const { payload = {} } = action;
            C.CONFIG_VARIABLES.forEach((configVar) => {
                if (typeof payload[configVar] !== 'undefined') {
                    state[configVar] = payload[configVar];
                }
                // Not necessary, but helpful to debug?
                /*
                else {
                    console.error(`appConfigSlice: app config ${configVar} is not defined in action payload`);
                }
                */
            });
        },
    },
});

// Exporting initialState as convenience for unit test:
export { initialState, APPCONFIG_SLICE_NAME };

// `createSlice` automatically generated action creators with these names.
// Export them as named exports from this "slice" file
export const { setAppConfig } = appConfigSlice.actions;

// Export the slice reducer as the default export
export default appConfigSlice.reducer;

import React from 'react';
import { CardTitle, CardSubtitle, Button } from 'reactstrap';

function StrikesTablePlaceholder() {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div style={{ flex: 1 }}>
                    <CardTitle className="d-inline-block placeholder-glow mt-1" style={{ width: '12%' }}>
                        <div className="placeholder-lg placeholder col-12" />
                    </CardTitle>
                    <CardSubtitle className="placeholder-glow mb-2" style={{ width: '30%' }}>
                        <div className="placeholder-lg placeholder col-12" />
                    </CardSubtitle>
                </div>
                <div className="text-sm-end mt-2 placeholder-glow align-items-end">
                    <Button
                        className="placeholder-lg placeholder btn-sm btn-rounded ms-1 mt-1"
                        style={{ width: '6.5rem', height: '1.7rem' }}
                        aria-label="Open register model placeholder"
                        disabled
                    />
                </div>
            </div>
            <hr />
            <h4 className="placeholder-glow my-0">
                <span className="placeholder-lg placeholder col-12" style={{ width: '8%' }} />
            </h4>

            <div
                className="d-flex align-items-center justify-content-center placeholder-glow"
                style={{ marginTop: window.innerHeight >= 964 ? '12.2rem' : '8.5rem' }}
            >
                <Button
                    className="placeholder-lg placeholder btn-lg btn-rounded ms-1"
                    id="big-button"
                    style={{ width: '10rem' }}
                    aria-label="New Search model placeholder"
                    disabled
                />
            </div>
        </>
    );
}

export default StrikesTablePlaceholder;

import React from 'react';

function StrikesInfoPlaceholder() {
    return (
        <>
            <div
                className="col-lg-10 col-sm-10 px-4 pt-4"
                style={{ paddingBottom: window.innerHeight >= 964 ? '4.308rem' : '1.5rem' }}
            >
                <h4 className="placeholder-glow my-0">
                    <span className="placeholder-lg placeholder col-4" style={{ width: '12%' }} />
                </h4>
                <br />
                <p className="placeholder-glow">
                    <span className="placeholder-lg placeholder col-4" style={{ width: '20%' }} />
                </p>
                <div>
                    <p className="placeholder-glow mb-1">
                        <span className="placeholder-lg placeholder col-4" style={{ width: '15%' }} />
                    </p>
                    <p className="placeholder-glow mb-1">
                        <span className="placeholder-lg placeholder col-4" style={{ width: '12%' }} />
                    </p>
                    <p className="placeholder-glow mb-0">
                        <span className="placeholder-lg placeholder col-4" style={{ width: '13%' }} />
                    </p>
                </div>
            </div>
            <div className="d-flex align-self-stretch placeholder-glow p-3 col-lg-2 col-sm-2">
                <div className="placeholder-lg placeholder w-100" />
            </div>
        </>
    );
}

export default StrikesInfoPlaceholder;

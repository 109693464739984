import { APPCONFIG_SLICE_NAME } from './appConfigSlice';
import * as C from './constants';

export const getIsNewsFeedVisible = (state) => {
    const slice = state[APPCONFIG_SLICE_NAME];
    return slice ? slice[C.CONFIGNAME_IS_NEWSFEED_VISIBLE] : undefined;
};

export const getGoogleMapsApiKey = (state) => {
    const slice = state[APPCONFIG_SLICE_NAME];
    return slice ? slice[C.CONFIGNAME_GOOGLEMAPS_APIKEY] : undefined;
};

export const getAppConfigVariables = (state) => state[APPCONFIG_SLICE_NAME];

import React from 'react';
import { CardTitle, CardSubtitle } from 'reactstrap';
import './Styles/ArcaneTablePlaceholder.css';

function ArcaneTablePlaceholder() {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                    <CardTitle className="d-inline-block placeholder-glow mt-3 arcaneTablePlaceholderTitle">
                        <div className="placeholder-lg placeholder col-12" />
                    </CardTitle>
                    <div className="d-flex align-items-center">
                        <CardSubtitle className="placeholder-glow me-3 w-50">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-1 arcaneTableW2">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-3 arcaneTableColumnW8">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-1 arcaneTableColumnW2">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-3 arcaneTableColumnW8">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                    </div>
                    <h2 className="d-flex align-items-center mt-3">
                        <CardSubtitle className="placeholder-glow me-1 arcaneTableColumnW3">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-1 arcaneTableColumnW30">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-5 arcaneTableColumnW6">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-1 arcaneTableColumnW6">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-5 arcaneTableColumnW15">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-1 arcaneTableColumnW6">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                        <CardSubtitle className="placeholder-glow me-5 arcaneTableColumnW15">
                            <div className="placeholder-lg placeholder col-12" />
                        </CardSubtitle>
                    </h2>
                </div>
            </div>
            <h4 className="placeholder-glow my-0 mt-3">
                <span className="placeholder-lg placeholder col-12 arcaneTableColumnW20" />
            </h4>
            <div className="d-inline-block placeholder-glow mt-3 arcaneTableColumnLast">
                <div className="placeholder-lg placeholder col-12" />
            </div>
        </>
    );
}

export default ArcaneTablePlaceholder;

import React, { lazy, Suspense, useContext } from 'react';
import { ActiveContext } from '../../Context/ActiveContext';

// Suspense fallback
import Fallback from '../Suspense/Fallback';

// Layout Related Components
import Header from './Header/Header';

const Sidebar = lazy(() => import('./Sidebar/Sidebar'));
const Footer = lazy(() => import('./Footer/Footer'));

function Layout({ children }) {
    const { active, setActive } = useContext(ActiveContext);

    return (
        <div id="layout-wrapper">
            <Header active={active} setActive={setActive} />
            <Suspense fallback={<Fallback />}>
                <Sidebar active={active} setActive={setActive} />
                <div className="main-content">{children}</div>
                {!active.artemisGraph ? <Footer /> : null}
            </Suspense>
        </div>
    );
}

export default Layout;

import { resetObservations, fetchObservationData } from '../SideDrawer/Observations/observationsData';
import { epochUTC } from '../Helpers/helpers';

// Define a function to snap positions to the grid
export const snapPositions = (network, graph, gridSize) => {
    if (network) {
        network.on('dragEnd', (event) => {
            event.nodes.forEach((nodeId) => {
                const node = graph.nodes.find((n) => n.id === nodeId);
                if (node) {
                    const nodePosition = network.getPositions([nodeId])[nodeId];
                    const snappedX = Math.round(nodePosition.x / gridSize) * gridSize;
                    const snappedY = Math.round(nodePosition.y / gridSize) * gridSize;
                    network.moveNode(nodeId, snappedX, snappedY);
                }
            });
        });
    }
};

export const hideMenu = () => {
    const menu = document.getElementById('contextMenu');
    if (menu) {
        document.getElementById('contextMenu').style.display = 'none';
        document.oncontextmenu = (e) => {
            e.stopPropagation();
        };
    }
};

export const onContextMenu = (network, e, setSelectedNodeId, setIsRightClick) => {
    const node = network.getNodeAt(e.pointer.DOM);

    if (node) {
        setSelectedNodeId(node);
    } else {
        setSelectedNodeId();
    }

    document.oncontextmenu = (event) => {
        event.preventDefault();
        setIsRightClick(true);
    };

    document.getElementById('contextMenu').style.display = 'block';
    const new_menu = document.getElementById('contextMenu');
    new_menu.style.position = 'absolute';
    new_menu.style.left = `${e.pointer.DOM.x}px`;
    new_menu.style.top = `${e.pointer.DOM.y}px`;

    document.addEventListener('click', () => {
        setIsRightClick(false);
        hideMenu();
    });
};

export const handleNodeClick = async (
    network,
    e,
    setSelectedNodeId,
    saved,
    get_inbound_and_outbound_tx_ids,
    set_active_address,
    setInboundAndOutboundTxIds,
    getTxDetails,
    setSideDrawerIsOpen,
    dateFilter,
    column,
    dateRange,
    setNodeType,
    isOpen,
    setIsOpen,
    set_active_transaction,
    setLoading,
    txView,
    get_inbound_tx_ids,
    get_outbound_tx_ids,
    setHighlightedNode,
    activeTab,
    setActiveTab,
    getObservations,
    observationCache,
    setObservationCache,
    setLocations,
    apiKey,
    setLoadingObservations,
    filteredLocation,
    setSortStates,
    setLocationsHoldingArray,
    setSaved,
    setShowReset,
    subscriptionsCache,
    activeProviderTab,
    mapViewport,
    setCenter,
    setClearCluster,
    zoom,
    setZoom,
    setPoint,
    setFilteredLocation,
    mapHoldingObject,
    filteredSearchResults,
    addressInfo,
    getAddressInfo,
    setAddressData,
    setLoadingAddressData,
    setTxsOverTimeData,
    // getTxsOverTime,
    infoCache,
    setInfoCache,
    setBulkObservationLoading,
    navigate,
    setRateCache,
    rateCache,
    // getTxsOverTimeDaily,
    setTxsOverTimeDailyData,
    getAgencyInsightsResults,
    setAgencyInsightsResults,
    loadAgencyInsights,
    agencyInsightsTabCache,
    setAgencyInsightsTabCache,
    agencyInsightsLinkClicked,
    setAgencyInsightsLinkClicked,
    userDatasetAccess,
) => {
    if (isOpen) {
        setIsOpen(false);
    }
    if (agencyInsightsLinkClicked) {
        setAgencyInsightsLinkClicked(false);
    }
    setLoading(true);
    hideMenu();
    const node = network.getNodeAt(e.pointer.DOM);
    if (node && !node.includes('note-')) {
        setSideDrawerIsOpen(true);
        const selectedNode = saved.nodes.find((n) => n.id === node);
        if (selectedNode && selectedNode.type === 'address') {
            setNodeType('address');
            set_active_address(selectedNode.address);
            set_active_transaction();
            if (activeTab === 'observationsTab' && selectedNode.type === 'address') {
                const matchingObservations = observationCache.filter(
                    (observation) => observation.address === selectedNode.address,
                );

                const allEmptyLocations = matchingObservations.every(
                    (observation) => observation.locations && observation.locations.length === 0,
                );

                if (matchingObservations.length > 0 && allEmptyLocations) {
                    setActiveTab('infoTab');
                }
                const isActiveProviderInRateCache = rateCache.some(
                    (entry) =>
                        entry.address === selectedNode.address && entry.res.source.toLowerCase() === activeProviderTab,
                );

                if (isActiveProviderInRateCache) {
                    setLocations([]);
                } else {
                    setBulkObservationLoading(true);
                    const providers = subscriptionsCache?.providers || [];
                    const fetchObservationDataPromises = [];

                    if (providers.some((provider) => ['Chainalysis'].includes(provider.provider))) {
                        fetchObservationDataPromises.push(
                            fetchObservationData(
                                'ca',
                                mapViewport,
                                setCenter,
                                setClearCluster,
                                zoom,
                                setZoom,
                                setLocations,
                                setSortStates,
                                setPoint,
                                filteredLocation,
                                setFilteredLocation,
                                mapHoldingObject,
                                activeProviderTab,
                                observationCache,
                                selectedNode,
                                filteredSearchResults,
                                setObservationCache,
                                apiKey,
                                setLoadingObservations,
                                setLocationsHoldingArray,
                                saved,
                                network,
                                setSaved,
                                setShowReset,
                                getObservations,
                                resetObservations,
                                setRateCache,
                                rateCache,
                            ),
                        );
                    }

                    await Promise.all(fetchObservationDataPromises);
                    setBulkObservationLoading(false);
                }
            }
            if (activeTab === 'infoTab' && selectedNode.type === 'address') {
                addressInfo(
                    selectedNode.address,
                    getAddressInfo,
                    setAddressData,
                    setLoadingAddressData,
                    setTxsOverTimeData,
                    // getTxsOverTime,
                    infoCache,
                    setInfoCache,
                    navigate,
                    // getTxsOverTimeDaily,
                    setTxsOverTimeDailyData,
                );
            }
            if (activeTab === 'agencyInsightsTab' && selectedNode.type === 'address') {
                if (!(selectedNode?.insights?.length > 0)) {
                    setActiveTab('infoTab');
                }

                loadAgencyInsights(
                    selectedNode.address,
                    setLoadingAddressData,
                    agencyInsightsTabCache,
                    setAgencyInsightsTabCache,
                    navigate,

                    getAgencyInsightsResults,
                    setAgencyInsightsResults,
                    userDatasetAccess,
                    saved,
                    setSaved,
                );
            } else if (activeTab === 'transactionsTab') {
                if (txView === 'all') {
                    if (!dateFilter) {
                        get_inbound_and_outbound_tx_ids(
                            selectedNode.address,
                            'default',
                            undefined,
                            undefined,
                            undefined,
                            0,
                        );
                    } else {
                        const { epochStartTimeUTC, epochEndTimeUTC } = epochUTC(dateRange[0], dateRange[1]);
                        get_inbound_and_outbound_tx_ids(
                            selectedNode.address,
                            'sort_date',
                            epochStartTimeUTC,
                            epochEndTimeUTC,
                            column,
                            0,
                        );
                    }
                } else if (txView === 'inbound') {
                    if (!dateFilter) {
                        get_inbound_tx_ids(selectedNode.address, 'default', undefined, undefined, column, 0);
                    } else {
                        const { epochStartTimeUTC, epochEndTimeUTC } = epochUTC(dateRange[0], dateRange[1]);
                        get_inbound_tx_ids(
                            selectedNode.address,
                            'sort_date',
                            epochStartTimeUTC,
                            epochEndTimeUTC,
                            column,
                            0,
                        );
                    }
                } else if (txView === 'outbound') {
                    if (!dateFilter) {
                        get_outbound_tx_ids(selectedNode.address, 'default', undefined, undefined, column, 0);
                    } else {
                        const { epochStartTimeUTC, epochEndTimeUTC } = epochUTC(dateRange[0], dateRange[1]);

                        get_outbound_tx_ids(
                            selectedNode.address,
                            'sort_date',
                            epochStartTimeUTC,
                            epochEndTimeUTC,
                            column,
                            0,
                        );
                    }
                }
            } else if (!apiKey) {
                setActiveTab('infoTab');
            }
        }

        if (selectedNode && selectedNode.type === 'transaction') {
            setActiveTab('transactionsTab');
            setNodeType('transaction');
            set_active_transaction(selectedNode.id);
            set_active_address();
            const searched_tx = await getTxDetails({
                variables: {
                    tx_id: selectedNode.id,
                },
            });

            const tx = searched_tx.data.get_tx_id_details;
            if (tx) {
                setInboundAndOutboundTxIds([tx]);
            }
        }

        setSelectedNodeId(node);
        setHighlightedNode(node);
    } else {
        setSelectedNodeId('');
        setHighlightedNode(null);
    }
};

export const themeStyles = () => ({
    backgroundColor: '#ffffff',
    headerColor: '#ffffff',
    card: '#FFFFFF',
    text: '#5B646C',
    searchText: '#5B646C',
    iconColor: '#5B646C',
    searchIconColor: '#5B646C',
    dropdownIconColor: '#d39b22',
    rightCard: '#ffffff',
    nodeColor: '#2dcda8',
    nodeBorderColor: '#1e5ddc',
    searchNodeColor: '#2dcda8',
    searchNodeBorderColor: '#1e5ddc',
    transactionNodeColor: '#a6b0cf',
    transactionNodeBorderColor: '#2b7ce9',
    highlightTextColor: '#5B646C',
    highlightDropdownIconColor: '#d39b22',
    highlightColor: '#e1edf5',
    headerText: '#f6f6f6',
    searchBar: '#F3F3F9',
    edgeColor: '#2f75be',
});

export const gridSize = 30;
export const epicStyle = 'rgba(128, 128, 128, 0.9)';
export const majorStyle = 'rgba(128, 128, 128, 0.5)';
export const minorStyle = 'rgba(128, 128, 128, 0.3)';

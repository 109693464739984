import React from 'react';
import { reCenter } from '../../../../../Utils/Header/Recenter';
import { renderTooltip } from '../../../../../Utils/Helpers/helpers';

function RecenterButton({ network, setSelectedNodeId, iconColor }) {
    return (
        <>
            {renderTooltip('recenterTooltip', 'absolute')}
            <button
                type="button"
                onClick={() => {
                    reCenter(network, setSelectedNodeId);
                }}
                className="btn header-item noti-icon buttonPush"
                aria-label="Recenter"
                id="recenter"
                style={{
                    pointerEvents: 'all',
                    position: 'relative',
                }}
            >
                <i
                    className="bx bx-crosshair"
                    style={{
                        color: iconColor,
                    }}
                    data-tooltip-place="bottom"
                    data-tooltip-id="recenterTooltip"
                    data-tooltip-content="Recenter"
                />
            </button>
        </>
    );
}

export default RecenterButton;

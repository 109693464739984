import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

function Search({ children }) {
    const [search, set_search] = useState('');
    const [address, set_address] = useState();
    const [artemisSearch, setArtemisSearch] = useState(false);

    return (
        <SearchContext.Provider
            /* eslint-disable-next-line react/jsx-no-constructed-context-values */
            value={{
                search,
                set_search,
                address,
                set_address,
                artemisSearch,
                setArtemisSearch,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
}

export default Search;
